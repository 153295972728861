<template>
  <v-container :style="`max-width: ${$vuetify.breakpoint.xs ? '600px': '1100px'};`">

    <div class="text-center flex pa-2">
      <v-btn large rounded outlined @click="$router.push('/services/launchpad')" class="foreground title">
        <img 
        :src="$settings.AMMS[this.$store.state.exchange].icon" 
        height="24px"
        width="24px"
        class="mr-3 br-20">
        {{ $store.state.exchange }}
        <v-icon small color="">mdi-chevron-down</v-icon>
      </v-btn>
    </div>

    <div v-if="false">
      {{ $store.state.exchange }}
    </div>

    <div v-if="$store.state.exchange === 'Pancakeswap V1'"
    :class="['mb-16 mx-auto pa-4', {'foreground v-card br-20': $vuetify.breakpoint.xs}, {'v-card br-20 foreground': !$vuetify.breakpoint.xs}]"
    style="max-width: 600px;">
      Please create your presale on Pancakeswap V2
    </div>

    <div v-else-if="$store.state.exchange === 'Safemoonswap V1'"
    :class="['mb-16 mx-auto pa-4', {'foreground v-card br-20': $vuetify.breakpoint.xs}, {'v-card br-20 foreground': !$vuetify.breakpoint.xs}]"
    style="max-width: 600px;">
      Please create your presale on Pancakeswap V2
    </div>

    <div v-else-if="$store.state.exchange === 'Sushiswap V1'"
    :class="['mb-16 mx-auto pa-4', {'foreground v-card br-20': $vuetify.breakpoint.xs}, {'v-card br-20 foreground': !$vuetify.breakpoint.xs}]"
    style="max-width: 600px;">
      Please create your presale on Uniswap V2
    </div>

    <div v-else-if="$store.state.exchange === 'Julswap V1'"
    :class="['mb-16 mx-auto pa-4', {'foreground v-card br-20': $vuetify.breakpoint.xs}, {'v-card br-20 foreground': !$vuetify.breakpoint.xs}]"
    style="max-width: 600px;">
      Please create your presale on Pancakeswap V2
    </div>

    <div v-else-if="$store.state.exchange === 'Honeyswap V1'"
    :class="['mb-16 mx-auto pa-4', {'foreground v-card br-20': $vuetify.breakpoint.xs}, {'v-card br-20 foreground': !$vuetify.breakpoint.xs}]"
    style="max-width: 600px;">
      Honeyswap is not currently supported for ILO's
    </div>

    <div v-else-if="$store.state.exchange === 'Quickswap V1'"
    :class="['mb-16 mx-auto pa-4', {'foreground v-card br-20': $vuetify.breakpoint.xs}, {'v-card br-20 foreground': !$vuetify.breakpoint.xs}]"
    style="max-width: 600px;">
      Quickswap is not currently supported for ILO's
    </div>

    <template v-else>
      <v-slide-y-transition appear mode="out-in">
        <div 
        v-if="page === 0" 
        :class="['mb-16 mx-auto', {'foreground v-card br-20': $vuetify.breakpoint.xs}, {'v-card br-20 foreground': !$vuetify.breakpoint.xs}]"
        style="max-width: 600px;">
          
          <div class="py-6 title text-center textFaint--text">
            Select an ilo type
          </div>

          <div 
          class="d-flex align-center c-list pa-4 border-b"
          @click="page = 1">
            <img 
            src="@/assets/img/icons/rocket.svg" 
            height="60px"
            width="60px"
            class="mr-3">
            <div>
              <div class="title mb-2">
                Fair sale <span class="primary--text caption font-weight-bold">**NEW: Whitelist</span>
              </div>
              <div class="caption">
                <strong>Round 0:</strong> - {{ presaleSettings.uncl_percentage }}% of the presale hardcap can be sold before the sale starts by users willing to burn UNCL
              </div>
              <div class="caption">
                <strong>Round 0:</strong> - <span class="primary--text">{{ presaleSettings.whitelist_percentage }}% of the hardcap available to whitelisted participants <strong>before</strong> the sale starts</span>
              </div>
              <div v-if="false" class="caption">
                <strong>Round 1:</strong> - (2 hours) exclusively for UNCX and UNCL holders
              </div>
              <div class="caption">
                <strong>Round 1:</strong> - Open to everyone
              </div>
            </div>
          </div>

          <div 
          class="d-flex align-center pa-4">
            <img 
            src="@/assets/img/icons/rocket.svg" 
            height="60px"
            width="60px"
            class="mr-3 greyscale">
            <div>
              <div class="">
                Type 2: New format
              </div>
              <div class="caption textFaint--text">
                Coming soon!
              </div>
            </div>
          </div>
          
        </div>

        <pre-gen-06 v-if="page === 1"></pre-gen-06>
      </v-slide-y-transition>
    </template>
    
  </v-container>
</template>

<script>
import PresaleSettingsContract from '@/smart-contracts/presales/v6/presale-settings'
import PreGen06 from './versions/pregen-06'

export default {
  components: {
    PreGen06
  },

  data: () => ({
    page: 0,
    presaleSettings: {}
  }),

  computed: {
  },

  methods: {
    async getPresaleSettings () {
      var presaleSettings = await PresaleSettingsContract.getSettings()
      this.presaleSettings = presaleSettings
    },
  },

  created () {
    this.getPresaleSettings()
  }
}
</script>