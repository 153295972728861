import { render, staticRenderFns } from "./reserve-with-uncl.vue?vue&type=template&id=1b8f5302&"
import script from "./reserve-with-uncl.vue?vue&type=script&lang=js&"
export * from "./reserve-with-uncl.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports