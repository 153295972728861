<template>
  <div class="v-card br-20">

    <div class="pa-6">

      <div>
        <v-btn @click="$root.chainLink(`/farms`)" icon x-large>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </div>

      <div class="display-1 text-uppercase text-center font-weight-medium mb-4">
        Create a Stake Pool
      </div>

      <div>
        Supported features
      </div>
      <ul class="caption">
        <li>
          Multiple reward streams per stake pool, stake once, earn multiple rewards
        </li>
        <li>
          Tier systems (enforce minimum and maximum stake amounts per pool)
        </li>
        <li>
          Bonus reward periods to encourage early staking
        </li>
        <li>
          Time lock boosts - encourage stakers to lock their tokens for increased rewards
        </li>
        <li>
          Top up, or increase block rewards for existing reward pools
        </li>
      </ul>
      <div class="pt-4">
        Reflections and rebasing tokens
      </div>
      <ul class="caption">
        <li>
          Fully supported
        </li>
        <li>
          Stakers receive all reflections
        </li>
        <li>
          Reward pools enjoy increased block rewards from reflections
        </li>
      </ul>
    </div>

    <div v-if="!sEthers.coinbase" class="v-card ma-2 br-20 text-center pa-6">
      <div>
        <v-icon size="40" color="navcolor">mdi-account-circle</v-icon>
        <div class="textFaint--text pt-2">
          Connect your wallet to create a Stake Pool
        </div>
      </div>
      <div class="pt-4">
        <v-btn
        large
        block
        color="navcolor"
        class="white--text"
        rounded
        @click="connectWallet"
        >
          CONNECT
        </v-btn>
      </div>
    </div>

    <template v-else>
      <div class="px-6">

        <div class="mb-2 mt-10 font-weight-medium">
          Which token should a user stake?
        </div>
        <load-token @on-update="updateToken"></load-token>

        <div class="mt-10">
          <div class="font-weight-medium">
            Minimum staking period
          </div>
          <div class="textFaint--text caption">
            (Funds are locked in staking for this period at minimum). Boosts are added to this minimum staking period.
          </div>
        </div>
        <time-offset @onUpdate="updateLockPeriod" class="mt-2"></time-offset>

        <v-row dense class="mt-10">
          <v-col cols="12">

            <div class="mb-2 font-weight-medium">
              Min Stake Amount
            </div>

            <div class="d-flex align-center inputcolor pa-2 r-outlined">
              <c-input :value.sync="min_stake_amount_human" placeholder="0.0" @updateWhileFocussed="onMinStakeAmountChange" class="pa-2 font-weight-bold">
              </c-input>

              <div class="font-weight-medium">
                {{ tokenHydrated.symbol }}
              </div>
            </div>

          </v-col>
          <v-col cols="12">

            <div class="mb-2 font-weight-medium">
              Max stake amount
            </div>

            <div class="d-flex align-center inputcolor pa-2 r-outlined">
              <c-input :value.sync="max_stake_amount_human" placeholder="0.0" @updateWhileFocussed="onMaxStakeAmountChange" class="pa-2 font-weight-bold">
              </c-input>

              <div class="font-weight-medium">
                {{ tokenHydrated.symbol }}
              </div>
            </div>

            <div class="text-end">
              <v-btn @click="setMaxStakeAmount" small rounded text color="primary">
                Set to max value
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <!-- STAKE MOD FEE -->
        <div class="mt-6 py-2 d-flex align-center font-weight-medium">
          Stake modification fee
          <v-spacer></v-spacer>
          <v-switch
          v-model="stakeModFeeEnabled"
          color="primary"
          class="ma-0"
          hide-details
          ></v-switch>
        </div>
        <div class="textFaint--text">
          Charge the user a fee every time they stake or unstake.
          This is useful for covering oracle costs.
        </div>

      </div>

      <v-expand-transition mode="out-in">
        <div v-if="stakeModFeeEnabled">
          <div class="pa-4 v-card mx-4 br-20">
            <div class="mb-2 d-flex align-center textFaint--text font-weight-medium">
              Fee Token
              <v-spacer></v-spacer>
              <v-btn small rounded depressed 
              @click="stakeModFeeTokenIsGasToken = true"
              :color="stakeModFeeTokenIsGasToken ? 'primary' : ''">
                {{ $store.state.nativeGasTokenSymbol }}
              </v-btn>
              <v-btn small rounded depressed 
              @click="stakeModFeeTokenIsGasToken = false"
              :color="!stakeModFeeTokenIsGasToken ? 'primary' : ''">
                Other
              </v-btn>
            </div>
            <v-expand-transition mode="out-in">
              <div v-if="!stakeModFeeTokenIsGasToken" class="pa-0">
                <load-token @on-update="updateStakeModFeeToken"></load-token>
              </div>
            </v-expand-transition>

            <div class="mt-4 textFaint--text font-weight-medium">
              Amount
            </div>
            <div class="d-flex mt-2 br-8 align-center inputcolor pa-2">
              <c-input :value.sync="stake_mod_fee_amount_human" placeholder="0.0" @updateWhileFocussed="onStakeModFeeAmountChange" class="pa-2 font-weight-bold">
              </c-input>

              <div class="font-weight-medium">
                {{ stakeModFeeTokenIsGasToken ? $store.state.nativeGasTokenSymbol : stake_mod_fee_token_hydrated.symbol }}
              </div>
            </div>

            <div class="mt-4 textFaint--text font-weight-medium">
              Fee Address
            </div>
            <div class="mt-2 pa-2 br-8 inputcolor mb-4">
              <div class="d-flex align-center py-2">
                <template v-if="!stakeModFeeAddressIsValid">
                  <v-icon small color="textFaint" class="mr-1">mdi-alert-outline</v-icon>
                </template>
                <template v-else>
                  <v-icon small color="primary" class="mr-1">mdi-check-circle-outline</v-icon>
                </template>
                <c-input :value.sync="stake_mod_fee_address" placeholder="0x...">
                </c-input>
              </div>
            </div>
          </div>
        </div>
      </v-expand-transition>
      <!-- STAKE MOD FEE -->

      <!-- BOOST -->
      <div class="pa-4 textFaint--text">
        <div class="text--text font-weight-medium">
          Time Boost 
          <span>({{ boost_options.length }})</span>
        </div>
        <div class="caption">
          Time boosts allow you to give users who lock their tokens for longer more rewards. Its reccomended you use this feature.
        </div>
        <div class="caption py-3 font-italic">
          Boosts are added to your minimum staking period. E.g. if the minimum staking period is 1 month
          and you add a 1 month boost for 10%, users who choose the 10% boost will have their tokens locked 
          for 2 months (1 month + 1 month)
        </div>
        <div class="mt-2 caption">
          Boost periods must be ordered chronologically and in ascending order e.g.
        </div>
        <div class="caption">
          Boost 1: 1 Month, 10%
        </div>
        <div class="caption">
          Boost 1: 2 Months, 25%
        </div>
      </div>

      <div v-if="boost_options.length > 0" class="d-flex align-center px-4 font-weight-bold textFaint--text caption">
        <div style="flex: 1 1 0;">
          Additional Lock time
        </div>
        <div style="flex: 1 1 0;" class="pl-2">
          Boost percentage
        </div>
      </div>
      <boost-row 
      v-for="boost of boost_options" 
      :key="boost.nonce" 
      :item="boost"
      :minimumLockPeriod="lock_period"
      @remove-boost="removeBoost(boost.nonce)"
      class="pa-2 border-b">
      </boost-row>

      <div v-if="boost_options.length < 10" class="border-b text-center py-2">
        <v-btn small rounded color="primary" @click="addBoost">
          <v-icon small>mdi-subdirectory-arrow-right</v-icon>
          Add Boost Option
        </v-btn>
      </div>

      <!-- COUNTRY SELECT -->
      <div class="px-4 py-6">
        <div class="font-weight-bold text--text">
          Select your country
        </div>
        <div class="textFaint--text pb-4">
          Where is your business headquartered?
        </div>
        <div>
          <div @click="openSelectCountryDialog"
          class="inputcolor pa-4 br-8 d-flex align-center c-list font-weight-bold">
            <span v-if="selectedCountry.id === -1" class="red--text">
              Select your country
            </span>
            <div v-else>
              {{ selectedCountry.country_name }}
            </div>
            <v-spacer></v-spacer>
            <template v-if="selectedCountry.id === -1">
              <v-icon color="red">mdi-alert-outline</v-icon>
            </template>
            <v-icon>mdi-chevron-down</v-icon>
          </div>
        </div>
      </div>
      <!-- END COUNTRY SELECT -->

      <template v-if ="chargeFee">
        <div class="mt-4 mx-4 pa-4 br-8 midground">
          <div class="caption textFaint--text">
            STAKE POOL CREATION
          </div>
          <div class="d-flex font-weight-bold">
            Fee
            <v-spacer></v-spacer>
            {{ ethCreationFeeHuman }} {{ $store.state.nativeGasTokenSymbol }}
          </div>
        </div>
        <div class="mx-4 mt-2 mb-4 pa-4 br-8 midground">
          <div class="caption textFaint--text">
            REWARD POOL CREATION (Charged later when creating reward pools)
          </div>
          <div class="d-flex textFaint--text font-weight-bold">
            Fee
            <v-spacer></v-spacer>
            {{ stake_fees.reward_fee / 10 }}%
          </div>
        </div>
      </template>
      <div v-else class="ma-4 pa-4 br-8 midground d-flex font-weight-bold">
        <img 
        v-if="true"
        src="@/assets/img/UNCX_fill.svg"
        height="40px"
        width="40px"
        class="mr-3">
        <div>
          <div class="caption textFaint--text">
            UNICRYPT PREMIUM USER
          </div>
          <div class="primary--text">
            No Fees!
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-icon color="textFaint" size="40">mdi-emoticon-excited-outline</v-icon>
      </div>

      <v-btn @click="createStakePool" block x-large rounded color="primary" :loading="createLoading">
        Create Stake Pool
      </v-btn>
    </template>

    <!-- DIALOGS -->
    <country-list-dialog ref="countryListDialog"></country-list-dialog>
    <!-- DIALOGS -->

  </div>
</template>

<script>
import { ethers } from 'ethers'
import StakePoolGeneratorContract from '@/smart-contracts/farms-v2/normal/stake-pool/v1/stake-pool-generator'
import StakeSettingsContract from '@/smart-contracts/farms-v2/normal/stake-pool/v1/stake-settings'
import StakeFactoryContract from '@/smart-contracts/farms-v2/normal/stake-factory'
import EcosystemWhitelistContract from '@/smart-contracts/ecosystem-whitelist/ecosystem-whitelist'
import TxWrapper from '@/smart-contracts/tx-wrapper'
import TimeOffset from '@/components/date-time/time-offset'
import BoostRow from './boost-row'
import CountryListDialog from '@/components/dialogs/country-select/csdialog'

export default {

  components: {
    TimeOffset,
    BoostRow,
    CountryListDialog
  },

  data: () => ({
    tokenHydrated: '',
    lock_period: 0,
    min_stake_amount: '0',
    max_stake_amount: '0',
    min_stake_amount_human: '0',
    max_stake_amount_human: '0',
    stake_fees: {
      eth_creation_fee: '0'
    },
    boost_options: [],
    boostNonce: -1, // unique nonce for each boost row
    stakeModFeeEnabled: false,

    stake_mod_fee_amount: '0',
    stake_mod_fee_amount_human: '0',
    stake_mod_fee_token_hydrated: {},
    stake_mod_fee_address: '',
    stakeModFeeTokenIsGasToken: true,

    selectedCountry: {
      id: -1,
      country_code: null,
      country_name: 'No selection',
      continent_name: null
    },

    chargeFee: true,

    createLoading: false,
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    ethCreationFeeHuman () {
      var amount = ethers.utils.formatUnits(this.stake_fees.eth_creation_fee, 18)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3})
    },
    stakeModFeeAddressIsValid () {
      try {
        ethers.utils.getAddress(this.stake_mod_fee_address)
        return true
      } catch (e) {
        return false
      }
    },
  },

  watch: {
    sEthersWatcher () {
      this.checkEcosystemFee()
      this.getStakePoolFees()
    }
  },

  methods: {
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .then(() => {
        })
        .catch(e => {})
    },
    async checkEcosystemFee () {
      this.chargeFee = true
      var chargeFee = await EcosystemWhitelistContract.chargeFee(this.tokenHydrated.address, this.sEthers.coinbase)
      this.chargeFee = chargeFee
    },
    generateNonce () {
      this.boostNonce++
      return this.boostNonce
    },
    addBoost () {
      if (this.boost_options.length === 10) {
        return
      }
      this.boost_options.push({
        time_period: 0,
        boost_percentage: 0,
        nonce: this.generateNonce()
      })
    },
    removeBoost (nonce) {
      this.boost_options = this.boost_options.filter(item => item.nonce !== nonce)
    },
    onMinStakeAmountChange (val) {
      if (!val) {
        this.min_stake_amount = '0'
        return
      }
      this.min_stake_amount = ethers.utils.parseUnits(val, this.tokenHydrated.decimals)
    },
    onMaxStakeAmountChange (val) {
      if (!val) {
        this.max_stake_amount = '0'
        return
      }
      this.max_stake_amount = ethers.utils.parseUnits(val, this.tokenHydrated.decimals)
    },
    onStakeModFeeAmountChange (val) {
      if (!val) {
        this.stake_mod_fee_amount = '0'
        return
      }
      this.stake_mod_fee_amount = ethers.utils.parseUnits(val, this.stake_mod_fee_token_hydrated.decimals)
    },
    setMaxStakeAmount () {
      this.max_stake_amount = '115792089237316195423570985008687907853269984665640564039457584007913129639935'
      this.max_stake_amount_human = 'MAX_VALUE'
    },
    updateToken (token) {
      this.tokenHydrated = token;
      this.checkEcosystemFee()
    },
    updateStakeModFeeToken (token) {
      this.stake_mod_fee_token_hydrated = token;
      this.stake_mod_fee_amount = '0'
      this.stake_mod_fee_amount_human = '0'
    },
    async getStakePoolFees () {
      this.stake_fees = await StakeSettingsContract.getFees()
    },
    updateLockPeriod (timeInSeconds) {
      this.lock_period = timeInSeconds
    },
    openSelectCountryDialog () {
      this.$refs.countryListDialog.open()
        .then(country => {
          this.selectedCountry = country
        })
    },
    createStakePool () {
      this.createLoading = true
      var userAddress = this.sEthers.coinbase
      var boost_options = this.boost_options.map(item => {
        return [item.time_period, item.boost_percentage * 100]
      })
      var stakeModFeeToken = ethers.constants.AddressZero
      var stakeModFeeAddress = ethers.constants.AddressZero
      if (!this.stakeModFeeTokenIsGasToken) {
        stakeModFeeToken = this.stake_mod_fee_token_hydrated.address
      }
      if (this.stakeModFeeEnabled) {
        stakeModFeeAddress = this.stake_mod_fee_address
      }
      var flatFee = this.stake_fees.eth_creation_fee
      if (!this.chargeFee) {
        flatFee = '0'
      }
      TxWrapper.doTransaction(StakePoolGeneratorContract.createStakePool(
        [
          this.tokenHydrated.address, 
          this.lock_period, 
          this.min_stake_amount, 
          this.max_stake_amount, 
          stakeModFeeToken, 
          this.stake_mod_fee_amount, 
          stakeModFeeAddress,
          this.selectedCountry.id
        ],
        boost_options,
        flatFee), this.$root.$dialog.confirmTx, 1)
        .then(response => {
          this.redirectToLatestPool(userAddress)
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          this.createLoading = false
        })
    },
    async redirectToLatestPool (userAddress) {
      var index = await StakeFactoryContract.getNumPoolsForAdmin(userAddress)
      var address = await StakeFactoryContract.getStakePoolForAdminAtIndex(userAddress, index - 1)
      this.$root.chainLink(`/farm/${address}`)
    }
  },

  created () {
    this.setMaxStakeAmount()
    this.getStakePoolFees()
  }
}
</script>