<template>
  <v-container :style="`max-width: ${$vuetify.breakpoint.xs ? '600px': '1100px'};`" :class="[{'pa-0 midground mobile-page': $vuetify.breakpoint.xs}]">

    <template v-if="$vuetify.breakpoint.mdAndUp">
      
      <div class="d-flex align-center">

        <div class="br-20 pa-4 mb-3">
          <div class="title primary--text justify-start font-weight-bold d-flex align-center">
            <v-icon large color="primary">mdi-shield-outline</v-icon>
            ${{ tvalHumanFormat(tvalLocked) }}
            <div class="text-center caption textFaint--text">
              &nbsp; - Total Value locked across all AMMS
            </div>
          </div>
        </div>

        <v-spacer></v-spacer>

        <div class="text-end">
          <token-chip address="0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c" exchange="Safemoonswap V1" icon="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/logo.png"></token-chip>
          <token-chip address="0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2" exchange="Uniswap V2" icon="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png"></token-chip>
          <token-chip address="0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599" exchange="Uniswap V2" icon="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png"></token-chip>
        </div>

      </div>

      <v-expand-transition mode="out-in">
        <div v-if="true">
          <div class="v-card pa-4 mx-2 mb-4 br-20 textFaint--text d-flex align-center">
            <img 
            src="@/assets/img/UNCX_fill.svg" 
            height="30px"
            width="30px"
            class="mr-2 br-c">
            <div class="flex">
              Earn fees from our ecosystem with
              <router-link to="/uncx-staking" class="deadlink primary--text">
                UNCX staking
              </router-link>
            </div>
          </div>
        </div>
      </v-expand-transition>

      <gaming-launches class="mb-6"></gaming-launches>

      <!-- INCUBATIONS -->
      <div v-if="numPresales > 0" class="br-20 mb-4">
        <template>
          <div class="title pa-4">
            Upcoming Incubated Launches
          </div>
        </template>

        <v-row dense class="pa-2 ma-0">
          <template v-for="exchange of Object.keys(presales)">
            <template v-if="presales[exchange].length > 0">
              <v-col cols="12" md="6" v-for="presale of presales[exchange]" :key="presale.presale_contract" class="py-0">
                <presale-row
                @click.native="goToPresale(presale)"
                :key="presale.presale_contract"
                :exchange="presale.exchange"
                :presale="presale"
                >
                </presale-row>
              </v-col>
            </template>
          </template>
        </v-row>
      </div>
      <!-- INCUBATIONS -->

      <div class="br-8 mb-1">
        <v-btn @click="$root.ammLinkTwo(`/ilos`, 'Pancakeswap V2')"
        rounded text color="text" class="title d-flex px-3 font-weight-medium align-center">
          <img 
          v-if="false"
          :src="$settings.AMMS['Pancakeswap V2'].icon" 
          height="24px"
          width="24px"
          class="mr-3 br-20 greyscale">
          Upcoming Pancakeswap Presales
          <v-icon small class="ml-1">mdi-chevron-right</v-icon>
        </v-btn>
        <presale-timeline exchange="Pancakeswap V2" sorting="start_block" :hide_flagged="true"></presale-timeline>
        
        <!--
        <v-btn @click="$root.ammLinkTwo(`/ilos`, 'Uniswap V2')"
        rounded text color="textFaint" class="title d-flex px-3 font-weight-bold align-center">
          <img 
          :src="$settings.AMMS['Uniswap V2'].icon" 
          height="24px"
          width="24px"
          class="mr-3 br-20 greyscale">
          Uniswap Presales
          <v-icon small class="ml-1">mdi-chevron-right</v-icon>
        </v-btn>
        <presale-timeline exchange="Uniswap V2" sorting="start_block" :hide_flagged="true"></presale-timeline>
        -->
      </div>

      <v-row dense class="ma-0">
        <v-col cols="12" md="6">
          <buy-tokens></buy-tokens>

          <ilo-card></ilo-card>

          <v-slide-x-transition appear>
            <div :class="['mb-16', {'pa-4': $vuetify.breakpoint.xs}, {'v-card br-20': !$vuetify.breakpoint.xs}]">
              
              <template v-if="presalesLoading">
                <div class="text-center pa-8">
                  <v-progress-circular
                  indeterminate
                  size="80"
                  width="3"
                  color="textFaint">
                    <v-icon color="textFaint" size="50">mdi-lock-outline</v-icon>
                  </v-progress-circular>
                </div>
              </template>
              <v-scroll-y-transition>
                <div v-if="!presalesLoading">

                  <div class="pa-4 d-flex align-center mb-1 mb-6 background">
                    <v-icon color="text" size="22" class="mr-2">mdi-lock-outline</v-icon>
                    New Liquidity Locks (>100k)
                  </div>

                  <template v-for="exchange of Object.keys(locks)">
                    <div :key="`${exchange}-lock`" class="">
                      <div v-if="false" class="pr-4 border-b d-flex align-center text-center py-2">
                        <v-btn color="text" text rounded @click="goToLocks(exchange)">
                          <img 
                          :src="$settings.AMMS[exchange].icon" 
                          height="24px"
                          width="24px"
                          class="mr-2 br-20">
                          {{ exchange }}
                          <v-icon small>
                            mdi-chevron-right
                          </v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <div class="text-end">
                          <div class="text--text font-weight-medium">
                          ${{ tvalHumanFormat(tval[exchange].tval) }} Locked
                          </div>
                          <div class="textFaint--text caption">
                          in {{ tval[exchange].pair_count }} pairs
                          </div>
                        </div>
                      </div>
                      <lock-row
                      v-for="item of locks[exchange]" 
                      :key="item.address"
                      :exchange="item.exchange"
                      class="border-b"
                      :item="item">
                      </lock-row>
                    </div>
                  </template>

                </div>
              </v-scroll-y-transition>
              
            </div>
          </v-slide-x-transition>
        </v-col>
        <v-col cols="12" md="6">
          
          <!--
          <a href="https://unicrypt.network/community-campaign" class="deadlink" target="_blank">
            <div class="primary-pulse pa-1 br-20 mb-3">
              <div class="border primary white--text br-20 pa-4 c-list">
                <div class="title text-center font-weight-bold">
                  Community Campaign now LIVE
                </div>
                <div class="text-center">
                  Earn USDC by completing educational tasks.
                </div>
                <div class="text-center caption font-italic">
                  Click here to find out more!
                </div>
              </div>
            </div>
          </a>
          -->

          <services-card class="v-card mb-4 br-20"></services-card>

          <div v-if="false" :class="['mb-16', {'pa-4': $vuetify.breakpoint.xs}, {'v-card br-20': !$vuetify.breakpoint.xs}]">

            <div class="pa-4 d-flex align-center mb-1 mb-6 background">
              <v-icon color="text" size="22" class="mr-2">mdi-lock-outline</v-icon>
              New Token Locks
            </div>

            <div class="mt-2 br-20">
              <div class="pa-3 d-flex align-center">
                <img 
                :src="$settings.CHAINS['Mainnet'].icon" 
                height="24px"
                width="24px"
                class="mr-3 br-20">
                Mainnet
              </div>
              <global-tokens chain="Mainnet"></global-tokens>
            </div>

            <div class="mt-2 br-20">
              <div class="pa-3 d-flex align-center">
                <img 
                :src="$settings.CHAINS['BSC_MAINNET'].icon" 
                height="24px"
                width="24px"
                class="mr-3 br-20">
                Binance smart chain
              </div>
              <global-tokens chain="BSC_MAINNET"></global-tokens>
            </div>

            <div class="mt-2 br-20">
              <div class="pa-3 d-flex align-center">
                <img 
                :src="$settings.CHAINS['xDai'].icon" 
                height="24px"
                width="24px"
                class="mr-3 br-20">
                xDai
              </div>
              <global-tokens chain="xDai"></global-tokens>
            </div>

            <div class="mt-2 br-20">
              <div class="pa-3 d-flex align-center">
                <img 
                :src="$settings.CHAINS['Matic'].icon" 
                height="24px"
                width="24px"
                class="mr-3 br-20">
                Matic
              </div>
              <global-tokens chain="Matic"></global-tokens>
            </div>

            <div class="mt-2 br-20">
              <div class="pa-3 d-flex align-center">
                <img 
                :src="$settings.CHAINS['Kovan'].icon" 
                height="24px"
                width="24px"
                class="mr-3 br-20">
                Kovan
              </div>
              <global-tokens chain="Kovan"></global-tokens>
            </div>

          </div>

        </v-col>
      </v-row>
    </template>

    <div v-else :class="['mb-16']">

      <!--
      <a href="https://unicrypt.network/community-campaign" class="deadlink" target="_blank">
        <div class="primary-pulse pa-1 mx-2 br-20 mb-3">
          <div class="border primary white--text br-20 pa-4 c-list">
            <div class="title text-center font-weight-bold">
              Community Campaign now LIVE
            </div>
            <div class="text-center">
              Earn USDC by completing educational tasks.
            </div>
            <div class="text-center caption font-italic">
              Click here to find out more!
            </div>
          </div>
        </div>
      </a>
      -->

      <div class="text-center mt-2">
        <token-chip address="0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c" exchange="Safemoonswap V1" icon="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/logo.png"></token-chip>
        <token-chip address="0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2" exchange="Uniswap V2" icon="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png"></token-chip>
        <token-chip address="0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599" exchange="Uniswap V2" icon="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png"></token-chip>
      </div>

      <div class="br-20 pa-4 mx-2">
        <div class="title primary--text text-center font-weight-bold">
          <div>
            <v-icon large color="primary">mdi-shield-outline</v-icon>
          </div>
          ${{ tvalHumanFormat(tvalLocked) }}
        </div>
        <div class="text-center caption textFaint--text">
          Total Value locked across all AMMS
        </div>
      </div>

      <v-expand-transition mode="out-in">
        <div v-if="true">
          <div class="v-card pa-4 mx-2 caption br-20 textFaint--text d-flex align-center">
            <img 
            src="@/assets/img/UNCX_fill.svg" 
            height="30px"
            width="30px"
            class="mr-2 br-c">
            <div class="flex">
              Earn fees from our ecosystem with
              <router-link to="/uncx-staking" class="deadlink primary--text">
                UNCX staking
              </router-link>
            </div>
          </div>
        </div>
      </v-expand-transition>

      <gaming-launches class="mb-6 mt-3 mx-2"></gaming-launches>

      <!-- INCUBATIONS -->
      <div v-if="numPresales > 0" class="br-20 mb-4">
        <template>
          <div class="title px-4 pt-4">
            Upcoming Incubated Launches
          </div>
        </template>

        <v-row dense class="pa-2 ma-0">
          <template v-for="exchange of Object.keys(presales)">
            <template v-if="presales[exchange].length > 0">
              <v-col cols="12" md="6" v-for="presale of presales[exchange]" :key="presale.presale_contract" class="py-0">
                <presale-row
                @click.native="goToPresale(presale)"
                :key="presale.presale_contract"
                :exchange="presale.exchange"
                :presale="presale"
                >
                </presale-row>
              </v-col>
            </template>
          </template>
        </v-row>
      </div>
      <!-- INCUBATIONS -->

      <div class="br-8 mb-1">
        <v-btn @click="$root.ammLinkTwo(`/ilos`, 'Pancakeswap V2')"
        rounded text color="text" class="title d-flex px-3 font-weight-medium align-center">
          <img 
          :src="$settings.AMMS['Pancakeswap V2'].icon" 
          height="24px"
          width="24px"
          class="mr-3 br-20">
          Pancakeswap Presales
          <v-icon small class="ml-1">mdi-chevron-right</v-icon>
        </v-btn>
        <presale-timeline exchange="Pancakeswap V2" sorting="start_block" :hide_flagged="true"></presale-timeline>
      </div>
      <div class="br-8 mb-1">
        <v-btn @click="$root.ammLinkTwo(`/ilos`, 'Trader Joe V1')"
        rounded text color="text" class="title d-flex px-3 font-weight-medium align-center">
          <img 
          :src="$settings.AMMS['Trader Joe V1'].icon" 
          height="24px"
          width="24px"
          class="mr-3 br-20">
          Trader Joe Presales
          <v-icon small class="ml-1">mdi-chevron-right</v-icon>
        </v-btn>
        <presale-timeline exchange="Trader Joe V1" sorting="start_block" :hide_flagged="true"></presale-timeline>
      </div>
      <div class="br-8 mb-1">
        <v-btn @click="$root.ammLinkTwo(`/ilos`, 'Uniswap V2')"
        rounded text color="text" class="title d-flex px-3 font-weight-medium align-center">
          <img 
          :src="$settings.AMMS['Uniswap V2'].icon" 
          height="24px"
          width="24px"
          class="mr-3 br-20">
          Uniswap V2 Presales
          <v-icon small class="ml-1">mdi-chevron-right</v-icon>
        </v-btn>
        <presale-timeline exchange="Uniswap V2" sorting="start_block" :hide_flagged="true"></presale-timeline>
      </div>

      <buy-tokens class="mx-2"></buy-tokens>

      <ilo-card></ilo-card>

      <template v-if="presalesLoading">
        <div class="text-center">
          <v-progress-circular
          indeterminate
          size="80"
          width="3"
          color="textFaint">
            <v-icon color="textFaint" size="50">mdi-lock-outline</v-icon>
          </v-progress-circular>
        </div>
      </template>
      <v-scroll-y-transition>
        <div v-if="!presalesLoading">

          <div class="text-center mt-10">
            <img 
            src="@/assets/img/icons/padlock.svg" 
            height="40px"
            width="40px"
            class="greyscale">
            <div class="text-center textFaint--text mb-4">
              New Liquidity Locks (>100k)
            </div>
          </div>

          <template v-for="exchange of Object.keys(locks)">
            <div :key="`${exchange}-lock`" class="">
              <div v-if="false" class="pr-4 border-b d-flex align-center text-center py-2">
                <v-btn color="text" text rounded @click="goToLocks(exchange)">
                  <img 
                  :src="$settings.AMMS[exchange].icon" 
                  height="24px"
                  width="24px"
                  class="mr-2 br-20">
                  {{ exchange }}
                  <v-icon small>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <div class="text-end">
                  <div class="text--text font-weight-medium">
                  ${{ tvalHumanFormat(tval[exchange].tval) }} Locked
                  </div>
                  <div class="textFaint--text caption">
                  in {{ tval[exchange].pair_count }} pairs
                  </div>
                </div>
              </div>
              <lock-row
              v-for="item of locks[exchange]" 
              :key="item.address"
              :exchange="item.exchange"
              class="border-b"
              :item="item">
              </lock-row>
            </div>
          </template>

          <!-- TOKEN LOCKS -->
          <div v-if="false">

            <div class="text-center mt-10">
              <img 
              src="@/assets/img/icons/padlock.svg" 
              height="40px"
              width="40px"
              class="greyscale">
              <div class="text-center textFaint--text mb-4">
                New Token Locks
              </div>
            </div>

            <div class="mt-2 br-20">
              <div class="pa-3 d-flex align-center justify-center">
                <img 
                :src="$settings.CHAINS['Mainnet'].icon" 
                height="24px"
                width="24px"
                class="mr-3 br-20">
                Mainnet
              </div>
              <global-tokens chain="Mainnet"></global-tokens>
            </div>

            <div class="mt-2 br-20">
              <div class="pa-3 d-flex align-center justify-center">
                <img 
                :src="$settings.CHAINS['BSC_MAINNET'].icon" 
                height="24px"
                width="24px"
                class="mr-3 br-20">
                Binance smart chain
              </div>
              <global-tokens chain="BSC_MAINNET"></global-tokens>
            </div>

            <div class="mt-2 br-20">
              <div class="pa-3 d-flex align-center justify-center">
                <img 
                :src="$settings.CHAINS['xDai'].icon" 
                height="24px"
                width="24px"
                class="mr-3 br-20">
                xDai
              </div>
              <global-tokens chain="xDai"></global-tokens>
            </div>

            <div class="mt-2 br-20">
              <div class="pa-3 d-flex align-center justify-center">
                <img 
                :src="$settings.CHAINS['Matic'].icon" 
                height="24px"
                width="24px"
                class="mr-3 br-20">
                Matic
              </div>
              <global-tokens chain="Matic"></global-tokens>
            </div>

            <div class="mt-2 br-20">
              <div class="pa-3 d-flex align-center justify-center">
                <img 
                :src="$settings.CHAINS['Kovan'].icon" 
                height="24px"
                width="24px"
                class="mr-3 br-20">
                Kovan
              </div>
              <global-tokens chain="Kovan"></global-tokens>
            </div>
            
          </div>
          <!-- TOKEN LOCKS -->

        </div>
      </v-scroll-y-transition>

    </div>
    
  </v-container>
</template>

<script>
import SETTINGS from '@/store/settings'
import axios from 'axios'
import LockRow from '@/views/pairs/row'
import PresaleRow from '@/views/presale/presales/row-switcher'
import GlobalTokens from '@/views/locker/erc20/global-token/GlobalTokens'
import BuyTokens from './buy-uncx-uncl'
import IloCard from './ilo-card'
import PresaleTimeline from '@/views/presale/presales/timeline'
import ServicesCard from './services'
import TokenChip from '@/views/tokens/token-chip'
import GamingLaunches from './gaming-launches'

export default {

  components: {
    PresaleRow,
    LockRow,
    GlobalTokens,
    BuyTokens,
    IloCard,
    PresaleTimeline,
    ServicesCard,
    TokenChip,
    GamingLaunches
  },

  data: () => ({
    presales: {
      'Uniswap V2': [],
      'Honeyswap V1': [],
      'Pancakeswap V1': [],
      'Pancakeswap V2': [],
      'Julswap V1': [],
      'Sushiswap V1': [],
      'Quickswap V1': [],
    },
    locks: {
      'Uniswap V2': [],
      'Pancakeswap V2': [],
      'Pancakeswap V1': [],
      'Julswap V1': [],
      'Sushiswap V1': [],
      'Quickswap V1': [],
      'Honeyswap V1': [],
    },
    tval: {
      'Uniswap V2': '',
      'Honeyswap V1': '',
      'Pancakeswap V1': '',
      'Pancakeswap V2': '',
      'Julswap V1': '',
      'Sushiswap V1': '',
      'Quickswap V1': '',
    },
    presalesLoading: true,
    locksLoading: true,
    mobileTab: 0
  }),

  computed: {
    numPresales () {
      var length = Object.keys(this.presales).reduce((a, exchange) => { return a + this.presales[exchange].length }, 0)
      return length
    },
    tvalLocked () {
      var tval = Object.keys(this.tval).reduce((a, exchange) => { return a + (parseInt(this.tval[exchange].tval) || 0) }, 0)
      return tval
    }
  },

  methods: {
    tvalHumanFormat (tval) {
      var amount = parseInt(tval)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
      /* if (amount >= 1000000) {
        var deci = Number(amount / 1000000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
        return deci + 'M'
      } else if (amount > 1000) {
        return Math.floor(amount / 1000) + 'k'
      }
      return amount || 0 */
    },
    goToPresale (presale) {
      // this.$store.commit('switchExchange', presale.exchange)
      this.$root.ammLinkTwo(`/ilo/${presale.presale_contract}`, presale.exchange)
    },
    goToPresales (exchange) {
      this.$root.ammLinkTwo(`/ilos`, exchange)
    },
    goToLocks (exchange) {
      this.$root.ammLinkTwo(`/pairs`, exchange)
    },
    async loadToken (address, exchange) {
      var presale_response = await axios.get(`${SETTINGS.AMMS[exchange].server}/presales/info/${address}`)
      var presale = presale_response.data
      var token_response = await axios.get(`${SETTINGS.AMMS[exchange].server}/erc20/token/${presale.s_token}`)
      var token = token_response.data
      token.exchange = exchange
      token.presale_contract = address
      this.tokens.push(token)
    },
    async getPresales (exchange) {
      var page = 0
      var filters = {
        sort: 'start_block',
        sortAscending: true,
        search: '',
        hide_flagged: false,
        hot_only: true
      }
      var response = await axios.post(`${SETTINGS.AMMS[exchange].server}/presales/search`, {filters: filters, page: page, stage: 0})
      var presales = response.data.rows
      presales.map(item => {
        item.exchange = exchange
      })
      this.presales[exchange] = presales
    },
    async getAll () {
      await Promise.all([
        this.getPresales('Uniswap V2'),
        this.getPresales('Pancakeswap V1'),
        this.getPresales('Pancakeswap V2'),
        this.getPresales('Honeyswap V1'),
        this.getPresales('Julswap V1'),
        this.getPresales('Sushiswap V1'),
        this.getPresales('Quickswap V1'),

        this.getNewLocks('Uniswap V2'),
        // this.getNewLocks('Honeyswap V1'),
        // this.getNewLocks('Pancakeswap V1'),
        this.getNewLocks('Pancakeswap V2'),
        // this.getNewLocks('Julswap V1'),
        this.getNewLocks('Sushiswap V1'),
        this.getNewLocks('Quickswap V1'),

        this.getTVAL('Uniswap V2'),
        this.getTVAL('Honeyswap V1'),
        this.getTVAL('Pancakeswap V1'),
        this.getTVAL('Pancakeswap V2'),
        this.getTVAL('Julswap V1'),
        this.getTVAL('Sushiswap V1'),
        this.getTVAL('Quickswap V1'),
      ])
    },
    async getNewLocks (exchange) {
      var page = 0
      var filters = {
        rowsPerPage: 10,
        sort: 'most_recent_lock',
        sortAscending: false,
        search: ''
      }
      var response = await axios.post(`${SETTINGS.AMMS[exchange].server}/uniswap/search`, {filters: filters, page: page})
      var locks = response.data.rows
      locks.map(item => {
        item.exchange = exchange
      })
      locks = locks.filter(item => item.locked_usd > 100000)
      this.locks[exchange] = locks
    },
    async getTVAL (exchange) {
      var response = await axios.get(`${SETTINGS.AMMS[exchange].server}/pol/lock-stats`)
      this.tval[exchange] = response.data
    }
  },

  created () {
    // this.loadToken('0x67A6EaBB1fB634cdFe5636688961556260C48629', 'Pancakeswap')
    this.getAll()
      .catch(e => {})
      .then(() => {
        this.presalesLoading = false
      })
  }
}
</script>