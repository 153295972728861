var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"max-width":"600px"}},[_c('v-slide-y-transition',{attrs:{"appear":""}},[_c('div',{class:['mb-16', {'foreground v-card br-20': _vm.$vuetify.breakpoint.xs}, {'v-card br-20 foreground': !_vm.$vuetify.breakpoint.xs}]},[_c('div',{staticClass:"py-6 title text-center textFaint--text"},[_vm._v(" Our services ")]),_c('router-link',{attrs:{"to":"/services/minter"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{staticClass:"d-flex align-center c-list pa-4 border-b",on:{"click":navigate}},[_c('img',{staticClass:"mr-3",attrs:{"src":require("@/assets/img/icons/token.svg"),"height":"60px","width":"60px"}}),_c('div',[_c('div',{},[_vm._v(" Token Minter ")]),_c('div',{staticClass:"caption textFaint--text"},[_vm._v(" Create your own ENMT Token. ENMT tokens are fully ERC20 compliant Non-Mintable Tokens (fixed supply). ")]),_c('div',{staticClass:"caption textFaint--text"},[_vm._v(" Requires no coding skills and no additional audits. ")])])])]}}])}),_c('router-link',{attrs:{"to":"/services/launchpad"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{staticClass:"d-flex align-center c-list pa-4 border-b",on:{"click":navigate}},[_c('img',{staticClass:"mr-3",attrs:{"src":require("@/assets/img/icons/rocket.svg"),"height":"60px","width":"60px"}}),_c('div',[_c('div',{},[_vm._v(" LaunchPad ")]),_c('div',{staticClass:"caption textFaint--text"},[_vm._v(" Raise capital to kick start your blockchain project ")]),_c('div',{staticClass:"caption textFaint--text"},[_vm._v(" Decentalised presale service ")])])])]}}])}),_c('router-link',{attrs:{"to":"/services/lock-liquidity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{staticClass:"d-flex align-center c-list pa-4 border-b",on:{"click":navigate}},[_c('img',{staticClass:"mr-3",attrs:{"src":require("@/assets/img/icons/padlock.svg"),"height":"60px","width":"60px"}}),_c('div',[_c('div',{},[_vm._v(" Liquidity Locker ")]),_c('div',{staticClass:"caption textFaint--text"},[_vm._v(" Lock Liquidity tokens ")])])])]}}])}),_c('router-link',{attrs:{"to":"/services/lock-tokens"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{staticClass:"d-flex align-center c-list pa-4",on:{"click":navigate}},[_c('img',{staticClass:"mr-3",attrs:{"src":require("@/assets/img/icons/padlock.svg"),"height":"60px","width":"60px"}}),_c('div',[_c('div',{},[_vm._v(" Token Locker ")]),_c('div',{staticClass:"caption textFaint--text"},[_vm._v(" Lock your ERC20 team tokens ")]),_c('div',{staticClass:"caption textFaint--text"},[_vm._v(" Send tokens to someone else that are locked for a configurable period ")])])])]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }