<template>
  <v-container :style="`max-width: ${$vuetify.breakpoint.xs ? '600px': '1100px'};`" :class="[{'pa-0 foreground mobile-page pb-16': $vuetify.breakpoint.xs}]">

    <super-user v-if="$store.state.superUserMode" :address="address" :presale_info="presaleInfo" style="max-width: 600px;" class="mx-auto"></super-user>

    <div class="pb-2">
      
      <div class="d-flex align-center py-2 pr-2">
        <v-btn :to="`${$store.state.ammPrefix}/ilos`" text rounded color="textFaint">
          <v-icon class="mr-2">mdi-arrow-left</v-icon>
          Back to presales
        </v-btn>
        <v-spacer></v-spacer>
        <template v-if="!firstLoad">
          <!-- PRESALE STAGE -->
          <div class="font-weight-medium px-2 br-8" style="position: relative; overflow: hidden;">
            <div :class="presaleStageColor" style="position: absolute; left: 0px; top: 0px; bottom: 0px; right: 0px;opacity: 0.2;">

            </div>
            <span class="text-uppercasee">{{ presaleStageString }}</span>
          </div>
          <v-btn v-if="userIsOwner || SHOWALL" :to="`${$store.state.ammPrefix}/ilo/${this.address}/edit`" color="textFaint" text rounded class="ml-2">
            Edit presale
          </v-btn>
        </template>

      </div>
    </div>

    <div v-if="showWarning" :class="{'pa-2': $vuetify.breakpoint.xs}">
      <v-card class="mb-2 d-flex align-center font-weight-medium pa-4 br-20">
        <div>
          <div class="d-flex align-center mb-2 orange--text">
            <img 
            src="@/assets/img/flags/exclamation.svg" 
            height="26px"
            class="mr-2"
            width="26px">
            Safety Alert
          </div>
          <div class="caption textFaint--text font-weight-medium">
            This is a decentralised and open presale platform. Similar to Uniswap anyone can create and name a presale freely
            including fake versions of existing tokens. It is also possible for developers of a token to mint near infinite
            tokens and dump them on locked liquidity.
            Please do your own research before using this platform.
          </div>
          <div class="mt-2 d-flex align-center">
            <v-btn to="/terms/ilos" text small rounded>
              Terms And Conditions
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn href="https://unicrypt.medium.com/decentralised-presales-99809cdb398d" target="_blank"
            rounded small text color="text" class="mr-2">
              More info
            </v-btn>
            <v-btn color="orange" rounded text class="white--text" @click="showWarning = false">
              I understand
            </v-btn>
          </div>
        </div>
      </v-card>
    </div>

    <v-row dense v-if="firstLoad" class="ma-0">
      <v-col cols="12" md="6">
        <div :class="['mb-16', {'pa-4': $vuetify.breakpoint.xs}, {'v-card pa-8 br-20': !$vuetify.breakpoint.xs}]">
          <div class="text-center" style="height: 80vh;">
            <v-progress-circular
            indeterminate
            size="130"
            color="#aaa">
              <img 
              src="@/assets/img/UNCX_fill.svg" 
              height="120px"
              class="greyscale"
              width="120px">
            </v-progress-circular>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div :class="['mb-16', {'pa-4': $vuetify.breakpoint.xs}, {'v-card pa-8 br-20': !$vuetify.breakpoint.xs}]">
          <div class="text-center" style="height: 80vh;">
            <v-progress-circular
            indeterminate
            size="130"
            color="#aaa">
              <img 
              src="@/assets/img/UNCX_fill.svg" 
              height="120px"
              class="greyscale"
              width="120px">
            </v-progress-circular>
          </div>
        </div>
      </v-col>
    </v-row>

    <template v-else>

      <!-- PRO AND CON -->
      <v-row dense class="ma-0">
        <v-col cols="12" md="6">

          <div :class="['mb-16', {'': $vuetify.breakpoint.xs}, {'v-card br-20': !$vuetify.breakpoint.xs}]">
            
            <div class="pa-4 d-flex align-center">
              <coin-icon :url="dbInfo.icon_url" :size="110"></coin-icon>
              <div class="ml-0">
                <v-btn text @click.stop="$root.tokenPage.open(presaleInfo.token.address)" class="title pa-0 pl-4" style="min-width: 10px;">
                  <span class="text-truncate">
                    {{ presaleInfo.token.name }}
                  </span>
                  <v-icon small color="textFaint">mdi-chevron-up</v-icon>
                </v-btn>
                <div class="ml-1">
                  <v-btn small color="textFaint" text :href="`${$store.state.etherscan_url}/address/${presaleInfo.token.address}`" target="_blank">
                    {{ $store.state.explorer_name }}
                    <v-icon x-small>mdi-arrow-top-right</v-icon>
                  </v-btn>
                  <copy-address :address="presaleInfo.token.address" color="textFaint"></copy-address>
                  <v-btn v-if="dbInfo.website_url" small text color="textFaint" rounded :href="dbInfo.website_url" target="_blank">
                    Website
                    <v-icon x-small>mdi-arrow-top-right</v-icon>
                  </v-btn>
                  <v-btn v-if="dbInfo.twitter_url" small text color="textFaint" rounded :href="dbInfo.twitter_url" target="_blank">
                    Twitter
                    <v-icon x-small>mdi-arrow-top-right</v-icon>
                  </v-btn>
                  <v-btn v-if="dbInfo.telegram_url" small text color="textFaint" rounded :href="dbInfo.telegram_url" target="_blank">
                    Telegram
                    <v-icon x-small>mdi-arrow-top-right</v-icon>
                  </v-btn>
                </div>
                <!-- BADGES -->
                <div class="d-flex align-center flex-wrap">

                  <template v-if="tokenHydrated.address">

                    <div class="d-flex align-center font-weight-medium pr-2 br-8" style="position: relative; overflow: hidden;">
                      <v-icon size="16" color="text" class="mr-1">mdi-lock</v-icon>
                      <span class="text--text">
                        {{ presaleInfo.liquidity_percent / 10 }}%
                      </span>
                    </div>

                    <div v-if="dbInfo.hot" class="caption d-flex align-center font-weight-medium primary white--text pr-2 br-20">
                      <v-icon size="16" color="white" class="mx-1">mdi-rocket</v-icon>
                      Incubated
                    </div>

                    <div v-if="tokenomicsScore < 70 || tokenomicsArentCredible" class="caption d-flex align-center font-weight-medium red white--text pr-2 br-20">
                      <v-icon size="16" color="white" class="mx-1">mdi-chart-pie</v-icon>
                      <span>
                        {{ tokenomicsScore }}%
                      </span>
                    </div>

                    <div v-else class="caption d-flex align-center font-weight-medium lgrad-blue white--text pr-2 br-20">
                      <v-icon size="16" color="white" class="mx-1">mdi-chart-pie</v-icon>
                      <span>
                        {{ tokenomicsScore }}%
                      </span>
                    </div>

                    <div v-if="tokenHydrated.isENMT" class="caption d-flex align-center font-weight-medium lgrad-blue white--text pr-2 br-20">
                      <v-icon size="16" color="white" class="mx-1">mdi-leaf</v-icon>
                      <span>
                        ENMT
                      </span>
                    </div>

                    <div v-if="dbInfo.audit_rating === 2" class="caption d-flex align-center font-weight-medium red white--text pr-2 br-20">
                      <v-icon size="16" color="white" class="mx-1">mdi-file-document-outline</v-icon>
                      <span>
                        Flagged
                      </span>
                    </div>

                    <div v-else-if="dbInfo.audit_rating === 1" class="caption d-flex align-center font-weight-medium orange white--text pr-2 br-20">
                      <v-icon size="16" color="white" class="mx-1">mdi-file-document-outline</v-icon>
                      Audit
                    </div>

                    <div v-else-if="dbInfo.audit_rating === 0" class="caption d-flex align-center font-weight-medium lgrad-blue white--text pr-2 br-20">
                      <v-icon size="16" color="white" class="mx-1">mdi-file-document-outline</v-icon>
                      <span>
                        Audit
                      </span>
                    </div>

                    <div v-else-if="!tokenHydrated.isENMT" class="caption d-flex align-center font-weight-medium red white--text pr-2 br-20">
                      <v-icon size="16" color="white" class="mx-1">mdi-file-document-outline</v-icon>
                      <span>
                        Audit
                      </span>
                    </div>
                  </template>

                  <div v-if="dbInfo.kyc_rating === 0" class="caption d-flex align-center font-weight-medium lgrad-blue white--text pr-2 br-20">
                    <v-icon size="16" color="white" class="mx-1">mdi-magnify</v-icon>
                    <span>
                      KYC
                    </span>
                  </div>

                  <div v-else class="caption d-flex align-center font-weight-medium red white--text pr-2 br-20">
                    <v-icon size="16" color="white" class="mx-1">mdi-magnify</v-icon>
                    <span>
                      KYC
                    </span>
                  </div>

                </div>
                <!-- BADGES -->
              </div>
            </div>

            <div class="d-flex justify-center">
              <div v-if="false" class="textFaint--text text-center caption">
                Total supply: {{ totalSupplyHuman }} {{ presaleInfo.token.symbol }}
              </div>
              <div style="width: 80%;max-width: 250px;">
                <donut-chart :chartdata="chartData" :options="chartOptions" :red="tokenomicsArentCredible" ref="donutChart1" />
              </div>
            </div>

            <!-- PRICE IMPACT -->
            <div v-if="rugPullSeverityRisk <= 20" class="caption d-flex align-center font-weight-medium pa-4 mb-1" style="position: relative; overflow: hidden;">
              <div class="primary" style="position: absolute; left: 0px; top: 0px; bottom: 0px; right: 0px;opacity: 0.2;">
              </div>
              <div class="flex">
                <v-progress-linear rounded height="20" :value="rugPullSeverityRisk" 
                color="background" background-color="" class="br-20">
                  <div class="caption text--text font-weight-bold">
                    -{{ rugPullSeverityRisk }}% price impact
                  </div>
                </v-progress-linear>
                <div>
                  if unlocked tokens are dumped on the pool.
                </div>
                <div class="d-flex  align-center caption mt-3 font-weight-medium">
                  Decrease risk in this presale by asking the devs to 
                  <v-btn text rounded depressed small color="white" class="text--text"
                  :to="`/services/lock-tokens?token=${presaleInfo.token.address}`">
                    Lock team tokens
                    <v-icon small class="ml-1">mdi-lock-outline</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>

            <div v-if="rugPullSeverityRisk > 20" class="caption white--text red d-flex align-center font-weight-medium pa-4 mb-1">
              <div class="flex">
                <v-progress-linear height="20" :value="rugPullSeverityRisk" 
                color="#ffffff66" background-color="" class="br-20">
                  <div class="white--text font-weight-bold">
                    -{{ rugPullSeverityRisk }}% price impact
                  </div>
                </v-progress-linear>
                <div>
                  if unlocked tokens are dumped on the pool.
                </div>
                <div class="d-flex  align-center caption mt-3 font-weight-medium">
                  Decrease risk in this presale by asking the devs to 
                  <v-btn text rounded depressed small color="white" class="white--text"
                  :to="`/services/lock-tokens?token=${presaleInfo.token.address}`">
                    Lock team tokens
                    <v-icon small class="ml-1">mdi-lock-outline</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>

            <div v-if="tokenomicsArentCredible" class="caption white--text red d-flex align-center font-weight-medium pa-4 mb-1">
              <div class="flex">
                <v-progress-linear height="20" :value="100" 
                color="#ffffff66" background-color="" class="br-20">
                  <div class="caption white--text font-weight-bold">
                    -100% price impact
                  </div>
                </v-progress-linear>
                <div>
                  if new tokens are <strong>minted</strong> and dumped on the pool.
                </div>
                <div class="d-flex  align-center caption mt-3 font-weight-medium">
                  This token has no audit and is not an ENMT token. This means the contract may contain 
                  minting, blacklisting, or high taxation exploits.
                </div>
              </div>
            </div>
            <!-- PRICE IMPACT -->

            <div v-if="dbInfo.kyc_rating !== 0" class="d-flex red white--text align-center font-weight-medium pa-4 border-b mb-1">
              <v-icon size="26" color="white" class="mx-1">mdi-magnify</v-icon>
              <div>
                This team is anonymous / This is a high risk ILO
              </div>
            </div>

            <div v-if="dbInfo.audit_rating === 2" class="d-flex red white--text align-center font-weight-medium pa-4 border-b">
              <v-icon size="26" color="white" class="mx-1">mdi-file-document-outline</v-icon>
              <div>
                This token has been flagged by independant auditors. Please check the Audit tab for details.
              </div>
            </div>

            <template v-else-if="dbInfo.audit_rating === 1">
              <!-- minor warning ignore -->
            </template>

            <div v-else-if="dbInfo.audit_rating !== 0 && !tokenHydrated.isENMT" class="d-flex red white--text align-center font-weight-medium pa-4 border-b">
              <v-icon size="26" color="white" class="mx-1">mdi-file-document-outline</v-icon>
              <div>
                This token has no audits on Unicrypt.
              </div>
            </div>

            <!-- LOCKS -->
            <div v-if="tokenLocks.length > 0">
              <lock-row 
              v-for="lock in tokenLocks" 
              :key="lock.id"
              :item="lock"
              :disableDialogs="true"
              :tokenHydrated="presaleInfo.token">
              </lock-row>
            </div>
            <!-- LOCKS -->
          </div>

        </v-col>
        <v-col cols="12" md="6">

          <div :class="['', {'': $vuetify.breakpoint.xs}, {'v-card br-20': !$vuetify.breakpoint.xs}]">
            <!-- AUDIT -->
            <audit-block :address="presaleInfo.token.address" @on-update="loadInfo"></audit-block>
            <!-- AUDIT -->
          </div>

          <div class="py-1">
            <v-card outlined :class="['br-20 pa-4 c-list']" href="https://t.me/Unicrypt_Presales" target="_blank">
              <div class="d-flex align-center">
                <v-icon size="60">mdi-telegram</v-icon>
                <div class="ml-4">
                  <div class="caption text--text">
                    Discuss and educate yourself about ILOs on Telegram
                  </div>
                  <div class="textFaint--text">
                    Unicrypt ILO Chat
                    <v-icon small color="textFaint">mdi-arrow-top-right</v-icon>
                  </div>
                </div>
              </div>
            </v-card>
          </div>

          <!-- PRESALE CARD -->
          <div :class="['mb-16', {'pa-4': $vuetify.breakpoint.xs}, {'v-card pa-8 br-20': !$vuetify.breakpoint.xs}]">
            
            <v-card v-if="!showRiskyPresaleCard && tokenomicsArentCredible" class="mb-2 red white--text d-flex align-center font-weight-medium pa-4 br-20">
              <div>
                <div class="d-flex align-center mb-2">
                  <img 
                  src="@/assets/img/flags/exclamation.svg" 
                  height="26px"
                  class="mr-2"
                  width="26px">
                  SAFETY ALERT
                </div>
                <div class="caption font-weight-medium">
                  This token is high risk. Please confirm you have read all warnings and understand that you stand to lose everything you invest in 
                  this presale.
                </div>
                <div class="mt-2 d-flex align-center">
                  <v-spacer></v-spacer>
                  <v-btn color="white" rounded class="red--text" @click="showRiskyPresaleCard = true">
                    I understand
                  </v-btn>
                </div>
              </div>
            </v-card>
            
            <template v-else>
              <v-card v-if="SHOWALL" class="pa-4 mb-2 br-20">
                <presale-info-card :address="address"></presale-info-card>
              </v-card>

              <div outlined v-if="presaleStage === 0 || SHOWALL" class="mb-2">
                <reserve-with-uncl :presale_info="presaleInfo"></reserve-with-uncl>
              </div>

              <v-card outlined v-if="presaleStage === 0" class="text--text background text-center br-8 pa-4 mb-2">
                <div class="title">
                  Round 1 starts {{ timeToStartBlock }}
                </div>
                <div class="d-flex">
                  <div class="">
                    {{ startBlock - blockNumber }} block{{ startBlock - blockNumber === 1 ? '' : 's'}} to go
                  </div>
                  <v-spacer></v-spacer>
                  <div class="">
                    {{ dateStartBlockHuman }}
                  </div>
                </div>
                <div v-if="$store.state.superUserMode && $store.state.user.jwtObject.user_id === '21'" class="primary--text">
                  {{ presaleInfo.start_block - blockNumber }} blocks to go
                </div>
              </v-card>

              <v-card v-if="presaleStage === 1" outlined class="background textFaint--text text-center br-8 pa-4 mb-2">
                <div class="title text--text">
                  Round 2 starts {{ timeToRound2 }}
                </div>
                <div class="d-flex">
                  <div class="textFaint--text">
                    {{ round2StartBlock - blockNumber }} block{{ round2StartBlock - blockNumber === 1 ? '' : 's'}} to go
                  </div>
                  <v-spacer></v-spacer>
                  <div class="textFaint--text">
                    {{ round2DateHuman }}
                  </div>
                </div>
              </v-card>

              <!-- PROGRESS BAR -->
              <div class="px-2 mt-4">

                <div class="d-flex align-center justify-center text--text mt-1 mb-1">
                  <div class="primary--text title">
                    {{ totalEthCollectedHuman }}
                  </div>
                  <div class="px-1 textFaint--text">
                    /
                  </div>
                  <div class="textFaint--text d-flex font-weight-regular align-center">
                    {{ hardcapHuman }} {{ baseTokenSymbol }}
                  </div>
                </div>

                <v-progress-linear rounded striped height="20" :value="presaleProgress" 
                color="primary" background-color="background">
                  <div class="caption text--text font-weight-bold">
                    {{ presaleProgress }}% complete
                  </div>
                </v-progress-linear>

              </div>
              <!-- PROGRESS BAR -->

              <v-row v-if="true" class="mt-4">
                <v-col class="text-center" cols="4">

                  <v-progress-circular
                  :value="presaleInfo.liquidity_percent / 10"
                  :rotate="-90"
                  size="40"
                  width="3"
                  color="primary">
                    <v-icon size="20" color="primary">mdi-lock</v-icon>
                  </v-progress-circular>

                  <div class="title primary--text">
                    {{ presaleInfo.liquidity_percent / 10 }}%
                  </div>
                  <div class="caption textFaint--text">
                    Lock {{ lockPeriodHuman }}
                  </div>
                </v-col>
                <v-col class="text-center" cols="4">
                  <v-progress-circular
                  :value="0"
                  :rotate="-90"
                  size="40"
                  width="3"
                  color="primary">
                    <v-icon size="20" color="primary">mdi-account</v-icon>
                  </v-progress-circular>
                  <div class="title primary--text">
                    {{ presaleInfo.num_buyers }}
                  </div>
                  <div class="caption textFaint--text">
                    Participants
                  </div>
                </v-col>
                <v-col class="text-center" cols="4">
                  <v-progress-circular
                  :value="tokenomicsScore"
                  :rotate="-90"
                  size="40"
                  width="3"
                  color="primary">
                    <v-icon size="20" color="primary">mdi-chart-pie</v-icon>
                  </v-progress-circular>
                  <div :class="['title', 
                  {'primary--text': tokenomicsScore >= 60},
                  {'textFaint--text': tokenomicsScore < 60}]">
                    {{ tokenomicsScore }}%
                  </div>
                  <div class="caption textFaint--text">
                    Tokenomics
                  </div>
                </v-col>
              </v-row>

              <v-stepper v-if="presaleStage > 0 && false" alt-labels v-model="presaleStep">
                <v-stepper-header>
                  <v-stepper-step 
                  step="1" 
                  :complete="presaleStep > 1">
                    <span class="textFaint--text">
                      Round 1
                    </span>
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step step="2" :complete="presaleStep > 2">
                    <span class="textFaint--text">
                      Round 2
                    </span>
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step step="3" :complete="presaleStep > 2">
                    <span class="textFaint--text">
                      <template v-if="presaleStage === 4">
                        Failed
                      </template>
                      <template v-else>
                        Complete
                      </template>
                    </span>
                  </v-stepper-step>

                </v-stepper-header>
              </v-stepper>

              <div v-if="false" class="display-1 mb-6 d-flex align-center">
                
                <v-spacer></v-spacer>
                <!--
                <div class="title font-weight-medium excolor px-2 py-2 br-8" style="position: relative; overflow: hidden;">
                  <div :class="presaleStageColor" style="position: absolute; left: 0px; top: 0px; bottom: 0px; right: 0px;opacity: 0.2;">

                  </div>
                  <span class="text-uppercasee">{{ presaleStageString }}</span>
                </div>
                -->
              </div>

              <div class="d-flex justify-center my-8">
                <v-btn text rounded large @click="tab = 0" :color="tab === 0 ? 'primary' : 'textFaint'">
                  Presale
                </v-btn>
                <v-btn text rounded large @click="tab = 1" :color="tab === 1 ? 'primary' : 'textFaint'">
                  Info
                </v-btn>
                <v-btn text rounded large @click="tab = 2" :color="tab === 2 ? 'primary' : 'textFaint'">
                  <v-icon small>mdi-forum-outline</v-icon>
                  <DisqusCount shortname='unicrypt-network' :identifier="netIdentifier" />
                </v-btn>
              </div>

              <div v-show="tab === 0">

                <div v-if="!sEthers.coinbase" class="pa-2">
                  <v-btn large block rounded outlined color="primary" @click="connectWallet" style="border: 1.5px solid;">
                    <v-icon size="24" class="mr-1">mdi-ethereum</v-icon>
                    <div>
                      <div class="title">
                        Connect your wallet
                      </div>
                    </div>
                  </v-btn>
                </div>

                <v-card class="pa-4 br-20" v-if="presaleStage === 0 || presaleStage === 1">
                  <round-1-requirements :round1Length="presaleInfo.round1_length" :settings_contract_address="presale_settings_address"></round-1-requirements>
                </v-card>
                
                <v-card v-if="userInfo.tokens_owed !== '0' && (presaleStage === 1 || presaleStage === 2 || presaleStage === 3)" outlined class="d-flex background textFaint--text align-center pa-4 mt-4 br-20">
                  <coin-icon :url="dbInfo.icon_url" :size="30"></coin-icon>
                  <div class="ml-3">
                    <div>
                      You've bought
                    </div>
                    <div class="font-weight-bold">
                      {{ userTokensOwedHuman }} {{ presaleInfo.token.symbol }}
                    </div>
                    <div class="caption textFaint--text">
                      You can withdraw your {{ presaleInfo.token.symbol }} once the presale has concluded.
                    </div>
                  </div>
                </v-card>

                <!-- BUY CARD -->
                <template v-if="
                (
                (presaleStage === 1 && userHoldsRound1Tokens) ||
                (presaleStage === 2) || 
                SHOWALL
                ) && sEthers.coinbase">
                  <v-card class="pa-4 mt-4 br-20">
                    <user-spend-eth :presaleInfo="presaleInfo"></user-spend-eth>
                  </v-card>
                </template>
                <!-- BUY CARD -->

                <!-- WITHDRAW CARD -->
                <!-- <user-withdraw-token v-if="presaleStage === 5 && userInfo.tokens_owed !== '0' || SHOWALL" :presaleInfo="presaleInfo" :presaleSettings="presaleSettings" class="mt-4"></user-withdraw-token> -->
                <user-withdraw-token v-if="presaleStage === 5 && userInfo.eth_deposited !== '0' || SHOWALL" :presaleInfo="presaleInfo" :presaleSettings="presaleInfo" class="mt-4"></user-withdraw-token>
                <user-withdraw-eth v-if="presaleStage === 4 && sEthers.coinbase && (userInfo.eth_deposited !== '0' || userInfo.uncl_owed !== '0') || SHOWALL" :presaleInfo="presaleInfo" class="mt-4"></user-withdraw-eth>
                <!-- WITHDRAW CARD -->

                <div v-if="presaleStage === 3 || SHOWALL" class="v-card br-20 mt-8 pa-8 text-center">
                  <div class="title mb-2 primary--text">
                    Presale success!
                  </div>
                  <div class="">
                    Anyone from the public can call this function to create the pool, lock liquidity and enable token withdrawals.
                  </div>
                  <v-btn @click="addLiquidity" rounded class="mt-3" x-large block outlined>
                    Create {{ $store.state.exchange }} Pair And Lock Liquidity
                  </v-btn>
                </div>

                <v-card v-if="false" outlined class="background br-20 pa-4">
                  <div class="text-center caption textFaint--text">
                    Add {{ presaleInfo.token.symbol }} to your wallet as a custom token with the following address
                  </div>
                  <div class="text-center">
                    <copy-address :address="presaleInfo.token.address" color=""></copy-address>
                  </div>
                </v-card>

                <div v-if="false" class="caption mt-8">
                  <div>
                    {{ presaleInfo }}
                  </div>
                </div>
              </div>

              <v-card v-show="tab === 1" class="mt-3 br-20">

                <div class="mt-4 textFaint--text text-start">

                  <div class="">
                    <div class="title text-center text--text mb-4 mt-10">
                      Presale info
                    </div>
                    <div class="textFaint--text pa-4">
                      <div v-if="dbInfo.website_url">
                        Website: 
                        <a :href="dbInfo.website_url" target="_blank" class="deadlink primary--text">
                          {{ dbInfo.website_url }}
                          <v-icon x-small color="primary">mdi-arrow-top-right</v-icon>
                        </a>
                      </div>
                      <div v-if="dbInfo.twitter_url">
                        Twitter: 
                        <a :href="dbInfo.twitter_url" target="_blank" class="deadlink primary--text">
                          {{ dbInfo.twitter_url }}
                          <v-icon x-small color="primary">mdi-arrow-top-right</v-icon>
                        </a>
                      </div>
                      <div v-if="dbInfo.telegram_url">
                        Telegram: 
                        <a :href="dbInfo.telegram_url" target="_blank" class="deadlink primary--text">
                          {{ dbInfo.telegram_url }}
                          <v-icon x-small color="primary">mdi-arrow-top-right</v-icon>
                        </a>
                      </div>
                    </div>
                    <div class="pa-4">
                      <div>
                        Start block: {{ startBlock }} (Approx: {{ dateStartBlockHuman }})
                      </div>
                      <div v-if="$store.state.superUserMode && $store.state.user.jwtObject.user_id === '21'" class="primary--text">
                        Start block real: {{ presaleInfo.start_block }}
                      </div>
                      <div>
                        End block: {{ presaleInfo.end_block }} (Approx: {{ dateEndBlockHuman }})
                      </div>
                    </div>
                  </div>

                  <div class="pa-3 d-flex border-b">
                    <v-icon size="40">mdi-lock-outline</v-icon>
                    <div class="ml-4">
                      <div class="text--text">
                        {{ presaleInfo.liquidity_percent / 10 }}% {{ baseTokenSymbol }} raised liquidity lock
                      </div>
                      <div class="textFaint--text">
                        {{ lockPeriodHuman }} lock duration
                      </div>
                    </div>
                  </div>

                  <div class="pa-3 d-flex border-b">
                    <v-icon size="40">mdi-ethereum</v-icon>
                    <div class="ml-4">
                      <div class="text--text">
                        {{ softcapHuman }} {{ baseTokenSymbol }}
                      </div>
                      <div class="textFaint--text">
                        Softcap
                      </div>
                    </div>
                  </div>

                  <div class="pa-3 d-flex border-b">
                    <v-icon size="40">mdi-ethereum</v-icon>
                    <div class="ml-4">
                      <div class="text--text">
                        {{ hardcapHuman }} {{ baseTokenSymbol }}
                      </div>
                      <div class="textFaint--text">
                        Hardcap
                      </div>
                    </div>
                  </div>

                  <div class="pa-3 d-flex border-b">
                    <v-icon size="40">mdi-ethereum</v-icon>
                    <div class="ml-4">
                      <div class="text--text">
                        {{ userMaxLimitHuman }} {{ baseTokenSymbol }}
                      </div>
                      <div class="textFaint--text">
                        Max spend per account
                      </div>
                    </div>
                  </div>

                  <div class="pa-3 d-flex border-b">
                    <v-icon size="40">mdi-ethereum</v-icon>
                    <div class="ml-4">
                      <div class="text--text">
                        {{ tokenPriceHuman }} {{ presaleInfo.token.symbol }} <span class="textFaint--text caption">/ per {{ baseTokenSymbol }}</span>
                      </div>
                      <div class="textFaint--text">
                        Presale price
                      </div>
                    </div>
                  </div>

                  <div class="pa-3 d-flex">
                    <v-icon size="40">mdi-ethereum</v-icon>
                    <div class="ml-4">
                      <div class="text--text">
                        {{ listingPriceHuman }} {{ presaleInfo.token.symbol }} <span class="textFaint--text caption">/ per {{ baseTokenSymbol }}</span>
                      </div>
                      <div class="textFaint--text">
                        Listing price
                      </div>
                    </div>
                  </div>

                </div>

              </v-card>

              <div v-if="tab === 2">
                <div class="text-center textFaint--text mb-4">
                  Discuss the presale token. Mention mints / proxies or dangerous code below.
                </div>
                <comments :identifier="`token_${presaleInfo.token.address}`"></comments>
              </div>
            </template>

          </div>
          <!-- PRESALE CARD -->
        </v-col>
      </v-row>
      <!-- PRO AND CON -->
    </template>

  </v-container>
</template>

<script>
import PresaleSettingsContract from '@/smart-contracts/presales/v5/presale-settings'
import PresaleContract from '@/smart-contracts/presales/v5/presale-contract'
import PresaleABI from '@/smart-contracts/presales/v5/presale-abis'
import ReserveWithUncl from '@/views/presale/presale/v5/reserve-with-uncl'
import VestingPager from '@/smart-contracts/token-locker/pager-contract'
import VESTABI from '@/smart-contracts/token-locker/token-locker-abis'
import { ethers } from 'ethers'
import TxWrapper from '@/smart-contracts/tx-wrapper'
import PresaleInfoCard from '@/views/presale/presale/presale-info-card'
import UserWithdrawToken from '@/views/presale/presale/user-withdraw-token'
import UserWithdrawEth from './user-withdraw-eth'
import UserSpendEth from './user-spend-eth'
import SuperUser from './super-user'
import moment from 'moment'
import Round1Requirements from '@/views/presale/presale/round-1-requirements'
import ERC20 from '@/smart-contracts/erc20/pager'
import ERC20BALANCE from '@/smart-contracts/erc20'
import DonutChart from '@/components/charts/donut6burn'
import Comments from '@/components/disqus/comments'
import { DisqusCount } from 'vue-disqus'
import LockRow from '@/views/locker/erc20/user/user-row'
import AuditBlock from '@/views/audit-kyc/main-block'

export default {
  components: {
    UserWithdrawToken,
    UserWithdrawEth,
    UserSpendEth,
    Round1Requirements,
    PresaleInfoCard,
    DonutChart,
    Comments,
    DisqusCount,
    LockRow,
    ReserveWithUncl,
    SuperUser,
    AuditBlock
  },

  props: {
    address: {
      type: String
    }
  },

  data: () => ({
    tab: 0,
    showWarning: true,
    userInfo: {
      eth_deposited: '0',
      tokens_owed: '0',
      uncl_owed: '0'
    },
    tokenHydrated: {},
    presaleInfo: {
      presale_owner: '',
      eth_price: '0',
      token_price: '0',
      max_spend_per_buyer: '0',
      hardcap: '0',
      softcap: '0'
    },
    dbInfo: {

    },
    totalSupply: '0',
    userHoldsRound1Tokens: false,
    SHOWALL: false,
    firstLoad: true,
    chartData: {
      labels: ['Presale', 'Liquidity', 'Fees', 'Locked', 'Burnt', 'Unlocked'],
      datasets: [{
        data: [],
        backgroundColor: [
          'rgb(20, 240, 132)',
          'rgb(54, 162, 235)',
          'rgba(0, 0, 0, 0.1)',
          'rgba(0, 0, 0, 0.1)',
          'rgba(0, 0, 0, 0.1)',
          'rgba(0, 0, 0, 0.1)',
        ],
        borderWidth: 1
        // borderColor: 'rgba(0,0,0,0.1)'
      }]

    },
    chartOptions: {
      cutoutPercentage: 70,
      legend: {
        position: 'right'
      }
    },
    tokenLocks: [],
    tokenomicsScore: 0,
    TOKENOMICS: {
      presale: '0',
      liquidity: '0',
      fees: '0',
      locked: '0',
      unlocked: '0'
    },
    showRiskyPresaleCard: false
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    presale_settings_address () {
      return PresaleABI.presale_settings_address()
    },
    tokenomicsArentCredible () {
      return !(this.tokenHydrated.isENMT || this.dbInfo.audit_rating === 0 || this.dbInfo.audit_rating === 1)
    },
    netIdentifier () {
      var identifier = `token_${this.presaleInfo.token.address}`
      return `${this.$store.state.requiredNetwork}_${identifier}`
    },
    blockNumber () {
      return this.$store.state.blockNumber
    },
    startBlock () {
      return Number(this.presaleInfo.start_block)
    },
    tokenAddressCondensed () {
      var address = this.presaleInfo.token.address
      if (!address) {
        return ''
      }
      return address.slice(0, 6) + '...' + address.slice(address.length - 4)
    },
    liquidityAtHardcap () {
      var listingRate = ethers.BigNumber.from(this.presaleInfo.listing_rate).mul(1000).div(this.presaleInfo.token_price).toString()
      var amount = ethers.BigNumber.from(this.presaleInfo.amount).mul(this.presaleInfo.liquidity_percent).mul(listingRate).div(1000000).toString()
      return ethers.BigNumber.from(amount).toString()
    },
    percentTokensOfTotal () {
      if (this.totalSupply === '0') {
        return '0'
      }
      var amount = ethers.BigNumber.from(this.liquidityAtHardcap).add(this.presaleInfo.amount)
      var percent = ethers.BigNumber.from(amount).mul(100).div(this.totalSupply)
      return percent.toNumber()
    },
    baseTokenSymbol () {
      return this.presaleInfo.presale_in_eth ? this.$store.state.nativeGasTokenSymbol : this.presaleInfo.base_token.symbol.toUpperCase()
    },
    userIsOwner () {
      return this.sEthers.coinbase === this.presaleInfo.presale_owner
    },
    totalSupplyHuman () {
      var amount = ethers.utils.formatUnits(this.presaleInfo.token.totalSupply, this.presaleInfo.token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: this.presaleInfo.token.decimals})
    },
    userTokensOwedHuman () {
      var amount = ethers.utils.formatUnits(this.userInfo.tokens_owed, this.presaleInfo.token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: this.presaleInfo.token.decimals})
    },
    userMaxLimitHuman () {
      var amount = ethers.utils.formatUnits(this.presaleInfo.max_spend_per_buyer, this.presaleInfo.base_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    tokenPriceHuman () {
      var amount = ethers.utils.formatUnits(this.presaleInfo.token_price, this.presaleInfo.token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8})
    },
    listingPriceHuman () {
      var amount = ethers.utils.formatUnits(this.presaleInfo.listing_rate, this.presaleInfo.token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8})
    },
    lockPeriodHuman () {
      var endOfLock = moment().unix() + Number(this.presaleInfo.lock_period)
      if (endOfLock > 9999999999) {
        var diff = Number(this.presaleInfo.lock_period) - moment().unix()
        return moment.duration(diff * 1000).years() + ' Years'
      }
      var duration = moment.duration(this.presaleInfo.lock_period * 1000)
      if (duration.years() >= 1) {
        return duration.years() + ' Years'
      }
      if (duration.months() >= 1) {
        return duration.months() + ' Months'
      }
      return duration.days() + ' Days'
    },
    totalEthCollectedHuman () {
      var amount = ethers.utils.formatUnits(this.presaleInfo.total_base_collected, this.presaleInfo.base_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    softcapHuman () {
      var amount = ethers.utils.formatUnits(this.presaleInfo.softcap, this.presaleInfo.base_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    hardcapHuman () {
      var amount = ethers.utils.formatUnits(this.presaleInfo.hardcap, this.presaleInfo.base_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    presaleProgress () {
      var percent = ethers.BigNumber.from(this.presaleInfo.total_base_collected).mul(1000).div(this.presaleInfo.hardcap)
      return percent.toNumber() / 10
      // return 0
    },
    rugPullSeverityRisk () { 
      if (this.TOKENOMICS.liquidity === '0') {
        return 0
      }
      var percent = ethers.BigNumber.from(this.TOKENOMICS.unlocked).mul(1000).div(this.TOKENOMICS.liquidity)
      percent = percent.toNumber() / 10
      if (percent > 100) {
        percent = 100
      }
      return percent
    },
    presaleSoftcapProgress () {
      var percent = ethers.BigNumber.from(this.presaleInfo.total_base_collected).mul(1000).div(this.presaleInfo.softcap)
      return percent.toNumber() / 10
      // return 0
    },
    dateStartBlock () {
      var numBlocks = this.startBlock - this.blockNumber
      var seconds = numBlocks * this.$store.state.blocksPerSecond
      var date = moment().add(seconds, 'seconds')
      return date
    },
    dateStartBlockHuman () {
      return this.dateStartBlock.format('ddd D MMM HH:mm')
    },
    timeToStartBlock () {
      return this.dateStartBlock.fromNow()
    },
    dateEndBlock () {
      var numBlocks = this.presaleInfo.end_block - this.blockNumber
      var seconds = numBlocks * this.$store.state.blocksPerSecond
      var date = moment().add(seconds, 'seconds')
      return date
    },
    dateEndBlockHuman () {
      return this.dateEndBlock.format('ddd D MMM HH:mm')
    },
    timeToEndBlock () {
      return this.dateEndBlock.fromNow()
    },
    round2StartBlock () {
      return Number(this.startBlock) + Number(this.presaleInfo.round1_length)
    },
    round2Date () {
      var numBlocks = this.round2StartBlock - this.blockNumber
      var seconds = numBlocks * this.$store.state.blocksPerSecond
      var date = moment().add(seconds, 'seconds')
      return date
    },
    timeToRound2 () {
      return this.round2Date.fromNow()
    },
    round2DateHuman () {
      return this.round2Date.format('ddd D MMM HH:mm')
    },
    presaleStage () {
      if (this.presaleInfo.lp_generation_complete) {
        return 5 // finalized
      }
      if (this.presaleInfo.force_failed) {
        return 4 // failed
      }
      if (Number(this.blockNumber) > Number(this.presaleInfo.end_block) && ethers.BigNumber.from(this.presaleInfo.total_base_collected).lt(this.presaleInfo.softcap)) {
        return 4 // failed
      }
      if (ethers.BigNumber.from(this.presaleInfo.total_base_collected).gte(this.presaleInfo.hardcap)) {
        return 3 // success
      }
      if (Number(this.blockNumber) > Number(this.presaleInfo.end_block) && ethers.BigNumber.from(this.presaleInfo.total_base_collected).gte(this.presaleInfo.softcap)) {
        return 3 // success
      }
      if (Number(this.blockNumber) >= Number(this.startBlock) && Number(this.blockNumber) <= Number(this.presaleInfo.end_block)) {
        if (Number(this.blockNumber) < (Number(this.startBlock) + Number(this.presaleInfo.round1_length))) {
          return 1 // round 1
        }
        return 2 // round 2
      }

      return 0 // awaiting start
    },
    presaleStep () {
      if (this.presaleStage === 5 || this.presaleStage === 4 || this.presaleStage === 3) {
        return 3
      }
      if (this.presaleStage === 2) {
        return 2
      }
      if (this.presaleStage === 1) {
        return 1
      }
      return 0
    },
    presaleStageString () {
      var arr = ['Awaiting Start', 'LIVE: Round 1', 'LIVE: Round 2', 'Success', 'Failed', 'Markets Initialized']
      return arr[this.presaleStage]
    },
    presaleStageColor () {
      var arr = ['blue', 'primary', 'primary', 'primary', 'pink', 'purple']
      return arr[this.presaleStage]
    }
  },

  watch: {
    sEthersWatcher () {
      this.refresh()
    },
    blockNumber () {
      this.refresh()
    }
  },

  methods: {
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .then(() => {
        })
        .catch(e => {})
    },
    async addLiquidity () {
      TxWrapper.doTransaction(PresaleContract.addLiquidity(this.address), this.$root.$dialog.confirmTx)
        .then(() => { 
          this.refresh()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          // this.lockLoading = false
        })
    },
    async getUserInfo () {
      if (!this.sEthers.coinbase) {
        this.userInfo = {
          eth_deposited: '0',
          tokens_owed: '0'
        }
        this.userHoldsRound1Tokens = false
        return
      }
      var userInfo = await PresaleContract.userInfo(this.address, this.sEthers.coinbase)
      this.userInfo = userInfo

      this.userHoldsRound1Tokens = await PresaleSettingsContract.userHoldsSufficientRound1Token(this.sEthers.coinbase)
      // this.userHoldsRound1Tokens = true
    },
    async getPresaleInfo () {
      var response = await PresaleContract.getInfo(this.address)
      this.presaleInfo = response.presale_info
    },
    async loadInfo () {
      this.$axios.get(`/presales/info/${this.address}`)
        .then(response => {
          this.dbInfo = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    async getTotalSupply () {
      try {
        this.totalSupply = this.tokenHydrated.totalSupply

        var listingRatePercent = ethers.BigNumber.from(this.presaleInfo.listing_rate).mul(1000).div(this.presaleInfo.token_price).toString()
        var unicryptFee = ethers.BigNumber.from(this.presaleInfo.amount).mul(this.presaleInfo.fee_token).div(1000).toString()
        var liquidity = ethers.BigNumber.from(this.presaleInfo.amount).sub(unicryptFee).mul(this.presaleInfo.liquidity_percent).mul(listingRatePercent).div(1000000).toString()

        // tokenLocks
        var numLocks = await VestingPager.getTokenLocksLength(this.presaleInfo.token.address)
        var rows = []
        var page = 0;
        while (rows.length < numLocks) {
          var lock_ids = await VestingPager.getTokenLocks(this.presaleInfo.token.address, page * VESTABI.MAX_GETTER_LENGTH, VESTABI.MAX_GETTER_LENGTH)
          var locks = await VestingPager.getLocks(lock_ids)
          rows.push(...locks)
          page++
        }
        // total locked
        var now = moment().unix()
        var validLocks = rows.filter(item => {
          if (item.end_emission > now && item.shares_withdrawn !== item.shares_deposited && item.condition === ethers.constants.AddressZero) {
            return item
          }
        })
        var amountLocked = validLocks.reduce((a, item) => {
          return a.add(item.tokens_deposited).sub(item.tokens_withdrawn)
        }, ethers.BigNumber.from(0))
        var allLocks = rows.filter(item => {
          if (item.end_emission > now && item.shares_withdrawn !== item.shares_deposited) {
            return item
          }
        })
        this.tokenLocks = allLocks

        var burnt = await ERC20BALANCE.getBalance('0x000000000000000000000000000000000000dEaD', this.presaleInfo.token.address)
        var remain = ethers.BigNumber.from(this.totalSupply).sub(this.presaleInfo.amount).sub(liquidity).sub(unicryptFee).sub(amountLocked).sub(burnt)

        this.TOKENOMICS.presale = this.presaleInfo.amount
        this.TOKENOMICS.liquidity = liquidity
        this.TOKENOMICS.fees = unicryptFee
        this.TOKENOMICS.locked = amountLocked.toString()
        this.TOKENOMICS.unlocked = remain.toString()

        // chart DATA
        var unsold = ethers.utils.formatUnits(remain, this.presaleInfo.token.decimals)
        var amount = ethers.utils.formatUnits(this.presaleInfo.amount, this.presaleInfo.token.decimals)
        unicryptFee = ethers.utils.formatUnits(unicryptFee, this.presaleInfo.token.decimals)
        liquidity = ethers.utils.formatUnits(liquidity, this.presaleInfo.token.decimals)
        amountLocked = ethers.utils.formatUnits(amountLocked, this.presaleInfo.token.decimals)
        burnt = ethers.utils.formatUnits(burnt, this.presaleInfo.token.decimals)

        var data = [amount, liquidity, unicryptFee, amountLocked, burnt, unsold]
        this.chartData.datasets[0].data = data
        this.$refs.donutChart1.update()

        // percent of total
        var percent = ethers.BigNumber.from(this.totalSupply).sub(remain).mul(100).div(this.totalSupply)
        this.tokenomicsScore = Math.min(percent.toNumber(), 100)
      } catch (e) {
        console.log(e)
        // incase on testnets and token vesting is not connected
        // this prevents errors in the browser console that make no sense
      }
    },
    async refresh () {
      this.getPresaleInfo()
      this.getUserInfo()
    },
    async doFirstLoad () {
      this.loadInfo()
      await this.getPresaleInfo()
      this.tokenHydrated = await ERC20.getERC20(this.presaleInfo.token.address)
      this.getUserInfo()
        .then(() => {
          this.getTotalSupply()
        })
        .catch(e => {
          console.log(e)
        })
        .then(() => {
          this.firstLoad = false
        })
    }
  },

  created () {
    this.doFirstLoad()
  }
}
</script>