<template>
  <div>
    
    <div v-if="sEthers.coinbase">
      
      <div class="px-4 py-2 d-flex align-center">
        <div class="textFaint--text title font-weight-bold">
          YOUR ACCOUNT
        </div>
      </div>

      <div class="v-card br-20">

        <v-tabs center-active show-arrows color="textFaint" background-color="foreground" class="br-0">
          <v-tab>
            STAKE
          </v-tab>
          <v-tab>
            WITHDRAW
          </v-tab>
          <v-tab>
            BOOST
          </v-tab>
          <v-tab>
            INFO
          </v-tab>

          <v-tab-item>
            <div>
              
              <div class="pa-4">
                
                <div class="mb-4 v-card caption pa-2 br-8 background">

                  <div class="px-2 d-flex align-center">
                    <span class="textFaint--text">
                      Your staking
                    </span>
                    <v-spacer></v-spacer>
                    <div>
                      {{ $root.formatAmount(user_info.tokens_staking, stake_token_hydrated.decimals) }}
                      {{ stake_token_hydrated.symbol }}
                    </div>
                  </div>

                  <div class="px-2 d-flex align-center">
                    <span class="textFaint--text">
                      Time boost
                    </span>
                    <v-spacer></v-spacer>
                    <div>
                      <span class="flex d-flex justify-end text--text">
                        + {{ user_info.time_boost_percentage / 100 }}%
                      </span>
                      <v-progress-linear height="6" :value="timeBoostProgress"
                      color="lgrad-green"
                      background-color="foreground"
                      reverse
                      style="width: 80px;"
                      class="font-weight-bold br-c">
                      </v-progress-linear>
                    </div>
                  </div>

                  <div class="px-2 d-flex align-center">
                    <span class="textFaint--text">
                      UNCL boost
                    </span>
                    <v-spacer></v-spacer>
                    <div>
                      <span class="flex d-flex justify-end text--text">
                        + {{ user_info.uncl_boost_percentage / 100 }}%
                      </span>
                      <v-progress-linear height="6" :value="UnclBoostProgress"
                      color="lgrad-blue"
                      reverse
                      background-color="foreground"
                      style="width: 80px;"
                      class="font-weight-bold br-c">
                      </v-progress-linear>
                    </div>
                  </div>
                </div>

                <div class="d-flex align-center textFaint--text px-2">
                  <div class="text-center textFaint--text caption">
                    Stake between
                  </div>
                  <span class="caption ml-3 font-weight-medium text-end pr-2">
                  {{ $root.formatAmount(pool_info.min_stake_amount, stake_token_hydrated.decimals) }}
                  </span>
                  <v-icon small color="textFaint">mdi-arrow-left-right</v-icon>
                  <span class="caption ml-1 font-weight-medium pl-2">
                    <template v-if="maxStakeAmountIsMaxUint">
                      Infinity
                    </template>
                    <template v-else>
                      {{ $root.formatAmount(pool_info.max_stake_amount, stake_token_hydrated.decimals) }}
                    </template>
                  </span>
                </div>

                <div class="pa-3 align-center flex-nowrap c-input-wrapper br-8">

                  <div class="text-end caption pb-2">
                    Balance: {{ userTokenBalanceHuman }}
                  </div>

                  <div class="d-flex align-center">
                    <c-input :value.sync="amountStakeHuman" placeholder="0.0" @updateWhileFocussed="onStakeAmountChanged" class="title font-weight-medium">

                    </c-input>

                    <v-btn small color="foreground" class="px-2 py-5" rounded depressed @click.native="$root.tokenPage.open(stake_token_hydrated.address, $store.state.requiredNetwork)" style="min-width: 10px;max-height: unset;">
                      <coin-icon :address="stake_token_hydrated.address" :size="16" class="mr-1"></coin-icon>
                      <span :class="['text-truncate text-uppercase']" :style="$vuetify.breakpoint.xs ? 'max-width: 14ch;' : 'max-width: 20ch;'">
                        {{ stake_token_hydrated.symbol }}
                      </span>
                      <v-icon small>mdi-chevron-down</v-icon>
                    </v-btn>

                    <v-btn rounded small depressed color="foreground" @click="setMax" class="ml-1">
                      MAX
                    </v-btn>
                  </div>

                </div>

                <div class="pr-2 caption textFaint--text text-end">
                  You get:
                  {{ $root.formatAmount(numSharesToReceive, stake_token_hydrated.decimals) }}
                  shares
                </div>

                <!-- MIN MAX STAKE AMOUNT ERRORS -->
                <div v-if="eMinStakeAmount" class="white--text lgrad-red d-flex align-center font-weight-medium pa-4 mt-1">
                  <v-icon size="26" color="white" class="mr-2">mdi-alert-outline</v-icon>
                  <div class="flex">
                    Error: Min stake amount not met
                  </div>
                </div>
                <div v-if="eMaxStakeAmount" class="white--text lgrad-red d-flex align-center font-weight-medium pa-4 mt-1">
                  <v-icon size="26" color="white" class="mr-2">mdi-alert-outline</v-icon>
                  <div class="flex">
                    Error: Max stake amount exceeded
                  </div>
                </div>
                <!-- MIN MAX STAKE AMOUNT ERRORS -->
              </div>

              <div v-if="pool_info.boost_options.length > 0" class="">

                <div class="pa-3 pl-6 font-weight-medium d-flex align-center">
                  <v-progress-linear height="54" :value="timeBoostProgress"
                  color="lgrad-green"
                  background-color=""
                  v-if="false"
                  style="width: 54px;"
                  class="font-weight-bold br-c mr-2">
                    <v-icon size="40" color="white">mdi-flash-circle</v-icon>
                  </v-progress-linear>
                  <div>
                    <div class="caption font-weight-medium">
                      Time boost options
                    </div>
                    <div class="caption textFaint--text font-weight-medium">
                      Lock for longer periods to increase share weight & rewards
                    </div>
                  </div>
                </div>

                <v-slide-group
                  class="py-2 pl-2"
                  show-arrows
                  v-if="true"
                > 
                  <v-slide-item>
                    <div :class="['pa-05 mr-1 br-8', {'gradient-border': selectedBoostOption == 0}]">
                      <v-card
                        @click="selectedBoostOption = 0"
                        :class="['py-3 px-4', {'': selectedBoostOption == 0}]"
                      >
                        <div class="text-center">
                          <div :class="['title font-weight-medium', {'gradient-text': selectedBoostOption == 0}]">
                            0%
                          </div>
                          <div class="d-flex align-center caption textFaint--text">
                            {{ $root.getReadableTimeSpan(pool_info.min_staking_period) }}
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </v-slide-item>
                  <v-slide-item
                    v-for="(boost_option, index) of pool_info.boost_options" 
                    :key="boost_option.time_period"
                  >
                    <div :class="['pa-05 mr-1 br-8', {'gradient-border': selectedBoostOption == index + 1}]">
                      <v-card
                        @click="selectedBoostOption = index + 1"
                        :class="['py-3 px-4 br-8', {'text--text': selectedBoostOption == index + 1}]"
                      >
                        <div class="text-center">
                          <div :class="['title font-weight-medium', {'gradient-text': selectedBoostOption == index + 1}]">
                            + {{ boost_option.boost_percentage / 100 }}%
                          </div>
                          <div class="d-flex align-center textFaint--text caption">
                            {{ $root.getReadableTimeSpan(pool_info.min_staking_period + boost_option.time_period ) }}
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </v-slide-item>
                </v-slide-group>

                <template v-if="false">
                  <div class="d-flex textFaint--text caption px-3 font-weight-medium border-b">
                    <div>
                      Boost
                    </div>
                    <v-spacer></v-spacer>
                    <div>
                      Lock period
                    </div>
                  </div>

                  <div class="mb-2">
                    <div
                    @click="selectedBoostOption = 0"
                    :class="['c-list d-flex align-center pa-3', {'midground primary--text': selectedBoostOption == 0}, {'textFaint--text': selectedBoostOption != 0}]"
                    >
                      <v-icon :color="selectedBoostOption == 0 ? 'primary' : 'textFaint'">mdi-flash-circle</v-icon>
                      <div class="pl-2 font-weight-medium">
                        0%
                      </div>
                      <v-spacer></v-spacer>
                      <div class="d-flex align-center caption">
                        {{ $root.getReadableTimeSpan(pool_info.min_staking_period) }}
                      </div>
                    </div>

                    <div
                    v-for="(boost_option, index) of pool_info.boost_options" 
                    :key="boost_option.time_period"
                    @click="selectedBoostOption = index + 1"
                    :class="['c-list border-t d-flex align-center pa-3', {'midground primary--text': selectedBoostOption == index + 1}, {'textFaint--text': selectedBoostOption != index + 1}]"
                    >
                      <v-icon :color="selectedBoostOption == index + 1 ? 'primary' : 'textFaint'">mdi-flash-circle</v-icon>
                      <div class="pl-2 font-weight-medium">
                        + {{ boost_option.boost_percentage / 100 }}%
                      </div>
                      <v-spacer></v-spacer>
                      <div class="d-flex align-center caption">
                        {{ $root.getReadableTimeSpan(pool_info.min_staking_period + boost_option.time_period ) }}
                      </div>
                    </div>
                  </div>
                </template>

              </div>

              <!--
              <v-expand-transition mode="out-in">
                <div v-if="cSelectedBoostOption.boost_percentage < user_info.boost_percentage">
                  <div class="d-flex align-center midground red--text font-weight-bold pa-4">
                    <v-icon color="red" class="mr-2" size="30">mdi-alert-outline</v-icon>
                    Your time boost must be >= {{ user_info.boost_percentage / 100 }}%
                  </div>
                </div>
              </v-expand-transition>
              -->
              <v-expand-transition mode="out-in">
                <div v-if="selectedBoostOptionTooLow">
                  <div class="pa-1">
                    <div class="v-card pa-3 font-weight-bold d-flex align-center" style="border: 0px;">
                      <div class="red" style="position: absolute; left: 0px; top: 0px; bottom: 0px; right: 0px;opacity: 0.2;">
                      </div>
                      <v-icon class="mr-2 pa-3 lgrad-red br-c" size="30" color="white">mdi-alert-outline</v-icon>
                      <div>
                        <div>
                          Error
                        </div>
                        <div class="caption font-weight-bold">
                          Your time boost must be >= {{ user_info.time_boost_percentage / 100 }}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-expand-transition>

              <div v-if="false" class="pa-4 br-8 border midground d-flex align-center">
                <v-icon size="38">mdi-lock</v-icon>
                <div class="pl-2">
                  <div class="textFaint--text caption">
                    Minimum stake period: {{ $root.getReadableTimeSpan(pool_info.min_staking_period) }}
                  </div>
                  <div class="textFaint--text">
                    Staking period: {{ $root.getReadableTimeSpan(pool_info.min_staking_period) }} 
                    <span class="font-weight-bold text--text">+ {{ $root.getReadableTimeSpan(cSelectedBoostOption.time_period) }}</span>
                  </div>
                </div>
              </div>

              <v-expand-transition mode="out-in">
                <div v-if="predictiveUnlockDateSecondsIncrement > 0">
                  <div class="px-1 pt-1">
                    <div class="textFaint--text pa-3 font-weight-medium d-flex align-center">
                      <v-icon class="mr-2 pa-3 br-c midground" size="30" color="textFaint">mdi-alert</v-icon>
                      <div>
                        <div class="caption text--text">
                          Relock warning
                        </div>
                        <div class="caption font-weight-medium">
                          Your entire staking balance will be relocked when staking more and/or adjusting the time boost.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="px-1 pt-1">
                    <div class="textFaint--text pa-3 font-weight-medium d-flex align-center">
                      <v-icon class="mr-2 pa-3 midground br-c" size="30" color="textFaint">mdi-lock-open</v-icon>
                      <div>
                        <div class="caption text--text">
                          Withdraw date: {{ predictiveUnlockDateForNewStakeFromNow }}
                        </div>
                        <div class="caption font-weight-medium">
                          {{ predictiveUnlockDateForNewStake }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-expand-transition>

              <!-- STAKE FEE -->
              <stake-mod-fee v-if="showStakeModFee" :pool_address="address" :fee_amount="pool_info.stake_mod_fee_amount" :fee_token="pool_info.stake_mod_fee_token" key="stake" class="ma-1" ref="smfStake"></stake-mod-fee>
              <!-- STAKE FEE -->

              <div class="px-2 pb-2">
                <v-row dense class="ma-0 mt-4 br-c" style="overflow: hidden;">
                  <v-col cols="6" class="pa-0">
                    <v-btn class="br-0 background--text" @click="approve" color="text" x-large block depressed :disabled="!allowanceIncreaseRequired" :loading="approvalLoading">
                      Approve
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="pa-0">
                    <v-btn class="br-0 background--text" @click="stakeTokens" color="text" x-large block depressed :disabled="stakeDisabled" :loading="stakeLoading">
                      Stake
                    </v-btn>
                  </v-col>
                </v-row>
              </div>

            </div>
          </v-tab-item>

          <!-- WITHDRAW -->
          <v-tab-item>
            <div class="pa-4">
              
              <div class="pa-4 align-center flex-nowrap c-input-wrapper br-8">

                <div class="caption text-end">
                  Staking: {{ $root.formatAmount(user_info.shares, stake_token_hydrated.decimals) }} shares
                </div>

                <div class="d-flex align-center">
                  <c-input :value.sync="amountWithdrawHuman" placeholder="0.0" @updateWhileFocussed="onWithdrawAmountChanged" class="pa-2 font-weight-bold">

                  </c-input>

                  <span class="font-weight-bold caption">
                    SHARES
                  </span>

                  <v-btn v-if="true" small rounded depressed color="lgrad-green" @click="setMaxWithdraw" class="white--text ml-1">
                    MAX
                  </v-btn>
                </div>

              </div>

              <div class="pa-4 midground br-8 mt-1 textFaint--text text-center">
                You get:
                {{ $root.formatAmount(amountWithdrawInTokens, stake_token_hydrated.decimals) }}
                {{ stake_token_hydrated.symbol }}
              </div>

              <div v-if="!tokenUnlockEpochHasPassed">
                <div class="pa-4 br-8 mt-1 border midground d-flex align-center">
                  <v-icon class="mr-2 pa-3 br-c foreground" size="30" color="orange">mdi-lock-outline</v-icon>
                  <div class="pl-2">
                    <div class="font-weight-bold textFaint--text">
                      Withdrawable: {{ unbondingDateFromNow }}
                    </div>
                    <div class="caption textFaint--text">
                      {{ unbondingDateHuman }}
                    </div>
                  </div>
                </div>
                <div v-if="pool_info.unlock_tokens" class="pa-4 br-8 mt-1 border midground d-flex align-center">
                  <v-icon size="38" color="primary">mdi-lock-open-outline</v-icon>
                  <div class="pl-2">
                    <div class="font-weight-bold primary--text">
                      Lock date override
                    </div>
                    <div class="caption">
                      The pool admin has enabled full token withdrawls.
                    </div>
                  </div>
                </div>
              </div>

              <!-- STAKE FEE -->
              <stake-mod-fee v-if="showStakeModFee" :pool_address="address" :fee_amount="user_info.accepted_stake_fee_amount" :fee_token="user_info.accepted_stake_fee_token" key="withdraw" class="mt-1" ref="smfWithdraw"></stake-mod-fee>
              <!-- STAKE FEE -->

              <!-- v-if="tokensWithdrawable" -->
              <v-btn @click="withdrawTokens" color="lgrad-green" class="white--text br-c mt-3" x-large block depressed :loading="withdrawLoading">
                Withdraw Tokens
              </v-btn>

            </div>
          </v-tab-item>
          <!-- WITHDRAW -->

          <v-tab-item>
            <uncl-boost-widget
            :address="address" 
            :uncl_token_hydrated="uncl_token_hydrated" 
            :pool_info="pool_info"
            :user_info="user_info"
            @hard-refresh="$emit('hard-refresh')"
            class="mb-4"></uncl-boost-widget>
          </v-tab-item>

          <!-- INFO -->
          <v-tab-item>
            <div class="pa-4">
              <div class="d-flex align-center pl-2 py-2 mt-8">
                <span class="textFaint--text font-weight-bold ml-2">
                  Your staking stats
                </span>
                <v-spacer></v-spacer>
              </div>

              <div class="v-card br-8">
                <div class="pa-4 d-flex align-center border-b">
                  <span class="textFaint--text">
                    Tokens staking
                  </span>
                  <v-spacer></v-spacer>
                  <div class="">
                    {{ $root.formatAmount(user_info.tokens_staking, stake_token_hydrated.decimals) }}
                    {{ stake_token_hydrated.symbol }}
                  </div>
                </div>

                <div class="pa-4 d-flex align-center border-b">
                  <span class="textFaint--text">
                    Time boost
                  </span>
                  <v-spacer></v-spacer>
                  <div>
                    <span class="flex d-flex justify-end text--text">
                      + {{ user_info.time_boost_percentage / 100 }}%
                    </span>
                    <v-progress-linear height="6" :value="timeBoostProgress"
                    color="lgrad-green"
                    background-color="midground"
                    reverse
                    style="width: 80px;"
                    class="font-weight-bold br-c">
                    </v-progress-linear>
                  </div>
                </div>

                <div class="pa-4 d-flex align-center border-b">
                  <span class="textFaint--text">
                    UNCL boost
                  </span>
                  <v-spacer></v-spacer>
                  <div>
                    <span class="flex d-flex justify-end text--text">
                      + {{ user_info.uncl_boost_percentage / 100 }}%
                    </span>
                    <v-progress-linear height="6" :value="UnclBoostProgress"
                    color="lgrad-blue"
                    reverse
                    background-color="midground"
                    style="width: 80px;"
                    class="font-weight-bold br-c">
                    </v-progress-linear>
                  </div>
                </div>

                <div class="pa-4 d-flex align-center border-b">
                  <span class="textFaint--text">
                    Total stake weight
                  </span>
                  <v-spacer></v-spacer>
                  <div class="">
                    {{ $root.formatAmount(user_info.share_weight, stake_token_hydrated.decimals) }} SW
                  </div>
                </div>

                <div class="pa-4 d-flex align-center">
                  <span class="textFaint--text">
                    Your pool share
                  </span>
                  <v-spacer></v-spacer>
                  <div class="">
                    {{ poolSharePercent }}%
                  </div>
                </div>
              </div>
            </div>
          </v-tab-item>
          <!-- INFO -->
        </v-tabs>

      </div>
    </div>

    <div v-else class="v-card br-20 text-center pa-6">
      <div>
        <v-icon size="40" color="textFaint">mdi-account-circle</v-icon>
        <div class="textFaint--text caption pt-2">
          Connect your wallet to stake
        </div>
      </div>
      <div class="pt-2">
        <v-btn
        large
        block
        depressed
        class="font-weight-bold midground"
        rounded
        @click="connectWallet"
        >
          CONNECT
        </v-btn>
      </div>
    </div>

  </div>
</template>

<script>
import StakeModFee from './stake-mod-fee'
import UnclBoostWidget from './uncl-boost-widget'
import ERC20 from '@/smart-contracts/erc20/pager'
import { ethers } from 'ethers'
import TxWrapper from '@/smart-contracts/tx-wrapper'
import StakePoolContract from '@/smart-contracts/farms-v2/normal/stake-pool/v1/stake-pool'
import ERC20BALANCE from '@/smart-contracts/erc20'
import moment from 'moment'

export default {

  props: {
    address: null,
    user_info: {
      type: Object,
      default: () => {
        return {
          share_weight: '0',
          shares: '0',
          tokens_staking: '0',
        }
      }
    },
    pool_info: {
      type: Object,
      default: () => {
        return {
          share_weight_total: '0',
          min_stake_amount: '0',
          max_stake_amount: '0',
        }
      }
    },
    stake_token_hydrated: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
  },

  components: {
    StakeModFee,
    UnclBoostWidget
  },

  data: () => ({
    user_func_tab: 'stake', // stake, withdraw, uncl-boost
    uncl_token_hydrated: {
    },

    allowance: '0',

    amountStake: '0',
    amountStakeHuman: '0',

    amountWithdraw: '0',
    amountWithdrawHuman: '0',
    amountWithdrawInTokens: '0',
    numSharesToReceive: '0',

    userTokenBalance: '0',
    selectedBoostOption: 0,

    approvalLoading: false,
    stakeLoading: false,
    withdrawLoading: false
  }),

  watch: {
    amountWithdraw () {
      this.updateWithdrawAmountInTokens()
    },
    amountStake () {
      this.updateNumSharesToReceive()
    },
    sEthersWatcher () {
      this.refresh()
    },
    pool_info () {
      this.refresh()
    },
    user_info () {
      this.setAutomaticTimeBoostSelection()
      this.getAllowance()
      this.getUserTokenBalance()
    }
  },

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    timeBoostProgress () {
      var maxBoost = this.pool_info.boost_options.reduce((a, item) => {
        if (item.boost_percentage > a) {
          a = item.boost_percentage
        }
        return a
      }, 0)
      if (maxBoost === 0) {
        return 0
      }
      var percent = ethers.BigNumber.from(this.user_info.time_boost_percentage).mul(1000).div(maxBoost)
      return percent.toNumber() / 10
    },
    poolSharePercent () {
      if (this.pool_info.share_weight_total === '0') {
        return 0
      }
      var percent = ethers.BigNumber.from(this.user_info.share_weight).mul(10000).div(this.pool_info.share_weight_total)
      return percent.toNumber() / 100
      // return 0
    },
    UnclBoostProgress () {
      if (this.pool_info.uncl_boost_max_percentage === '0') {
        return 0
      }
      var percent = ethers.BigNumber.from(this.user_info.uncl_boost_percentage).mul(1000).div(this.pool_info.uncl_boost_max_percentage)
      return percent.toNumber() / 10
      // return 0
    },
    maxUint () {
      return ethers.constants.MaxUint256.toString()
    },
    maxStakeAmountIsMaxUint () {
      return this.maxUint === this.pool_info.max_stake_amount
    },
    stakeDisabled () {
      if (this.allowanceIncreaseRequired) {
        return true
      }
      return false
    },
    allowanceIncreaseRequired () {
      if (ethers.BigNumber.from(this.amountStake).gt(this.allowance)) {
        return true
      }
      return false
    },
    newBalanceStaking () {
      return ethers.BigNumber.from(this.amountStake).add(this.user_info.tokens_staking).toString()
    },
    eMinStakeAmount () {
      if (ethers.BigNumber.from(this.amountStake).eq(0)) {
        return false
      }
      if (ethers.BigNumber.from(this.amountStake).add(this.user_info.tokens_staking).lt(this.pool_info.min_stake_amount)) {
        return true
      }
      return false
    },
    eMaxStakeAmount () {
      if (ethers.BigNumber.from(this.amountStake).add(this.user_info.tokens_staking).gt(this.pool_info.max_stake_amount)) {
        return true
      }
      return false
    },
    cSelectedBoostOption () {
      var acceptedBoostTimePeriod = 0
      var acceptedBoostPercentage = 0
      if (this.selectedBoostOption !== 0) {
        var boost_option = this.pool_info.boost_options[this.selectedBoostOption - 1]
        acceptedBoostTimePeriod = boost_option.time_period
        acceptedBoostPercentage = boost_option.boost_percentage
      }
      return {
        time_period: acceptedBoostTimePeriod,
        boost_percentage: acceptedBoostPercentage
      }
    },
    selectedBoostOptionTooLow () {
      return this.cSelectedBoostOption.boost_percentage < this.user_info.time_boost_percentage
    },
    predictiveUnlockDateSecondsIncrement () {
      var total_seconds = this.pool_info.min_staking_period + this.cSelectedBoostOption.time_period
      return total_seconds
    },
    predictiveUnlockDateForNewStake () {
      var total_seconds = this.pool_info.min_staking_period + this.cSelectedBoostOption.time_period
      if (moment().add(total_seconds, 'seconds').isValid()) {
        return moment().add(total_seconds, 'seconds').format('D MMMM YYYY, HH:mm:ss')
      }
      return ''
    },
    predictiveUnlockDateForNewStakeFromNow () {
      var total_seconds = this.pool_info.min_staking_period + this.cSelectedBoostOption.time_period
      if (moment().add(total_seconds, 'seconds').isValid()) {
        return moment().add(total_seconds, 'seconds').fromNow()
      }
      return ''
    },
    remainingStakeAmount () {
      return ethers.BigNumber.from(this.pool_info.max_stake_amount).sub(this.user_info.tokens_staking).toString()
    },
    unbondingDateHuman () {
      var total_seconds = this.user_info.unlock_date + this.user_info.boost_additional_time
      if (moment.unix(total_seconds).isValid()) {
        return moment.unix(total_seconds).format('D MMMM YYYY, HH:mm')
      }
      return ''
    },
    unbondingDateFromNow () {
      var total_seconds = this.user_info.unlock_date + this.user_info.boost_additional_time
      if (moment.unix(total_seconds).isValid()) {
        return moment.unix(total_seconds).fromNow()
      }
      return ''
    },
    tokensWithdrawable () {
      if (this.pool_info.unlock_tokens) {
        return true
      }
      return this.tokenUnlockEpochHasPassed
    },
    tokenUnlockEpochHasPassed () {
      var total_seconds = this.user_info.unlock_date + this.user_info.boost_additional_time
      return total_seconds < moment().unix()
    },
    userTokenBalanceHuman () {
      var amount = ethers.utils.formatUnits(this.userTokenBalance, this.stake_token_hydrated.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8})
    },
    showStakeModFee () {
      if (this.pool_info.stake_mod_fee_allowed && this.pool_info.stake_mod_fee_amount !== '0') {
        return true
      }
      return false
    }
  },

  methods: {
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .then(() => {
        })
        .catch(e => {})
    },
    async refresh () {
      this.uncl_token_hydrated = await ERC20.getERC20(this.pool_info.uncl_token_address)
      this.getAllowance()
      this.getUserTokenBalance()
    },
    setAutomaticTimeBoostSelection () {
      var index = 0
      if (this.user_info.time_boost_percentage !== '0') {
        for (var option of this.pool_info.boost_options) {
          index++
          if (option.boost_percentage >= this.user_info.time_boost_percentage) {
            break
          }
        }
      }
      this.selectedBoostOption = index
    },
    async getAllowance () {
      var allowance = await ERC20BALANCE.getAllowance(this.stake_token_hydrated.address, this.sEthers.coinbase, this.address)
      this.allowance = allowance
    },
    async getUserTokenBalance () {
      this.userTokenBalance = await ERC20BALANCE.getBalance(this.sEthers.coinbase, this.stake_token_hydrated.address)
    },
    async updateWithdrawAmountInTokens () {
      this.amountWithdrawInTokens = await StakePoolContract.sharesToTokens(this.address, this.amountWithdraw)
    },
    async updateNumSharesToReceive () {
      var numShares = await StakePoolContract.tokensToShares(this.address, this.amountStake)
      if (numShares === '0') {
        this.numSharesToReceive = this.amountStake
        return
      }
      this.numSharesToReceive = numShares
    },
    async onWithdrawAmountChanged (_val) {
      try {
        this.amountWithdraw = ethers.utils.parseUnits(_val, this.stake_token_hydrated.decimals).toString()
      } catch (e) {
        this.amountWithdraw = '0'
      }
    },
    setPercent (percent) {
      this.amountStake = ethers.BigNumber.from(this.userTokenBalance).mul(percent).div(100).toString()
      this.amountStakeHuman = ethers.utils.formatUnits(this.amountStake, this.stake_token_hydrated.decimals)
    },
    setMax () {
      if (ethers.BigNumber.from(this.userTokenBalance).gt(this.remainingStakeAmount)) {
        this.amountStake = this.remainingStakeAmount
        this.amountStakeHuman = ethers.utils.formatUnits(this.amountStake, this.stake_token_hydrated.decimals)
        return 
      }
      this.amountStake = this.userTokenBalance
      this.amountStakeHuman = ethers.utils.formatUnits(this.amountStake, this.stake_token_hydrated.decimals)
    },
    setMaxWithdraw () {
      this.amountWithdraw = this.user_info.shares
      this.amountWithdrawHuman = ethers.utils.formatUnits(this.user_info.shares, this.stake_token_hydrated.decimals)
    },
    onStakeAmountChanged (_val) {
      try {
        this.amountStake = ethers.utils.parseUnits(_val, this.stake_token_hydrated.decimals).toString()
      } catch (e) {
        this.amountStake = '0'
      }
    },
    async stakeTokens () {
      this.stakeLoading = true
      var stakeModFeeAmount = '0'
      var stakeModFeeToken = ethers.constants.AddressZero
      if (this.pool_info.stake_mod_fee_allowed) {
        stakeModFeeAmount = this.pool_info.stake_mod_fee_amount
        stakeModFeeToken = this.pool_info.stake_mod_fee_token.address
      }
      TxWrapper.doTransaction(StakePoolContract.stakeTokens(this.address, this.sEthers.coinbase, this.amountStake, this.pool_info.min_staking_period, this.cSelectedBoostOption.time_period, this.cSelectedBoostOption.boost_percentage, stakeModFeeAmount, stakeModFeeToken), this.$root.$dialog.confirmTx, 1)
        .then(response => {
          this.refresh()
          this.$emit('hard-refresh')
          if (this.showStakeModFee) {
            this.$refs.smfStake.refresh()
          }
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          this.stakeLoading = false
        })
    },
    async withdrawTokens () {
      this.withdrawLoading = true
      TxWrapper.doTransaction(StakePoolContract.withdrawTokens(this.address, this.amountWithdraw, this.user_info.accepted_stake_fee_amount, this.user_info.accepted_stake_fee_token.address), this.$root.$dialog.confirmTx, 1)
        .then(response => {
          this.refresh()
          this.$emit('hard-refresh')
          if (this.showStakeModFee) {
            this.$refs.smfWithdraw.refresh()
          }
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          this.withdrawLoading = false
        })
    },
    approve () {
      var amount = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      this.approvalLoading = true
      TxWrapper.doTransaction(ERC20BALANCE.setAllowance(this.stake_token_hydrated.address, amount, this.address), this.$root.$dialog.confirmTx, 1)
        .then(() => { 
          this.getAllowance()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          this.approvalLoading = false
        })
    },
  },

  created () {
    this.refresh()
  }
}
</script>