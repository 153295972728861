<template>
  <div class="pa-4">

    <!-- NOT CONNECTED -->
    <div v-if="!sEthers.coinbase" class="pa-2">
      <v-btn large block rounded outlined color="textFaint" @click="connectWallet" style="border: 1.5px solid;">
        <v-icon size="24" color="">mdi-wallet</v-icon>
        <div>
          <div class="">
            Connect your wallet
          </div>
        </div>
      </v-btn>
    </div>
    <!-- NOT CONNECTED -->

    <template v-else>

      <!-- NETWORK SWITCH REQUIRED -->
      <div v-if="networkSwitchRequired" class="pa-2">
        
        <div class="text-center pb-1">
          Network change required
        </div>
        <div class="justify-center d-flex align-center pb-3 caption textFaint--text">
          {{ $store.state.requiredNetwork }} <v-icon small color="textFaint" class="px-1">mdi-chevron-right</v-icon>
          <span class="">
            {{ network }}
          </span>
        </div>
        <v-btn large block rounded outlined color="textFaint" @click="switchNetwork" style="border: 1.5px solid;">
          <div>
            <div class="">
              Switch Network
            </div>
          </div>
        </v-btn>
      </div>
      <!-- NETWORK SWITCH REQUIRED -->

      <template v-else>
        <div class="py-1 d-flex align-center">

          <v-spacer></v-spacer>
          Token Utils - {{ network }}
          <v-spacer></v-spacer>

          <v-btn icon @click="refresh">
            <v-icon small>mdi-refresh</v-icon>
          </v-btn>
        </div>

        <!--
        <div class="text-center">
          Balance: {{ $root.formatAmount(balance, tokenHydrated.decimals) }}
        </div>
        -->

        <div class="text-center">
          <div class="title primary--text">
            {{ $root.formatAmount(coinbase_balance, tokenHydrated.decimals) }} {{ tokenHydrated.symbol }}
          </div>
          <div class="caption textFaint--text font-italic">
            ${{ coinbaseValue }} in your wallet
          </div>
        </div>

        <div class="mt-2 d-flex justify-center align-center px-3 py-1 br-8">
          <template v-if="!getBalanceAddressIsValid">
            <v-icon small color="textFaint" class="mr-1">mdi-alert-outline</v-icon>
          </template>
          <template v-else>
            <v-icon small color="textFaint" class="mr-1">mdi-account-circle-outline</v-icon>
          </template>
          <div class="caption textFaint--text">
            {{ get_balance_address }}
          </div>
          <!--
          <v-btn small rounded color="primary" @click="getUserBalance">
            Get Balance
          </v-btn>
          -->
        </div>

        <div class="text-center mt-3 mb-2">
          Transfer
        </div>

        <div class="px-3 align-center flex-nowrap inputcolor br-8">
          
          <!--
          <div class="caption text-end pt-2">
            Balance: {{ $root.formatAmount(coinbase_balance, tokenHydrated.decimals) }}
          </div>
          -->

          <div class="d-flex align-center py-2">
            <c-input :value.sync="amountHuman" placeholder="0.0" @updateWhileFocussed="onTransferAmountChanged" class="font-weight-bold">

            </c-input>

            <div class="font-weight-bold text-uppercase">
              {{ tokenHydrated.symbol }}
            </div>

            <v-btn small depressed rounded color="primary" @click="setMax" class="ml-2">
              MAX
            </v-btn>
          </div>

        </div>

        <div class="text-center my-1 caption">
          to
        </div>

        <div class="px-3 br-8 inputcolor">
          <div class="d-flex align-center py-2">
            <template v-if="!transferToAddressIsValid">
              <v-icon small color="textFaint" class="mr-1">mdi-alert-outline</v-icon>
            </template>
            <template v-else>
              <v-icon small color="primary" class="mr-1">mdi-check-circle-outline</v-icon>
            </template>
            <c-input :value.sync="transfer_to_address" placeholder="User address.." class="caption">
            </c-input>
            <v-btn small rounded color="primary" @click="transfer">
              Send
            </v-btn>
          </div>
        </div>
        <div class="pl-3 caption textFaint--text">
          user balance: {{ $root.formatAmount(transfer_to_address_balance, tokenHydrated.decimals) }}
        </div>
      </template>
    </template>

  </div>
</template>

<script>
import { ethers } from 'ethers'
import ERC20 from '@/smart-contracts/erc20'
import TxWrapper from '@/smart-contracts/tx-wrapper'

export default {
  props: {
    price_usd: null,
    tokenHydrated: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
    network: null
  },

  data: () => ({
    get_balance_address: '',
    balance: '0',
    coinbase_balance: '0',
    transfer_to_address: '',
    transfer_to_address_balance: '0',

    // transfer
    amount: '0',
    amountHuman: '0',
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    transferToAddressIsValid () {
      try {
        ethers.utils.getAddress(this.transfer_to_address)
        return true
      } catch (e) {
        return false
      }
    },
    getBalanceAddressIsValid () {
      try {
        ethers.utils.getAddress(this.get_balance_address)
        return true
      } catch (e) {
        return false
      }
    },
    coinbaseValue () {
      // var value = this.price_usd * (this.coinbase_balance / Math.pow(10, this.tokenHydrated.decimals))
      var value = this.price_usd * Number(ethers.utils.formatUnits(this.coinbase_balance, this.tokenHydrated.decimals).toString())
      return Number(value).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
    },
    networkSwitchRequired () {
      return this.network !== this.$store.state.requiredNetwork
    }
  },

  watch: {
    transfer_to_address (nv) {
      this.getTransferToAddressBalance()
    },
    sEthersWatcher () {
      this.refresh()
    }
  },

  methods: {
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .then(() => {
          this.switchNetwork()
        })
        .catch(e => {})
    },
    switchNetwork () {
      if (this.$store.state.requiredNetwork === this.network) {
        // prevent switching networks if not necessary as it sets $store.state.exchange = null
        return
      }
      this.$store.commit('switchChain', this.network)
    },
    async getUserBalance () {
      var balance = await ERC20.getBalance(this.get_balance_address, this.tokenHydrated.address)
      this.balance = balance
    },
    async getTransferToAddressBalance () {
      if (!this.transferToAddressIsValid) {
        this.transfer_to_address_balance = '0'
        return
      }
      var balance = await ERC20.getBalance(this.transfer_to_address, this.tokenHydrated.address)
      this.transfer_to_address_balance = balance
    },
    async refresh () {
      var coinbase_balance = await ERC20.getBalance(this.sEthers.coinbase, this.tokenHydrated.address)
      this.coinbase_balance = coinbase_balance

      this.get_balance_address = this.sEthers.coinbase

      this.getTransferToAddressBalance()
    },
    setMax () {
      this.amount = this.coinbase_balance
      this.amountHuman = ethers.utils.formatUnits(this.coinbase_balance, this.tokenHydrated.decimals)
    },
    onTransferAmountChanged (_val) {
      try {
        this.amount = ethers.utils.parseUnits(_val, this.tokenHydrated.decimals).toString()
      } catch (e) {
        this.amount = '0'
      }
    },
    async transfer () {
      TxWrapper.doTransaction(ERC20.transfer(this.tokenHydrated.address, this.transfer_to_address, this.amount), this.$root.$dialog.confirmTx, 1)
        .then(response => {
          this.refresh()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
        })
    },
  },

  created () {
    this.getUserBalance()
    this.refresh()
  }
}

</script>
