var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-slide-y-transition',{attrs:{"appear":""}},[_c('div',[_c('div',{staticClass:"justify-center mx-auto",staticStyle:{"max-width":"580px"}},[_c('div',{staticClass:"text-center"},[_c('div',{class:['foreground', {'mt-1': !_vm.$vuetify.breakpoint.xs}]},[_c('div',{staticClass:"pa-2 border c-list",on:{"click":function($event){return _vm.$router.push('/amm')}}},[(false)?_c('div',{staticClass:"caption textFaint--text font-italic"},[_vm._v(" Selected exchange ")]):_vm._e(),_c('div',{staticClass:"font-weight-medium title text-uppercase py-1 textFaint--text d-flex align-center justify-center"},[_c('img',{staticClass:"mr-3 br-20",attrs:{"src":_vm.$settings.AMMS[this.$store.state.exchange].icon,"height":"24px","width":"24px"}}),_vm._v(" "+_vm._s(_vm.$store.state.exchange)+" "),_c('v-icon',{attrs:{"small":"","color":"textFaint"}},[_vm._v("mdi-chevron-down")])],1)])])]),(_vm.$store.state.exchange === 'Pancakeswap V1' || _vm.$store.state.exchange === 'Pancakeswap V2222')?_c('div',{staticClass:"d-flex align-center justify-center font-weight-medium px-4 py-2",staticStyle:{"position":"relative","overflow":"hidden"}},[_c('div',{staticClass:"orange",staticStyle:{"position":"absolute","left":"0px","top":"0px","bottom":"0px","right":"0px","opacity":"0.2"}}),_c('div',{staticClass:"text-center caption"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"text"}},[_vm._v("mdi-alert-outline")]),_vm._v(" The Pancakeswap explorer is out of sync ")],1),(false)?_c('div',{staticClass:"caption"},[_vm._v(" Data such as prices will be inaccurate. ")]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"d-flex border-b foreground border",staticStyle:{"overflow":"hidden"}},[_c('router-link',{attrs:{"to":((_vm.$store.state.ammPrefix) + "/tokens")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [_c('div',{class:['top-tab', {'active': isActive}],on:{"click":navigate}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20"}},[_vm._v("mdi-checkbox-blank-circle-outline")]),_c('span',[_vm._v("TOKENS")])],1)]}}])}),_c('router-link',{attrs:{"to":((_vm.$store.state.ammPrefix) + "/pairs")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [_c('div',{class:['top-tab', {'active': isActive}],on:{"click":navigate}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20"}},[_vm._v("mdi-checkbox-multiple-blank-circle-outline")]),_c('span',[_vm._v("PAIRS")])],1)]}}])}),_c('router-link',{attrs:{"to":((_vm.$store.state.ammPrefix) + "/ilos")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [_c('div',{class:['top-tab', {'active': isActive}],on:{"click":navigate}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20"}},[_vm._v("mdi-rocket")]),_c('span',[_vm._v("LAUNCHPAD")])],1)]}}])}),(_vm.$store.state.superUserMode)?_c('router-link',{attrs:{"to":((_vm.$store.state.ammPrefix) + "/sajfkhflkjasdlisdulansdlakds")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [_c('div',{class:['top-tab', {'active': isActive}],on:{"click":navigate}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20"}},[_vm._v("mdi-atom")]),_c('span',[_vm._v("ChavPanel")])],1)]}}],null,false,1235416401)}):_vm._e()],1)]),_c('keep-alive',{attrs:{"include":"CronTab,TokensPage,PairsComponent,Presales"}},[_c('router-view',{key:_vm.$route.fullPath})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }