<template>
  <div class="">
    <!--
    <div class="px-4 textFaint--text font-weight-bold pt-4">
      Earn
    </div>
    <div class="px-4 d-flex align-center display-1 text-uppercase font-weight-bold mb-6">
      REWARDS
      <v-spacer></v-spacer>
      <v-btn icon @click="refresh()">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-btn text @click="tab = 1" color="primary">
        Create
      </v-btn>
    </div>
    -->

    <div class="px-4 py-2 d-flex align-center">
      <div>
        <span v-if="loading" class="title textFaint--text">
          Rewards loading...
        </span>
        <span v-else class="title textFaint--text">
          REWARDS
        </span>
      </div>
      <v-spacer></v-spacer>
      <v-fade-transition appear mode="out-in">
        <v-progress-circular
        v-if="loading"
        indeterminate
        size="36"
        width="2"
        color="textFaint">
          <img 
          src="@/assets/img/UNCX_fill.svg" 
          height="120px"
          class="greyscale"
          width="28px">
        </v-progress-circular>
        <v-btn v-else @click="refresh" icon color="textFaint">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-fade-transition>
    </div>

    <div v-if="num_reward_pools === '0'" class="v-card pa-4 br-20">
      <span class="textFaint--text font-weight-bold">
        This stake pool has no rewards
      </span>
    </div>

    <!-- AVAILABLE POOLS -->
    <div v-if="unsubscribedList.length > 0" class="br-20 mb-8">
      <div class="v-card br-20 midground">
        <div class="py-4 px-4 d-flex align-center font-weight-medium textFaint--text foreground">
          <v-icon v-if="false" size="38" color="text" class="">mdi-alert-decagram-outline</v-icon>
          <div class="flex pl-2">
            <div class="textFaint--text font-weight-medium">
              NEW REWARD STREAMS
            </div>
            <div class="caption font-weight-medium">
              Subscribe to earn
            </div>
          </div>
          <v-btn v-if="unsubscribedList.length > 1" @click="subscribe" :loading="subscribeAllLoading" small depressed rounded color="textFaint" class="lgrad-green white--text">
            Subscribe all
          </v-btn>
        </div>
        <div v-if="false" class="gradient-text text-center midground br-8 ma-2 font-weight-bold pa-2">
          Subscribe to these pools to earn
        </div>

        <div class="">
          <reward-pool 
          v-for="reward_pool of unsubscribedList" 
          :key="reward_pool.pool_address" 
          :reward_info="reward_pool" 
          :spool_user_info="user_info"
          :pool_info="pool_info"
          :reward_meta="reward_meta[reward_pool.pool_address]"
          class="mt-1"
          @on-sub-event="$emit('on-sub-event')">
          </reward-pool>
        </div>
      </div>
    </div>
    <!-- AVAILABLE POOLS -->

    <div v-if="subscribedList.length > 0" class="v-card br-20 my-2 midground">
      <div class="py-6 px-4 d-flex align-center textFaint--text foreground">
        <v-icon v-if="false" size="38" color="textFaint">mdi-sprout-outline</v-icon>
        <div class="flex pl-2 font-weight-medium">
          <div>
            YOUR FARMING
          </div>
        </div>
        <div class="textFaint--text">
          {{ subscribedList.length }}/{{ pool_info.max_reward_subscriptions }} pools
        </div>
      </div>
      <reward-pool 
      v-for="reward_pool of subscribedList" 
      :key="reward_pool.pool_address" 
      :reward_info="reward_pool" 
      :spool_user_info="user_info"
      :pool_info="pool_info"
      :reward_meta="reward_meta[reward_pool.pool_address]"
      class="mt-1"
      @on-sub-event="$emit('on-sub-event')">
      </reward-pool>
    </div>

    <!-- YIELD LIST -->
    <div v-if="yieldList.length > 0" class="v-card br-20 my-2 midground">
      <div class="py-6 px-4 d-flex align-center textFaint--text foreground">
        <v-icon v-if="false" size="38" color="textFaint">mdi-arrow-up</v-icon>
        <div class="flex pl-2">
          <div class="font-weight-medium">
            Harvest
          </div>
          <div class="caption font-weight-medium">
            You have rewards to harvest
          </div>
        </div>
        <v-btn @click="harvestAll" depressed small rounded color="text" class="midground">
          Harvest all
        </v-btn>
      </div>
      <reward-pool 
      v-for="reward_pool of yieldList" 
      :key="reward_pool.pool_address" 
      :reward_info="reward_pool" 
      :spool_user_info="user_info"
      :pool_info="pool_info"
      :reward_meta="reward_meta[reward_pool.pool_address]"
      class="mt-1"
      @on-sub-event="$emit('on-sub-event')">
      </reward-pool>
    </div>
    <!-- YIELD LIST -->

    <!-- ENDED POOLS -->
    <div v-if="endedList.length > 0" class="v-card br-20 my-2 midground">
      <div class="py-3 px-4 d-flex align-center textFaint--text foreground c-list" @click="expandFinished = !expandFinished">
        <v-icon v-if="false" size="38" color="textFaint">mdi-clock-outline</v-icon>
        <div class="flex pl-2 font-weight-medium">
          <div>
            FINISHED POOLS
          </div>
        </div>
        <div class="textFaint--text">
          ({{ endedList.length }})
        </div>
        <v-icon color="textFaint" :class="[{'rotate-180': expandFinished}]">mdi-chevron-down</v-icon>
      </div>

      <v-expand-transition>
        <div v-if="expandFinished" class="midground">
          <reward-pool 
          v-for="reward_pool of endedList" 
          :key="reward_pool.pool_address" 
          :reward_info="reward_pool" 
          :spool_user_info="user_info"
          :pool_info="pool_info"
          class="mt-1"
          @on-sub-event="$emit('on-sub-event')">
          </reward-pool>
        </div>
      </v-expand-transition>
    </div>
    <!-- ENDED POOLS -->
    <!--
    <div v-else class="pa-4">
      <div class="v-card br-20 pa-6 text-center">
        <div class="title">
          No reward pools
        </div>
        <div class="textFaint--text">
          There are no rewards for this stake pool yet.
        </div>
      </div>
    </div>
    -->

  </div>
</template>

<script>
import StakePoolPaginatorContract from '@/smart-contracts/farms-v2/normal/stake-pool/v1/stake-pool-paginator'
import TxWrapper from '@/smart-contracts/tx-wrapper'
import StakePoolContract from '@/smart-contracts/farms-v2/normal/stake-pool/v1/stake-pool'
import RewardPool from './v1/reward-pool'
import { ethers } from 'ethers'

export default {

  props: {
    stake_pool_address: null,
    user_info: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
    pool_info: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
    reward_meta: {}
  },

  components: {
    RewardPool
  },

  data: () => ({
    num_reward_pools: 0,
    reward_pools: [],

    loading: false,
    subscribeAllLoading: false,
    expandFinished: false
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    wrongNetwork () {
      return this.$store.state.wrongNetwork
    },
    yieldList () {
      return this.reward_pools.filter(item => {
        return item.unharvested !== '0'
      })
    },
    subscribedList () {
      return this.reward_pools.filter(item => {
        return item.user_share_weight !== '0'
      })
    },
    unsubscribedList () {
      return this.reward_pools.filter(item => {
        return item.user_share_weight === '0' && item.farm_state !== 2
      })
    },
    endedList () {
      return this.reward_pools.filter(item => {
        return item.user_share_weight === '0' && item.farm_state === 2
      })
    }
  },

  watch: {
    sEthersWatcher () {
      this.refresh()
    },
    wrongNetwork (val) {
      this.refresh()
    }
  },

  methods: {
    async refresh () {
      this.loading = true
      // this.reward_pools = []
      try {
        var userAddress = ethers.utils.isAddress(this.sEthers.coinbase) ? this.sEthers.coinbase : ethers.constants.AddressZero
        this.num_reward_pools = await StakePoolPaginatorContract.getNumRewardPools(this.stake_pool_address)
        this.reward_pools = await StakePoolPaginatorContract.getRewardPools(this.stake_pool_address, userAddress, 0, this.num_reward_pools)
      } catch (e) {}
      this.loading = false
    },
    async harvestAll () {
      var list = this.yieldList.map(item => item.pool_address)
      console.log(list)
      TxWrapper.doTransaction(StakePoolContract.harvestMultiple(this.stake_pool_address, list), this.$root.$dialog.confirmTx, 1)
        .then(response => {
          // this.refresh()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
        })
    },
    async subscribe () {
      this.subscribeAllLoading = true
      var list = this.unsubscribedList.map(item => item.pool_address)
      console.log(list)
      TxWrapper.doTransaction(StakePoolContract.subscribe(this.stake_pool_address, list), this.$root.$dialog.confirmTx, 1)
        .then(response => {
          this.refresh()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          this.subscribeAllLoading = false
        })
    },
  },

  created () {
    this.refresh()
  }
}
</script>