<template>

  <div class="" style="overflow: hidden;">

    <div>
      <div class="pa-2">

        <div class="pt-3 px-3 textFaint--text">
          Boost your stake weight for this pool by burning {{ uncl_token_hydrated.symbol }}.
        </div>
        <div class="px-3 pb-3 textFaint--text caption">
          *Burnt {{ uncl_token_hydrated.symbol }} is not refundable and only applies to this pool.
        </div>

        <div class="pa-4 align-center flex-nowrap c-input-wrapper br-8">

          <div class="caption text-end pt-2">
            Balance: {{ userUNCLBalanceHuman }}
          </div>

          <div class="d-flex align-center">
            <c-input :value.sync="amountBoostUNCLHuman" placeholder="0.0" @updateWhileFocussed="onUNCLAmountChanged" class="pa-2 font-weight-bold">

            </c-input>

            <v-btn color="foreground" class="px-2 py-5" rounded @click.native="$root.tokenPage.open(uncl_token_hydrated.address, $store.state.requiredNetwork)" style="min-width: 10px;max-height: unset;">
              <coin-icon :address="uncl_token_hydrated.address" :size="24" class="mr-1"></coin-icon>
              <span :class="['text-truncate text-uppercase']" :style="$vuetify.breakpoint.xs ? 'max-width: 14ch;' : 'max-width: 20ch;'">
                {{ uncl_token_hydrated.symbol }}
              </span>
              <v-icon small>mdi-chevron-down</v-icon>
            </v-btn>

            <v-btn rounded small depressed color="lgrad-blue" @click="setMaxUNCL" class="ml-1 white--text">
              MAX
            </v-btn>
          </div>

        </div>

        <div class="mx-auto" style="max-width: 400px;">
          <div class="d-flex align-center mt-3 caption textFaint--text px-2">
            <v-spacer></v-spacer>
            <div>
              Max: {{ pool_info.uncl_boost_max_percentage / 100 }}%
            </div>
          </div>
          <div style="position: relative;height: 30px;">
            <v-progress-linear v-if="true" height="30" :value="boostProgress" 
            color="lgrad-blue"
            class="br-c text--text"
            background-color="background"
            style="position: absolute;opacity: 0.5;">
              <div v-if="false">
                {{ predictiveBoostProgress / 100 }}% BOOST
              </div>
            </v-progress-linear>
            <v-progress-linear v-if="true" height="30" :value="currentBoostProgress" 
            color="lgrad-blue"
            class="br-c text--text"
            background-color="transparent"
            style="position: absolute;z-index: 1;">
              <div class="font-weight-bold">
                {{ predictiveBoostProgress / 100 }}% BOOST
              </div>
            </v-progress-linear>
          </div>
        </div>

        <div class="text-center caption textFaint--text pt-1">
          Burn 
          <strong>{{ $root.formatAmount(actualBurnAmount, uncl_token_hydrated.decimals) }}</strong> {{ uncl_token_hydrated.symbol }} for an additional 
          <strong>{{ predictiveBoostAddition / 100 }}%</strong> boost
        </div>

        <v-row dense class="ma-0 mt-4 br-c" style="overflow: hidden;">
          <v-col cols="6" class="pa-0">
            <v-btn class="br-0 white--text" @click="approveUNCL" color="lgrad-blue" x-large block depressed :disabled="!allowanceIncreaseRequiredUNCL" :loading="approveUNCLLoading">
              Approve
            </v-btn>
          </v-col>
          <v-col cols="6" class="pa-0">
            <v-btn class="br-0 white--text" @click="boostUNCL" color="lgrad-blue" x-large block depressed :disabled="boostDisabled" :loading="boostLoading">
              Boost
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>

  </div>

</template>

<script>
import { ethers } from 'ethers'
import StakePoolContract from '@/smart-contracts/farms-v2/normal/stake-pool/v1/stake-pool'
import ERC20BALANCE from '@/smart-contracts/erc20'
import TxWrapper from '@/smart-contracts/tx-wrapper'

export default {

  props: {
    address: null,
    uncl_token_hydrated: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
    pool_info: {
      type: Object,
      default: () => {
        return {
          uncl_boost_max_percentage: '0'
        }
      }
    },
    user_info: {
      type: Object,
      default: () => {
        return {
          uncl_boost_percentage: '0'
        }
      }
    },
  },

  components: {
  },

  data: () => ({
    uncl_allowance: '0',
    amountBoostUNCL: '0',
    amountBoostUNCLHuman: '0',
    approveUNCLLoading: false,
    boostLoading: false,
    userUNCLBalance: '0',
    expandPanel: true,
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    currentBoostProgress () {
      if (this.pool_info.uncl_boost_max_percentage === '0') {
        return 0
      }
      var percent = ethers.BigNumber.from(this.user_info.uncl_boost_percentage).mul(10000).div(this.pool_info.uncl_boost_max_percentage)
      return percent.toNumber() / 100
      // return 0
    },
    boostProgress () {
      if (this.pool_info.uncl_boost_max_percentage === '0') {
        return 0
      }
      var percent = ethers.BigNumber.from(this.predictiveBoostProgress).mul(10000).div(this.pool_info.uncl_boost_max_percentage)
      return percent.toNumber() / 100
      // return 0
    },
    predictiveBoostAddition () {
      if (ethers.BigNumber.from(this.remainingUNCLToBoost).eq(0)) {
        return 0
      }
      var remaining_percentage = ethers.BigNumber.from(this.pool_info.uncl_boost_max_percentage).sub(this.user_info.uncl_boost_percentage)
      var amountToBurn = ethers.BigNumber.from(this.amountBoostUNCL).gt(this.remainingUNCLToBoost) ? this.remainingUNCLToBoost : this.amountBoostUNCL
      var percent = ethers.BigNumber.from(remaining_percentage).mul(amountToBurn).div(this.remainingUNCLToBoost)
      return percent.toNumber()
    },
    predictiveBoostProgress () {
      var percent = ethers.BigNumber.from(this.user_info.uncl_boost_percentage).add(this.predictiveBoostAddition)
      return percent.toNumber()
    },
    remainingUNCLToBoost () {
      if (this.pool_info.uncl_boost_max_percentage === '0') {
        return 0
      }
      if (ethers.BigNumber.from(this.user_info.uncl_boost_percentage).gte(this.pool_info.uncl_boost_max_percentage)) {
        return 0
      }
      return ethers.BigNumber.from(this.pool_info.uncl_boost_max_amount).sub(ethers.BigNumber.from(this.pool_info.uncl_boost_max_amount).mul(this.user_info.uncl_boost_percentage).div(this.pool_info.uncl_boost_max_percentage)).toString()
    },
    actualBurnAmount () {
      return ethers.BigNumber.from(this.amountBoostUNCL).gt(this.remainingUNCLToBoost) ? this.remainingUNCLToBoost : this.amountBoostUNCL
    },
    userUNCLBalanceHuman () {
      var amount = ethers.utils.formatUnits(this.userUNCLBalance, this.uncl_token_hydrated.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8})
    },
    allowanceIncreaseRequiredUNCL () {
      if (ethers.BigNumber.from(this.amountBoostUNCL).gt(this.uncl_allowance)) {
        return true
      }
      return false
    },
    boostDisabled () {
      if (this.allowanceIncreaseRequiredUNCL) {
        return true
      }
      return false
    },
  },

  watch: {
    sEthersWatcher () {
      this.refresh()
    },
    'uncl_token_hydrated.address' () {
      this.refresh()
    }
  },

  methods: {
    async refresh () {
      this.getUserUNCLBalance()
      this.getAllowance()
    },
    async getAllowance () {
      var allowance = await ERC20BALANCE.getAllowance(this.uncl_token_hydrated.address, this.sEthers.coinbase, this.address)
      this.uncl_allowance = allowance
    },
    setMaxUNCL () {
      if (ethers.BigNumber.from(this.userUNCLBalance).gt(this.remainingUNCLToBoost)) {
        this.amountBoostUNCL = this.remainingUNCLToBoost
        this.amountBoostUNCLHuman = ethers.utils.formatUnits(this.amountBoostUNCL, this.uncl_token_hydrated.decimals)
        return 
      }
      this.amountBoostUNCL = this.userUNCLBalance
      this.amountBoostUNCLHuman = ethers.utils.formatUnits(this.amountBoostUNCL, this.uncl_token_hydrated.decimals)
    },
    onUNCLAmountChanged (_val) {
      try {
        this.amountBoostUNCL = ethers.utils.parseUnits(_val, this.uncl_token_hydrated.decimals).toString()
      } catch (e) {
        this.amountBoostUNCL = '0'
      }
    },
    async getUserUNCLBalance () {
      this.userUNCLBalance = await ERC20BALANCE.getBalance(this.sEthers.coinbase, this.uncl_token_hydrated.address)
    },
    async boostUNCL () {
      TxWrapper.doTransaction(StakePoolContract.boostWeightWithUNCL(this.address, this.amountBoostUNCL), this.$root.$dialog.confirmTx, 1)
        .then(response => {
          this.refresh()
          this.$emit('hard-refresh')
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
        })
    },
    approveUNCL () {
      var amount = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      this.approveUNCLLoading = true
      TxWrapper.doTransaction(ERC20BALANCE.setAllowance(this.uncl_token_hydrated.address, amount, this.address), this.$root.$dialog.confirmTx, 1)
        .then(() => { 
          this.getAllowance()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          this.approveUNCLLoading = false
        })
    },
  },

  created () {
    this.refresh()
  }
}
</script>