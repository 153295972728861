<template>
  <div>

    <div v-if="$store.state.superUserMode && $store.state.user.jwtObject.user_id === '21'" class="primary--text">
      {{ presale_info.round0_offset - blockNumber }} blocks to go | {{ presale_info.round0_offset }}: BN - round 0
    </div>

    <v-card v-if="roundZeroOffset - blockNumber > 0" outlined class="blue white--text text-center br-8 pa-4">
      <div class="title">
        UNCL reservations start {{ timeToStartBlock }}
      </div>
      <div class="d-flex">
        <div class="">
          {{ roundZeroOffset - blockNumber }} block{{ roundZeroOffset - blockNumber === 1 ? '' : 's'}} to go
        </div>
        <v-spacer></v-spacer>
        <div class="">
          {{ dateStartBlockHuman }}
        </div>
      </div>
    </v-card>

    <div v-else class="text-center pb-2">
      <v-btn large block :text="expandMenu" rounded @click="expandMenu = !expandMenu" :color="expandMenu ? 'textFaint' : 'blue'" class="white--text">
        Reserve your spot with 
        {{ $root.formatAmount(uncl_info.uncl_amount, 18) }} UNCL
        <img 
        src="@/assets/img/UNCL.svg"
        height="18px"
        width="18px"
        class="mr-2 ml-2">
        <v-icon :class="[{'rotate-180': expandMenu}]">mdi-chevron-down</v-icon>
      </v-btn>
    </div>

    <v-expand-transition>
      <div v-show="expandMenu" class="v-card br-8 pa-0 text-center">
        <div class="pa-3">
          <!-- PROGRESS BAR -->
          <div class="px-2 mt-4">

            <div v-if="false" class="d-flex align-center justify-center text--text mt-1 mb-1">
              <div class="textFaint--text font-weight-bold">
                {{ presale_info.uncl_participants }}
              </div>
              <div class="px-1 textFaint--text">
                /
              </div>
              <div class="textFaint--text d-flex font-weight-regular align-center">
                {{ presale_info.uncl_max_participants }}
              </div>
            </div>

            <v-progress-linear rounded striped height="20" :value="unclProgress" 
            color="blue" background-color="background">
              <div class="caption text--text font-weight-bold">
                <!-- {{ unclProgress }}% reserved -->
                {{ presale_info.uncl_participants }} / {{ presale_info.uncl_max_participants }}
              </div>
            </v-progress-linear>

          </div>
          <!-- PROGRESS BAR -->

          <div class="font-weight-bold blue--text">
            {{ spotsLeft }} spots left!
          </div>
          
          <div v-if="!sEthers.coinbase">

            <v-btn @click="connectWallet" color="blue"
            large block rounded class="title white--text">
              <v-icon class="mr-3">mdi-wallet</v-icon>
              Connect your wallet
            </v-btn>
          </div>

          <template v-else>
            
            <!-- NOT YET RESERVED -->
            <div v-if="!userHasReservedASpot && spotsLeft > 0">

              <div class="mt-6 title d-flex align-center text--text font-weight-bold justify-center">
                Spend 
                <img 
                src="@/assets/img/UNCL.svg"
                height="25px"
                width="25px"
                class="mr-2 ml-2">

                {{ $root.formatAmount(uncl_info.uncl_amount, 18) }} UNCL
              </div>

              <div>
                for a guaranteed
                <span class="font-weight-bold">{{ $root.formatAmount(presale_info.max_spend_per_buyer, presale_info.base_token.decimals) }} {{ baseTokenSymbol }}</span>
                allocation in this ilo before round 1 starts
              </div>

              <div class="textFaint--text mt-2 caption">
                Your balance: {{ $root.formatAmount(unclBalance, 18) }} UNCL
              </div>

              <div class="textFaint--text mt-2 caption">
                Attention: You still need to participate with {{ baseTokenSymbol }} and need to participate <strong>before</strong> this ilo starts in order to get a guaranteed allocation.
                Ensure there is enough time before round 1 to participate (at least 10 mins before round 1 begins)
              </div>

              <div v-if="unclBalance === '0'" class="pt-2">
                You have 0 UNCL!
              </div>

              <!--
              <v-btn v-if="!antiBotKey.success || true" @click="getAntiBotKey" color="blue" x-large block depressed :disabled="false" :loading="approvalLoading">
                Im not a bot
              </v-btn>
              -->

              <div v-if="false">
                {{ antiBotKey }}
              </div>

              <vue-hcaptcha
              v-show="!antiBotKey.success"
              ref="hcaptcha"
              sitekey="3a0c424a-39b2-4fa4-b19d-7f329d712b72"
              @verify="onVerify"
              @expired="onExpire"
              @error="onError"></vue-hcaptcha>

              <div v-if="antiBotError" class="red--text">
                *hCaptcha failed, Please retry the hCaptcha
              </div>

              <div v-if="antiBotLoading" class="text-center pa-4">
                <v-progress-circular
                indeterminate
                size="50"
                width="2"
                color="textFaint">
                  <v-icon size="32" color="textFaint">mdi-robot</v-icon>
                </v-progress-circular>
                <div class="textFaint--text caption font-italic">
                  Anti-Bot Key Loading...
                </div>
              </div>

              <v-row v-if="antiBotKey.success" dense class="ma-0 mt-4">
                <v-col cols="6">
                  <v-btn @click="approve" color="blue" x-large block depressed :disabled="!allowanceIncreaseRequired || unclBalance === '0'" :loading="approvalLoading">
                    Approve
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn @click="reserveSpot" color="blue" x-large block depressed :disabled="allowanceIncreaseRequired" :loading="purchaseLoading">
                    Reserve
                  </v-btn>
                </v-col>
              </v-row>

            </div>
            <!-- NOT YET RESERVED -->

            <div v-if="userHasReservedASpot">
              <div class="mt-4">
                You must spend your allocation <strong>before</strong> round 1 begins in order
                to get a guaranteed allocation.
              </div>

              <v-card v-if="userInfo.tokens_owed !== '0'" outlined class="d-flex background textFaint--text align-center pa-4 mt-4 br-20">
                <coin-icon :url="presale_info.icon_url" :size="30"></coin-icon>
                <div class="ml-3">
                  <div>
                    You've bought
                  </div>
                  <div class="font-weight-bold">
                    {{ $root.formatAmount(userInfo.tokens_owed, presale_info.token.decimals) }} {{ presale_info.token.symbol }}
                  </div>
                  <div class="caption textFaint--text">
                    You can withdraw your {{ presale_info.token.symbol }} once the presale has concluded.
                  </div>
                </div>
              </v-card>

              <v-card class="pa-4 mt-4 br-20">
                <user-spend-eth :presaleInfo="presale_info" :round="0"></user-spend-eth>
              </v-card>
            </div>

          </template>
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import moment from 'moment'
import { ethers } from 'ethers'
import ERC20 from '@/smart-contracts/erc20'
import PresaleContract from '@/smart-contracts/presales/v4/presale-contract'
import TxWrapper from '@/smart-contracts/tx-wrapper'
import UserSpendEth from './user-spend-eth'
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

export default {
  props: {
    presale_info: {
      type: Object,
      default: () => {

      }
    }
  },

  components: {
    UserSpendEth,
    VueHcaptcha 
  },

  data: () => ({
    expandMenu: false,
    allowance: '0',
    unclBalance: '0',
    uncl_info: {
      uncl_address: '',
      uncl_amount: 0
    },
    approvalLoading: false,
    purchaseLoading: false,
    antiBotLoading: false,
    userInfo: {
      uncl_owed: 0
    },
    firstLoad: true,
    antiBotError: false,
    antiBotKey: {
      success: false,
      key: null,
      wait: 0
    },
    hcaptcha: {
      verified: false,
      expired: false,
      token: null,
      eKey: null,
      error: null
    }
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    roundZeroOffset () {
      return Number(this.presale_info.round0_offset) + this.$settings.ANTI_BOT_WAIT[this.$store.state.requiredNetwork]
    },
    spotsLeft () {
      return this.presale_info.uncl_max_participants - this.presale_info.uncl_participants
    },
    blockNumber () {
      return this.$store.state.blockNumber
    },
    allowanceIncreaseRequired () {
      if (ethers.BigNumber.from(this.uncl_info.uncl_amount).gt(this.allowance)) {
        return true
      }
      return false
    },
    userHasReservedASpot () {
      return ethers.BigNumber.from(this.userInfo.uncl_owed).gt(0)
    },
    unclProgress () {
      var max_participants = this.presale_info.uncl_max_participants === '0' ? 1 : this.presale_info.uncl_max_participants
      var percent = ethers.BigNumber.from(this.presale_info.uncl_participants).mul(1000).div(max_participants)
      return percent.toNumber() / 10
    },
    baseTokenSymbol () {
      return this.presale_info.presale_in_eth ? this.$store.state.nativeGasTokenSymbol : this.presale_info.base_token.symbol.toUpperCase()
    },
    dateStartBlock () {
      var numBlocks = this.roundZeroOffset - this.blockNumber
      var seconds = numBlocks * this.$store.state.blocksPerSecond
      var date = moment().add(seconds, 'seconds')
      return date
    },
    dateStartBlockHuman () {
      return this.dateStartBlock.format('ddd D MMM HH:mm')
    },
    timeToStartBlock () {
      return this.dateStartBlock.fromNow()
    },
  },

  watch: {
    sEthersWatcher () {
      this.refresh()
    },
    blockNumber () {
      this.refresh()
    }
  },

  methods: {
    async getAntiBotKey () {
      this.antiBotError = false
      this.antiBotLoading = true
      this.$axios.post(`/anti-bot/v4/get-uncl-key`, {
        presale_contract: this.presale_info.presale_contract,
        user: this.sEthers.coinbase,
        contract_version: 4,
        hcaptcha_token: this.hcaptcha.token
      })
        .then(response => {
          this.antiBotKey = response.data
          if (!this.antiBotKey.success) {
            this.onAntiBotFailResetCaptcha()
          }
        })
        .catch(() => {
          this.onAntiBotFailResetCaptcha()
        })
        .then(() => {
          this.antiBotLoading = false
        })
    },
    onAntiBotFailResetCaptcha () {
      this.antiBotError = true
      this.hcaptcha.verified = false;
      this.hcaptcha.token = null;
      this.hcaptcha.eKey = null;
      this.hcaptcha.expired = true;
      this.$refs.hcaptcha.reset();
    },
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .then(() => {
        })
        .catch(e => {})
    },
    approve () {
      var amount = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      this.approvalLoading = true
      TxWrapper.doTransaction(ERC20.setAllowance(this.uncl_info.uncl_address, amount, this.presale_info.presale_contract), this.$root.$dialog.confirmTx)
        .then(() => { 
          this.getAllowance()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          this.approvalLoading = false
        })
    },
    reserveSpot () {
      this.purchaseLoading = true
      TxWrapper.doTransaction(PresaleContract.reserveAllocationWithUNCL(this.presale_info.presale_contract, this.antiBotKey.key), this.$root.$dialog.confirmTx)
        .then(() => { 
          this.getAllowance()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          this.purchaseLoading = false
        })
    },
    async getAllowance () {
      if (!this.uncl_info.uncl_address || !this.sEthers.coinbase) {
        return
      }
      this.allowance = await ERC20.getAllowance(this.uncl_info.uncl_address, this.sEthers.coinbase, this.presale_info.presale_contract)
    },
    async getUNCLBalance () {
      if (!this.sEthers.coinbase) {
        return
      }
      this.unclBalance = await ERC20.getBalance(this.sEthers.coinbase, this.uncl_info.uncl_address)
    },
    async getUserInfo () {
      if (!this.sEthers.coinbase) {
        return
      }
      var userInfo = await PresaleContract.userInfo(this.presale_info.presale_contract, this.sEthers.coinbase)
      this.userInfo = userInfo
    },
    async refresh () {
      if (this.firstLoad) {
        return
      }
      this.getAllowance()
      this.getUNCLBalance()
      this.getUserInfo()
    },
    async getUNCLInfo () {
      this.uncl_info = await PresaleContract.getUNCLOverride(this.presale_info.presale_contract)
      this.firstLoad = false
      this.refresh()
    },
    onVerify (token, ekey) {
      this.hcaptcha.verified = true;
      this.hcaptcha.token = token;
      this.hcaptcha.eKey = ekey;
      this.getAntiBotKey()
      // console.log(`Callback token: ${token}, ekey: ${ekey}`);
    },
    onExpire () {
      this.hcaptcha.verified = false;
      this.hcaptcha.token = null;
      this.hcaptcha.eKey = null;
      this.hcaptcha.expired = true;
      console.log('Expired');
    },
    onError (err) {
      this.hcaptcha.token = null;
      this.hcaptcha.eKey = null;
      this.hcaptcha.error = err;
      console.log(`Error: ${err}`);
    },
  },

  created () {
    this.getUNCLInfo()
  }

}
</script>