<template>

  <div class="pa-4">
    <div style="max-width: 600px;" class="mx-auto">
      <div class="text-center pb-4">
        Admin functions
      </div>

      <div class="py-2 textFaint--text">
        These functions are intended for the pool owner, anyone can use Top up and Increase block reward
        however they will be giving tokens to all the farmers in the pool.
      </div>

      <top-up :reward_pool="reward_pool" :address="address"></top-up>

      <increase-block-reward :reward_pool="reward_pool" :address="address" class="mt-10"></increase-block-reward>

      <drain-token :reward_pool="reward_pool" :address="address" class="mt-10"></drain-token>

      <unicrypt-admin v-if="$store.state.adminIds.includes($store.state.user.jwtObject.user_id)" :reward_pool="reward_pool" :address="address" class="mt-10" @hard-refresh="$emit('hard-refresh')"></unicrypt-admin>
    </div>
  </div>

</template>

<script>
import TopUp from './admin/top-up'
import DrainToken from './admin/drain-token'
import IncreaseBlockReward from './admin/increase-block-reward'
import UnicryptAdmin from './admin/unicrypt-admin'

export default {

  props: {
    address: null,
    reward_pool: {
      type: Object,
      default: () => {
        return {
          reward_token: {}
        }
      }
    }
  },

  components: {
    TopUp,
    DrainToken,
    IncreaseBlockReward,
    UnicryptAdmin
  },

  data: () => ({
  }),

  computed: {
  },

  methods: {
  },

  created () {
  }
}
</script>