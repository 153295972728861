<template>
  <div>

    <div v-if="firstLoad" class="text-center py-8">
      <v-progress-circular
      indeterminate
      size="90"
      color="#aaa">
        <img 
        src="@/assets/img/UNCX_fill.svg" 
        height="80px"
        class="greyscale"
        width="80px">
      </v-progress-circular>
      <div class="caption textFaint--text mt-4">
        Connect your wallet to speed up loading times
      </div>
    </div>

    <template v-else>

      <div class="text-center textFaint--text caption font-weight-medium">
        Presale contract version: {{ contract_version }}
      </div>

      <div v-if="presaleNotFound" class="pa-8 v-card mx-auto" style="max-width: 600px;">
        <div class="mb-4">
          <v-btn icon :to="`${$store.state.ammPrefix}/ilos`" color="text">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </div>
        No presale for this address is registered on UniCrypt.
        <div class="mt-2 textFaint--text">
          {{ address }}
        </div>
      </div>
      <template v-else>
        <type-one v-if="contract_version < 3" :address="address" :key="sEthersWatcher"></type-one>
        <type-three v-else-if="contract_version === 3" :address="address" :key="sEthersWatcher"></type-three>
        <type-four v-else-if="contract_version === 4" :address="address" :key="sEthersWatcher"></type-four>
        <type-five v-else-if="contract_version === 5" :address="address" :key="sEthersWatcher"></type-five>
        <type-six v-else-if="contract_version === 6" :address="address" :key="sEthersWatcher"></type-six>
      </template>

    </template>
  </div>
</template>

<script>
import PresaleFactoryContract from '@/smart-contracts/presales/presale-factory'
import PresaleContract from '@/smart-contracts/presales/presale-contract'
import TypeOne from './type_1'
import TypeThree from './v3/type_3'
import TypeFour from './v4/type_4'
import TypeFive from './v5/type_5'
import TypeSix from './v6/type_6'

export default {
  props: {
    address: {
      type: String
    }
  },

  components: {
    TypeOne,
    TypeThree,
    TypeFour,
    TypeFive,
    TypeSix
  },

  data: () => ({
    contract_version: -1,
    firstLoad: true,
    presaleNotFound: false,
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
  },

  methods: {
    async getContractVersion () {
      this.contract_version = await PresaleContract.getContractVersion(this.address)
      this.firstLoad = false
    }
  },

  created () {
    PresaleFactoryContract.presaleIsRegistered(this.address)
      .then(isTrue => {
        if (isTrue) {
          this.getContractVersion()
        } else {
          this.presaleNotFound = true
          this.firstLoad = false
        }
      })
      .catch(e => {
        console.log(e)
      })
  }
}
</script>
