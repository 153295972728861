<template>
  <div class="pa-4">

    <div class="d-flex align-center">
      <div class="font-weight-bold">
        Stake Mod Fee:
      </div>
      <span class="ml-1 primary--text">
        {{ pool_info.stake_mod_fee_allowed ? 'Enabled' : 'Disabled'}}
      </span>
      <v-spacer></v-spacer>
      <v-btn v-if="pool_info.stake_mod_fee_allowed" color="lgrad-green" class="white--text" rounded @click="setStakeModFeeState(false)">
        Disable
      </v-btn>
      <v-btn v-else color="lgrad-green" class="white--text" rounded @click="setStakeModFeeState(true)">
        Enable
      </v-btn>
    </div>

    <v-card class="pa-4 mt-7">
      <div class="pb-4 font-weight-bold">
        Override Boost token / amount for this pool
      </div>
      <load-token @on-update="updateToken" :address="pool_info.uncl_token_address"></load-token>

      <div class="mt-4 font-weight-bold">
        Boost Fee Address
      </div>

      <div class="textFaint--text caption">
        The address where bosting fees are sent
      </div>

      <div class="mt-2 pa-2 br-8 inputcolor mb-4">
        <div class="d-flex align-center py-2">
          <template v-if="!stakeModFeeAddressIsValid">
            <v-icon small color="red" class="mr-1">mdi-alert-outline</v-icon>
          </template>
          <template v-else>
            <v-icon small color="primary" class="mr-1">mdi-check-circle-outline</v-icon>
          </template>
          <c-input :value.sync="unclFeeAddress" placeholder="0x...">
          </c-input>
        </div>
      </div>

      <div class="mt-4 font-weight-bold">
        Amount for max boost
      </div>

      <div class="pa-3 align-center flex-nowrap inputcolor br-8" style="border: 1px solid var(--v-outline-base)!important;">

        <div class="d-flex align-center">
          <c-input :value.sync="amountBoostHuman" placeholder="0.0" @updateWhileFocussed="onBoostAmountChanged" class="title font-weight-bold">

          </c-input>

          <v-btn color="foreground" class="px-2 py-5" rounded @click.native="$root.tokenPage.open(unclBoostToken.address, $store.state.requiredNetwork)" style="min-width: 10px;max-height: unset;">
            <coin-icon :address="unclBoostToken.address" :size="24" class="mr-1"></coin-icon>
            <span :class="['text-truncate text-uppercase']" :style="$vuetify.breakpoint.xs ? 'max-width: 14ch;' : 'max-width: 20ch;'">
              {{ unclBoostToken.symbol }}
            </span>
            <v-icon small>mdi-chevron-down</v-icon>
          </v-btn>

        </div>

      </div>

      <div class="mt-4 font-weight-bold">
        Max boost percent (e.g. 100)
      </div>

      <div class="pa-3 align-center flex-nowrap inputcolor br-8" style="border: 1px solid var(--v-outline-base)!important;">
        <div class="d-flex align-center">
          <c-input :value.sync="boostPercentHuman" @updateWhileFocussed="onBoostPercentChanged" placeholder="0.0" class="title font-weight-bold">
          </c-input>
        </div>
      </div>

      <div class="pa-2 text-end">
        <v-btn color="lgrad-green" rounded @click="overrideUNCLBoostInfo">
          Update
        </v-btn>
      </div>

    </v-card>

  </div>
</template>

<script>
import { ethers } from 'ethers'
import TxWrapper from '@/smart-contracts/tx-wrapper'
import StakePoolContract from '@/smart-contracts/farms-v2/normal/stake-pool/v1/stake-pool'

export default {

  props: {
    address: null,
    pool_info: {
      type: Object,
      default: () => {
        return {
          share_weight_total: '0',
          min_stake_amount: '0',
          max_stake_amount: '0',
        }
      }
    },
  },

  components: {
  },

  data: () => ({
    unclBoostToken: {},
    unclFeeAddress: '',
    amountBoost: '0',
    amountBoostHuman: '0',
    boostPercent: '0',
    boostPercentHuman: '0',
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    stakeModFeeAddressIsValid () {
      if (this.unclFeeAddress === ethers.constants.AddressZero) {
        return false
      }
      try {
        ethers.utils.getAddress(this.unclFeeAddress)
        return true
      } catch (e) {
        return false
      }
    },
  },

  methods: {
    setStakeModFeeState (_state) {
      TxWrapper.doTransaction(StakePoolContract.setStakeModFeeState(this.address, _state), this.$root.$dialog.confirmTx, 1)
        .then(response => {
          this.$emit('hard-refresh')
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
        })
    },
    overrideUNCLBoostInfo () {
      TxWrapper.doTransaction(StakePoolContract.overrideUNCLBoostInfo(this.address, this.unclBoostToken.address, this.unclFeeAddress, this.boostPercent, this.amountBoost), this.$root.$dialog.confirmTx, 1)
        .then(response => {
          this.$emit('hard-refresh')
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
        })
    },
    updateToken (token) {
      this.unclBoostToken = token;
    },
    onBoostAmountChanged (_val) {
      try {
        this.amountBoost = ethers.utils.parseUnits(_val, this.unclBoostToken.decimals).toString()
      } catch (e) {
        this.amountBoost = '0'
      }
    },
    onBoostPercentChanged (_val) {
      try {
        this.boostPercent = ethers.utils.parseUnits(_val, 2).toString()
      } catch (e) {
        this.boostPercent = '0'
      }
    },
  },

  created () {
    this.unclFeeAddress = this.pool_info.uncl_fee_address
    this.amountBoost = this.pool_info.uncl_boost_max_amount
    this.amountBoostHuman = ethers.utils.formatUnits(this.pool_info.uncl_boost_max_amount, this.unclBoostToken.decimals)
    this.boostPercent = this.pool_info.uncl_boost_max_percentage
    this.boostPercentHuman = ethers.utils.formatUnits(this.pool_info.uncl_boost_max_percentage, 2)
  }
}
</script>