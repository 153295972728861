<template>
  <v-container :style="`max-width: ${$vuetify.breakpoint.xs ? '600px': '1100px'};`" :class="[{'pa-0 foreground mobile-page pb-16': $vuetify.breakpoint.xs}]">

    <!-- TAB 0 -->
    <v-card v-if="tab === 0" style="max-width: 600px;" :class="['br-20 mx-auto', {'pa-4': $vuetify.breakpoint.xs}, {'pa-8': !$vuetify.breakpoint.xs}]">

      <div class="d-flex align-center">
        <v-btn icon :to="`${$store.state.ammPrefix}/ilos`" color="textFaint">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn href="https://docs.unicrypt.network/ilo-platform/audit-dev-documentation/creating-your-ilo" target="_blank"
        rounded outlined color="primary">
          ILO Help
        </v-btn>
      </div>

      <div class="display-1 text-center font-weight-medium mb-1 mt-4">
        LAUNCHPAD
      </div>
      <div class="text-center textFaint--text">
        Run a decentralised Initial Liquidity Offering (ILO) to raise funds and liquidity for your project with our trusted decentalised launchpad.
      </div>

      <div class="mt-3 mb-3 d-flex border align-center font-weight-medium pa-6 br-20" style="position: relative; overflow: hidden;">
        <div>
          <div class="text-center">
            <img 
            src="@/assets/img/icons/rocket.svg" 
            height="60px"
            width="60px"
            class="primary-pulse br-c">
          </div>
          <div class="d-flex align-center justify-center title font-weight-bold mb-2">
            <div class="text-uppercase">
              Unicrypt Incubator
            </div>
          </div>
          <div class="text-center textFaint--text">
            Is your project stellar!? Apply to be incubated by Unicrypt by sending us a mail at:
            <div class="text--text mb-2">support@unicrypt.network</div>
            <div class="caption">
              If you would like to be incubated do not create a presale yet, we'll help with marketing, KYC, Audits, Hardcaps and presale parameters.
            </div>
          </div>
        </div>
      </div>

      <div class="font-weight-bold mb-2">
        Presale best practices
      </div>
      <ul class="textFaint--text">
        <li>
          Raise around $300k. <strong>The best presales raise less</strong>. Raise so there is room for your market cap to grow.
        </li>
        <li>
          <router-link :to="`/services/lock-tokens`">Use token vesting</router-link> to lock as many of your team tokens as you can to increase trust in your project and your tokenomics score in the presale.
        </li>
        <li>
          <router-link :to="`/services/lock-tokens`">Use token vesting</router-link> to send tokens to marketers if you need to give tokens to anyone before a presale concludes. This ensures no one can 
          create a pair on an exchange with liquidity before a presale concludes and set the initial price (this will cause the presale to fail).
        </li>
        <li>
          Build trust by applying for 
          <a href="https://docs.unicrypt.network/auditors-and-kyc/auditors-and-kyc" target="_blank" class="deadlink primary--text">
            Audits and KYC
          </a>.
          Alternatively use our <router-link :to="`/services/minter`">ENMT safe-token minter</router-link> to mint a pre-audited token.
        </li>
      </ul>

      <div class="mt-4">
        <v-btn block large rounded color="primary" class="title" @click="tab = 1">
          Create my presale
        </v-btn>
      </div>
    </v-card>
    <!-- TAB 0 -->

    <v-row v-if="tab === 1" dense class="ma-0">
      
      <v-col cols="12" md="6">

        <div class="align-center blue-button white--text border ma-2 br-20 pa-4 border-b">
          <div class="title text-center mb-3">
            BOOST YOUR PRESALE SCORE
          </div>
          <v-btn color="background" rounded class="title" block large to="/services/minter">
            Mint a safe token (ENMT)
          </v-btn>
          <div>
            <div class="font-weight-bold text-center mb-3 mt-2">
              Highly recommended. Now with no zero flat rate fees!
            </div>
            <div class="text-center">
              Conducting a presale with an ENMT token means you get an automatic token audit <span class="font-weight-bold">for free</span>, and an additional green badge on the presale. Give investors security and peace of mind with a secure non mintable token contract.
            </div>
          </div>
        </div>

        <generated-tokens></generated-tokens>

      </v-col>
      <v-col cols="12" md="6">

        <v-card :class="['br-20 midground']">

          <div class="title textFaint--text mb-3 pa-4">
            Create your presale
          </div>

          <div>
            
            <div v-if="!sEthers.coinbase" class="pa-2">
              <v-btn large block rounded outlined color="textFaint" @click="connectWallet" style="border: 1.5px solid;">
                <v-icon size="24" color="">mdi-ethereum</v-icon>
                <div>
                  <div class="">
                    Connect your wallet to continue
                  </div>
                </div>
              </v-btn>
            </div>

            <!-- WALLET CONNECTED -->
            <template v-else>

              <div class="u_label">
                Token address
              </div>

              <div class="u_block">

                <div class="mt-2 d-flex align-center pa-3 r-outlined br-20 inputcolor">
                  <c-input :value.sync="presaleTokenAddress" placeholder="enter your token address...">
                  </c-input>

                  <v-progress-circular
                  v-if="loadingPresaleToken || checkingIfPairExists"
                  indeterminate
                  width="2"
                  class="mr-2"
                  size="24"
                  color="primary"
                  ></v-progress-circular>
                </div>

                <v-slide-y-transition>
                  <v-card v-if="presaleTokenHydrated.address && !uniswapPairExists && !checkingIfPairExists" outlined class="pa-4 br-20 mt-4">
                    <div class="d-flex align-center textFaint--text">
                      <coin-icon :address="presaleTokenHydrated.address" class="mr-2"></coin-icon>
                      {{ presaleTokenHydrated.symbol }} / {{ presaleTokenHydrated.name }}
                      <v-spacer></v-spacer>
                      {{ presaleTokenAddressCondensed }}

                      <v-btn icon color="text" @click="presaleTokenHydrated = {}">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </v-card>
                </v-slide-y-transition>

                <v-slide-y-transition>
                  <div v-if="presaleTokenHydrated.address && !presaleTokenHydrated.isENMT" class="font-weight-bold v-card lgrad-blue white--text pa-4 br-20 mt-4">
                    Custom token detected
                    <div class="caption">
                      Did you know you can use our token factory to mint a safe token (ENMT) instead to get a free audit and boosted presale ranking?
                      <v-btn rounded to="/services/minter" color="background">
                        Mint a safe token
                      </v-btn>
                    </div>
                  </div>
                </v-slide-y-transition>

                <v-slide-y-transition>
                  <div v-if="presaleTokenHydrated.address && uniswapPairExists" class="v-card blue-button white--text pa-4 br-20 mt-4">
                    <div class="d-flex align-center">
                      <coin-icon :address="presaleTokenHydrated.address" class="mr-2"></coin-icon>
                      {{ presaleTokenHydrated.symbol }} / {{ presaleTokenHydrated.name }}
                      <v-spacer></v-spacer>
                      {{ presaleTokenAddressCondensed }}

                      <v-btn icon color="white" @click="presaleTokenHydrated = {}">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                    <div class="text-center mt-4">
                      The {{ baseTokenHydrated.symbol }} / {{ presaleTokenHydrated.symbol }} pair already has liqudity on {{ $store.state.exchange }}.
                    </div>
                    <div class="text-center mt-1 caption">
                      You cannot create a presale for this pair as the price is already set.
                    </div>
                    <div class="text-center mt-4">
                      
                    </div>
                  </div>
                </v-slide-y-transition>

              </div>

              <div class="u_label d-flex align-center pt-2 pr-2">
                Buyers participate with
                <v-spacer></v-spacer>
                <v-btn @click="openSelectBaseTokenDialog" rounded class="title text background--text">
                  <coin-icon :address="baseTokenHydrated.address" class="mr-2" :size="26"></coin-icon>
                  {{ baseTokenHydrated.symbol }}
                  <v-icon small>mdi-chevron-down</v-icon>
                </v-btn>
              </div>

              <div class="u_block">

              </div>

              <div class="u_label">
                {{ $store.state.exchange }} pair to be created
              </div>

              <div class="u_block">

                <div class="font-weight-bold">
                  {{ baseTokenHydrated.symbol }} / {{ presaleTokenHydrated.symbol || '?' }}
                </div>

              </div>
              
              <template v-if="presaleTokenHydrated.address && !uniswapPairExists && !checkingIfPairExists">
                
                <div class="ml-4 pb-2">
                  <div class="mt-1 font-weight-bold textFaint--text">
                    Presale creator
                  </div>

                  <div class="mb-2 font-weight-bold">
                    {{ sEthers.coinbase_condensed }}
                  </div>

                  <div class="mb-2 caption textFaint--text">
                    This account will be the only account capable of adding presale information, editing presale contract paramaters and
                    unlocking liquidity.
                  </div>

                </div>

                <div class="u_block">

                  <div class="u_label">
                    How many {{ presaleTokenHydrated.symbol }} are up for presale?
                  </div>

                  <div class="pa-4 align-center flex-nowrap inputcolor r-outlined">

                    <div class="caption text-end pt-2">
                      Balance: {{ presaleTokenBalanceHuman }}
                    </div>

                    <div class="d-flex align-center">
                      <c-input :value.sync="amountHuman" placeholder="0.0" @updateWhileFocussed="onPresaleTokenAmountChange" class="pa-2 font-weight-bold">

                      </c-input>

                      <div class="font-weight-bold text-uppercase">
                        {{ presaleTokenHydrated.symbol }}
                      </div>

                      <v-btn v-if="false" small depressed color="primary" @click="setMax" class="ml-2">
                        MAX
                      </v-btn>
                    </div>

                    <div v-if="false">
                      <v-btn small outlined rounded color="" @click="setPercent(25)" class="mr-1">
                        25%
                      </v-btn>
                      <v-btn small outlined rounded color="" @click="setPercent(50)" class="mr-1">
                        50%
                      </v-btn>
                      <v-btn small outlined rounded color="" @click="setPercent(75)" class="mr-1">
                        75%
                      </v-btn>
                      <v-btn v-if="false" small outlined rounded color="" @click="setMax" class="mr-1">
                        100%
                      </v-btn>
                    </div>

                  </div>

                  <div v-if="!userHasSufficientTokens" class="pink--text">
                      You do not have enough tokens
                    </div>

                  <div v-if="eMinDivis" class="pink--text">
                    A minimum divisibility of 10,000 units (including decimals) is required for a presale.
                  </div>

                </div>

                <v-row class="mt-6 foreground px-4">
                  <v-col cols="6">

                    <div class="mb-2 textFaint--text font-weight-bold text-center">
                      Softcap
                    </div>

                    <div class="d-flex align-center inputcolor pa-4 r-outlined">
                      <c-input :value.sync="softcapHuman" placeholder="0.0" @updateWhileFocussed="onSoftcapAmountChange" class="pa-2 font-weight-bold">
                      </c-input>

                      <div class="font-weight-bold">
                        {{ baseTokenHydrated.symbol }}
                      </div>
                    </div>

                    <div v-if="eSoftcapLessThanRequiredPercentageOfHardcap" class="pink--text">
                      Must be >= 1/4 hardcap
                    </div>
                    <div v-if="eSoftcapIsZero" class="pink--text">
                      Must be > 0
                    </div>
                  </v-col>
                  <v-col cols="6">

                    <div class="mb-2 textFaint--text font-weight-bold text-center">
                      Hardcap
                    </div>

                    <div class="d-flex align-center inputcolor pa-4 r-outlined">
                      <c-input :value.sync="hardcapHuman" placeholder="0.0" @updateWhileFocussed="onHardcapAmountChange" class="pa-2 font-weight-bold">
                      </c-input>

                      <div class="font-weight-bold">
                        {{ baseTokenHydrated.symbol }}
                      </div>
                    </div>
                    <div v-if="eHardcapLessThanSoftcap" class="pink--text">
                      Must be >= than softcap
                    </div>
                    <div v-if="eHardcapIsZero" class="pink--text">
                      Must be > 0
                    </div>
                  </v-col>
                </v-row>

                <div class="mt-10 text-center">
                  Presale rate
                </div>
                <div class="mb-2 title primary--text font-weight-bold text-center">
                  1 {{ baseTokenHydrated.symbol }} = {{ tokensPerEthHuman }} {{ presaleTokenHydrated.symbol }}
                </div>

                <div class="mt-10 text-center">
                  Listing rate
                </div>
                <div class="mb-2 title primary--text font-weight-bold text-center">
                  1 {{ baseTokenHydrated.symbol }} = {{ listingRateHuman }} {{ presaleTokenHydrated.symbol }}
                </div>
                <div class="text-center">
                  <v-btn small outlined rounded :color="listingRatePercent === 0 ? 'primary' : ''" @click="setListingRatePercent(0)" class="mr-1">
                    0%
                  </v-btn>
                  <v-btn small outlined rounded :color="listingRatePercent === 100 ? 'primary' : ''" @click="setListingRatePercent(100)" class="mr-1">
                    10%
                  </v-btn>
                  <v-btn small outlined rounded :color="listingRatePercent === 150 ? 'primary' : ''" @click="setListingRatePercent(150)" class="mr-1">
                    15%
                  </v-btn>
                  <v-btn small outlined rounded :color="listingRatePercent === 250 ? 'primary' : ''" @click="setListingRatePercent(250)" class="mr-1">
                    25%
                  </v-btn>
                  <v-btn small outlined rounded :color="listingRatePercent === 300 ? 'primary' : ''" @click="setListingRatePercent(300)" class="mr-1">
                    30%
                  </v-btn>
                </div>

                <div class="textFaint--text font-weight-bold text-center mt-8">
                  Percent of raised {{ baseTokenHydrated.symbol }} used for liquidity
                </div>
                <div class="display-1 text-center mt-4">
                  {{ liquidityPercent }}%
                </div>
                <div v-if="false" class="text-center textFaint--text">
                  Minus fee ({{ actualLiquidityPercent }} %)
                </div>
                <div class="d-flex align-center">
                  <v-btn x-large icon>
                    <v-icon
                      x-large
                      color="primary"
                      @click="liquidityPercent-=5"
                    >
                      mdi-minus
                    </v-icon>
                  </v-btn>
                  <v-slider
                    dense
                    hide-details
                    v-model="liquidityPercent"
                    color="primary"
                    track-color="grey"
                    always-dirty
                    min="60"
                    max="100"
                  >
                  </v-slider>
                  <v-btn x-large icon>
                    <v-icon
                      x-large
                      color="primary"
                      @click="liquidityPercent+=5"
                    >
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </div>
                <div v-if="liquidityPercent === 100" class="text-center font-weight-bold pa-2 pink--text">
                  Please be aware you will not raise ANY {{ baseTokenHydrated.symbol }} dev funds with these settings
                </div>
                <div v-else-if="liquidityPercent > 90" class="pink--text text-center pa-2">
                  Please be aware you will not raise much {{ baseTokenHydrated.symbol }} dev funds with these settings
                </div>

                <div class="u_block pa-4">
                  <div class="mb-2 textFaint--text text-center">
                    Additional tokens required for liquidity if hardcap is met
                  </div>

                  <div class="d-flex align-center">
                    <coin-icon :address="presaleTokenHydrated.address" class="mr-2"></coin-icon>
                    <div class="font-weight-bold">
                      {{ amountLiquidityHardcapHuman }}
                    </div>

                    <div class="font-weight-medium textFaint--text text-uppercase ml-2">
                      {{ presaleTokenHydrated.symbol }}
                    </div>
                  </div>
                </div>

                <div class="mt-8 u_block">
                  <!-- @click="showPredictionPanel = !showPredictionPanel" -->
                  <div class="title text-center mt-8">
                    Presale prediction
                  </div>

                  <div class="text-center textFaint--text">
                    Use the slider to predict fee and liquidity amounts depending on amounts raised in presale.
                  </div>

                  <v-slide-y-transition>
                    <div v-if="showPredictionPanel">

                      <div class="display-1 text-center mt-8">
                        {{ predictiveBaseRaisedHuman }} {{ baseTokenHydrated.symbol }}
                      </div>
                      <div class="d-flex align-center">
                        <v-btn x-large icon>
                          <v-icon
                            x-large
                            color="primary"
                            @click="presalePredictionPercent-=5"
                          >
                            mdi-minus
                          </v-icon>
                        </v-btn>
                        <v-slider
                          dense
                          hide-details
                          v-model="presalePredictionPercent"
                          color="primary"
                          track-color="grey"
                          always-dirty
                          min="0"
                          max="100"
                        >
                        </v-slider>
                        <v-btn x-large icon>
                          <v-icon
                            x-large
                            color="primary"
                            @click="presalePredictionPercent+=5"
                          >
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </div>
                      <div v-if="false" class="caption primary--text text-center">
                        {{ presalePredictionPercent }}%
                      </div>

                      <v-row dense>
                        <v-col cols="4">
                          <div class="mt-10 mb-2 textFaint--text pl-2">
                            Unicrypt fee
                          </div>

                          <div class="d-flex align-center pa-4 r-outlined">
                            {{ predictiveUnicryptBaseFeeHuman }} {{ baseTokenHydrated.symbol }}
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <div class="mt-10 mb-2 textFaint--text pl-2">
                            {{ baseTokenHydrated.symbol }} liquidity
                          </div>

                          <div class="d-flex align-center pa-4 r-outlined">
                            {{ predictiveBaseLiquidityHuman }} {{ baseTokenHydrated.symbol }}
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <div class="mt-10 mb-2 textFaint--text pl-2">
                            Your {{ baseTokenHydrated.symbol }}
                          </div>

                          <div class="d-flex align-center primary--text pa-4 r-outlined">
                            {{ predictiveOwnerBaseHuman }} {{ baseTokenHydrated.symbol }}
                          </div>
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <v-col cols="4">
                          <div class="mb-2 textFaint--text pl-2">
                            Unicrypt fee
                          </div>

                          <div class="d-flex align-center pa-4 r-outlined">
                            {{ predictiveUnicryptTokenFeeHuman }} {{ presaleTokenHydrated.symbol }}
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <div class="mb-2 textFaint--text pl-2">
                            {{ presaleTokenHydrated.symbol }} liquidity
                          </div>

                          <div class="d-flex align-center pa-4 r-outlined">
                            {{ predictiveTokenLiquidityHuman }} {{ presaleTokenHydrated.symbol }}
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <div class="mb-2 textFaint--text pl-2">
                            {{ presaleTokenHydrated.symbol }} sold
                          </div>

                          <div class="d-flex align-center pa-4 r-outlined">
                            {{ predictiveTokensSoldHuman }} {{ presaleTokenHydrated.symbol }}
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-slide-y-transition>
                </div>

                <div class="u_block mt-4">
                  <div class="mb-2 textFaint--text text-center">
                    Max allocation per user
                  </div>

                  <div class="d-flex align-center inputcolor pa-2 pr-4 r-outlined">
                    <c-input :value.sync="maxSpendHuman" placeholder="0.0" @updateWhileFocussed="onMaxSpendAmountChange" class="pa-2">
                    </c-input>

                    <div class="font-weight-bold">
                      {{ baseTokenHydrated.symbol }}
                    </div>
                  </div>

                  <div v-if="eMaxSpendIsZero" class="pink--text">
                    Must be above 0
                  </div>

                  <div class="caption text-center">
                    {{ numHardcapParticipants }} unique participants
                  </div>

                  <div v-if="numHardcapParticipants > 199" class="primary--text justify-center d-flex align-center">
                    <v-icon small color="primary">mdi-checkbox-marked-circle-outline</v-icon>
                    Great!
                  </div>
                  <div v-else-if="numHardcapParticipants < presaleSettings.minimum_participants" class="text-center red--text">
                    <div class="caption">
                      <v-icon small color="red">mdi-alert-circle-outline</v-icon>
                      Error: Allocation too high!
                    </div>
                    <v-btn rounded depressed color="red" @click="setIdealAllocation" class="white--text">
                      Set allocation to {{ $root.formatAmount(idealAllocation, baseTokenHydrated.decimals) }} {{ baseTokenHydrated.symbol }}
                    </v-btn>
                  </div>
                  <div v-else class="text-center orange--text">
                    <div class="caption">
                      <v-icon small color="orange">mdi-alert-circle-outline</v-icon>
                      Warning: Allocation high!
                    </div>
                    <v-btn rounded depressed color="orange" @click="setIdealAllocation" class="white--text">
                      Set allocation to {{ $root.formatAmount(idealAllocation, baseTokenHydrated.decimals) }} {{ baseTokenHydrated.symbol }}
                    </v-btn>
                  </div>

                  <div class="text-center caption font-weight-bold textFaint--text mt-2">
                    Round 0 spots
                  </div>
                  <v-row dense class="ma-0">
                    <v-col cols="6" class="border">
                      <div class="text-center">
                        <strong class="title">{{ numUNCLParticipants }}</strong> UNCL spots
                      </div>
                    </v-col>
                    <v-col cols="6" class="border">
                      <div class="text-center">
                        <strong class="title">{{ numWhitelistParticipants }}</strong> whitelist spots
                      </div>
                    </v-col>
                  </v-row>

                  <div class="text-center caption textFaint--text">
                    *These amounts change based on max allocation per user
                  </div>

                </div>

                <div class="u_block">
                  <div class="mb-2 text-center textFaint--text">
                    Lock Liquidity for
                  </div>
                  <v-menu
                  offset-y
                  >
                    <template v-slot:activator="{ attrs, on }">
                      <div class="d-flex align-center inputcolor pa-4 r-outlined"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ lockPeriod }}
                        <v-spacer></v-spacer>
                        <v-icon small>mdi-chevron-down</v-icon>
                      </div>
                    </template>

                    <v-list style="min-width: 200px;" class="background">
                      <v-list-item @click="lockPeriod = '1 month'">
                        <v-list-item-title>
                          1 month
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="lockPeriod = '2 months'">
                        <v-list-item-title>
                          2 months
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="lockPeriod = '3 months'">
                        <v-list-item-title>
                          3 months
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="lockPeriod = '6 months'">
                        <v-list-item-title>
                          6 months
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="lockPeriod = '1 year'">
                        <v-list-item-title>
                          1 year
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="lockPeriod = 'Max: 266 years'">
                        <v-list-item-title>
                          Max: 266 years
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>

                <div class="u_block">
                  <template v-if="round0Start === '0'">
                    <div class="textFaint--text mb-2 font-weight-bold text-center">
                      Would you like to set a custom round zero start date?
                    </div>

                    <div class="text-center">
                      <v-btn depressed :color="round0Start === '0' ? 'primary' : ''" @click="round0Start = '0'">
                        No
                      </v-btn>
                      <v-btn depressed :color="round0Start !== '0' ? 'primary' : ''" @click="round0Start = '1'">
                        Yes
                      </v-btn>
                    </div>
                  </template>

                  <template v-else>

                    <div class="mb-2 textFaint--text font-weight-bold text-center">
                      Round Zero start date (UNCL reservation round)
                    </div>

                    <b-date :block.sync="round0Start"></b-date>

                    <div v-if="Number(round0Start) > Number(startBlock)" class="orange--text">
                      Round zero must be before the Start block
                    </div>

                    <div class="text-center mt-3">
                      <v-btn small rounded color="primary" @click="round0Start = '0'">
                        Use defaults
                      </v-btn>
                      <div class="caption textFaint--text">
                        Default settings: 2 hours after presale creation
                      </div>
                    </div>

                  </template>
                </div>

                <div class="u_block">
                  <div class="mb-2 textFaint--text font-weight-bold text-center">
                    Presale Start Date
                  </div>

                  <b-date :block.sync="startBlock"></b-date>

                  <div v-if="startBlock < oneWeekFromNowAsBlockNumber" class="orange--text">
                    Presale should ideally start 1 week from today to give you time to raise awareness
                  </div>
                </div>

                <div class="u_block">
                  <div class="mb-2 textFaint--text font-weight-bold text-center">
                    End Date
                  </div>

                  <b-date :block.sync="endBlock"></b-date>

                  <div>
                    Presale duration: {{ presaleDurationHuman }} / {{ presaleBlockDuration }} blocks
                  </div>

                  <div v-if="Number(endBlock) < Number(startBlock)" class="caption white--text lgrad-red d-flex align-center font-weight-medium pa-4 mb-1">
                    <v-icon size="26" color="white" class="mr-2">mdi-alert-outline</v-icon>
                    <div class="flex">
                      <div>
                        Your end date must be after the start date
                      </div>
                    </div>
                  </div>

                  <div v-if="eStartEndExceedsMax" class="caption white--text lgrad-red d-flex align-center font-weight-medium pa-4 mb-1">
                    <v-icon size="26" color="white" class="mr-2">mdi-alert-outline</v-icon>
                    <div class="flex">
                      <div>
                        Presale duration too long. Maximum of {{ presaleMaxDurationHuman }} allowed
                      </div>
                    </div>
                  </div>

                  <div v-if="eEndBlockLessThanCurrent" class="caption white--text lgrad-red d-flex align-center font-weight-medium pa-4 mb-1">
                    <v-icon size="26" color="white" class="mr-2">mdi-alert-outline</v-icon>
                    <div class="flex">
                      <div>
                        Your endblock is before the current block. Presale will instantly fail.
                      </div>
                    </div>
                  </div>

                </div>

                <!-- COUNTRY SELECT -->
                <div class="u_block">
                  <div class="font-weight-bold textFaint--text pb-2">
                    Select your country of operation. Where is your business headquartered?
                  </div>
                  <div class="text-center">
                    <v-btn v-if="selectedCountry.id === -1" @click="openSelectCountryDialog" large rounded class="pink white--text">
                        Select your Country
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                    <v-btn v-else @click="openSelectCountryDialog" large rounded class="primary">
                        {{ selectedCountry.country_name }}
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </div>
                </div>
                <!-- END COUNTRY SELECT -->
                
                <!-- REFERRALS -->
                <div class="u_block">
                  <div class="textFaint--text mb-2 font-weight-bold text-center">
                    Do you have a valid referral address?
                  </div>

                  <div class="text-center">
                    <v-btn depressed :color="!hasReferralAddress ? 'primary' : ''" @click="hasReferralAddress = false">
                      No
                    </v-btn>
                    <v-btn depressed :color="hasReferralAddress ? 'primary' : ''" @click="hasReferralAddress = true">
                      Yes
                    </v-btn>
                  </div>

                  <v-slide-y-transition>
                    <div v-if="hasReferralAddress">
                      <div class="textFaint--text mt-4 caption">
                        Enter the ethereum address of one of Unicrypts partners who referred you here.
                      </div>
                      <div class="mt-2 d-flex align-center pa-3 r-outlined br-8 inputcolor">
                        <c-input :value.sync="referralAddress" placeholder="Referral address">
                        </c-input>

                        <v-progress-circular
                        v-if="referralIsLoading"
                        indeterminate
                        width="2"
                        class="mr-2"
                        size="24"
                        color="primary"
                        ></v-progress-circular>
                      </div>

                      <div v-if="!referralAddressIsAddress && !referralIsLoading" class="text-center pink--text">
                        Referral address not valid
                      </div>
                      <div v-else-if="referralAddressIsAddress && !referralIsLoading && !referrerIsValid" class="text-center pink--text">
                        Invalid Referrer
                      </div>
                      <div v-else class="primary--text justify-center d-flex align-center">
                        <v-icon color="primary">mdi-checkbox-marked-circle-outline</v-icon>
                        Valid
                      </div>
                    </div>
                  </v-slide-y-transition>
                </div>
                <!-- REFERRALS -->

                <!-- TOKEN VESTING -->
                <div class="u_block">
                  <div class="textFaint--text mb-2 font-weight-bold text-center">
                    Do you plan to vest participants tokens?
                  </div>

                  <div class="textFaint--text caption mb-2 font-weight-medium text-center">
                    This feature must be requested from a Unicrypt admin, only they can implement it.
                    However its important you set the flag to true so your users are aware in Round 0 
                    that a percentage of their tokens will be vested post presale.
                  </div>

                  <div class="text-center">
                    <v-btn depressed :color="!implementVesting ? 'primary' : ''" @click="implementVesting = false">
                      No
                    </v-btn>
                    <v-btn depressed :color="implementVesting ? 'primary' : ''" @click="implementVesting = true">
                      Yes
                    </v-btn>
                  </div>
                </div>
                <!-- TOKEN VESTING -->
                
                <div class="u_block">
                  <div class="mb-2 textFaint--text font-weight-bold text-center">
                    Fees
                  </div>

                  <div class="d-flex align-center">
                    Presale creation:
                    <v-spacer></v-spacer>
                    <span>
                      {{ ethCreationFeeHuman }} {{ $store.state.nativeGasTokenSymbol }}
                    </span>
                  </div>

                  <div class="d-flex align-center">
                    {{ presaleSettings.base_fee / 10 }}%:
                    <v-spacer></v-spacer>
                    <span>
                      {{ presaleSettings.base_fee / 10 }}% of raised {{ baseTokenHydrated.symbol }}
                    </span>
                  </div>

                  <div class="d-flex align-center">
                    {{ presaleSettings.token_fee / 10 }}%:
                    <v-spacer></v-spacer>
                    <span>
                      {{ presaleSettings.token_fee / 10 }}% of sold {{ presaleTokenHydrated.symbol }}
                    </span>
                  </div>

                  <div class="d-flex align-center">
                    Liquidity Lock:
                    <v-spacer></v-spacer>
                    <span>
                      Standard locker fee
                    </span>
                  </div>

                </div>

                <div class="u_block mt-8">
                  <div class="textFaint--text font-weight-bold text--text text-center">
                    Tokenomics score {{ tokenomicsScore }}%
                  </div>
                  <div class="d-flex justify-center">
                    <div style="width: 80%;max-width: 250px;">
                      <donut-chart :chartdata="chartData" :options="chartOptions" ref="donutChart" :red="!userHasSufficientTokens" />
                    </div>
                  </div>
                  <div v-if="tokenomicsScore < 50" class="caption white--text lgrad-red d-flex align-center font-weight-medium pa-4 mb-1">
                    <v-icon size="26" color="white" class="mr-2">mdi-alert-outline</v-icon>
                    <div class="flex">
                      <div>
                        Your tokenomics score is quite bad. Your presale stands a HIGH chance of failing due to the risk involved with so many tokens not in the presale or initial liquidity.
                        Investors on Unicrypt are well trained on tokenomics. You should increase the amount of tokens for the presale and liquidity.
                        Take this warning SERIOUSLY to prevent wasting time on an almost sure to fail presale.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="px-4 u_block">

                  <div class="mt-10 mb-2 title font-weight-bold text-center">
                    Total {{ presaleTokenHydrated.symbol }} required
                  </div>

                  <div class="d-flex align-center">
                    Presale:
                    <v-spacer></v-spacer>
                    <span>
                      {{ amountHuman }}
                    </span>
                  </div>

                  <div class="d-flex align-center">
                    Amount for liquidity:
                    <v-spacer></v-spacer>
                    <span>
                      {{ amountLiquidityHardcapHuman }}
                    </span>
                  </div>

                  <div class="d-flex align-center">
                    Fees:
                    <v-spacer></v-spacer>
                    <span>
                      {{ tokenFeeHuman }}
                    </span>
                  </div>

                  <div class="d-flex align-center">
                    Total Required:
                    <v-spacer></v-spacer>
                    <span class="font-weight-bold">
                      {{ totalTokensRequiredHuman }}
                    </span>
                  </div>

                  <template v-if="!userHasSufficientTokens">
                    <div class="d-flex align-center">
                      Your Balance:
                      <v-spacer></v-spacer>
                      <span class="pink--text">
                        {{ presaleTokenBalanceHuman }}
                      </span>
                    </div>
                  </template>

                </div>

                <div class="px-4 u_block">
                  <div class="mt-4 title font-weight-medium">
                    Whitelist and Hypemeter spots
                  </div>
                  <div class="d-flex align-center">
                    UNCL spots:
                    <v-spacer></v-spacer>
                    <span>
                      {{ numUNCLParticipants }}
                    </span>
                  </div>
                  <div class="d-flex align-center">
                    Whitelist spots:
                    <v-spacer></v-spacer>
                    <span>
                      {{ numWhitelistParticipants }}
                    </span>
                  </div>

                  <div class="caption font-weight-bold mt-1 mb-4">
                    If you would like more whitelist spots (20% of hardcap) please decrease the max allocation per user.
                  </div>
                </div>

                <div class="my-4 caption textFaint--text text-center">
                  Please note: If the presale is a success, any unsold tokens are sent to the 0x00...dEaD burn address.
                </div>

                <div v-if="tokenomicsScore < 50" class="caption white--text lgrad-red d-flex align-center font-weight-medium pa-4 mb-1">
                  <v-icon size="26" color="white" class="mr-2">mdi-alert-outline</v-icon>
                  <div class="flex">
                    <div>
                      Your tokenomics score is quite bad. Your presale stands a HIGH chance of failing due to the risk involved with so many tokens not in the presale or initial liquidity.
                      Investors on Unicrypt are well trained on tokenomics. You should increase the amount of tokens for the presale and liquidity.
                      Take this warning SERIOUSLY to prevent wasting time on an almost sure to fail presale.
                    </div>
                  </div>
                </div>

                <div v-if="eInsufficientEth" class="caption white--text lgrad-red d-flex align-center font-weight-medium pa-4 mb-1">
                  <v-icon size="26" color="white" class="mr-2">mdi-alert-outline</v-icon>
                  <div class="flex">
                    <div>
                      You do not have enough {{ $store.state.nativeGasTokenSymbol }} in your wallet to perform this transaction.
                      {{ ethCreationFeeHuman }}
                      {{ $store.state.nativeGasTokenSymbol }} required.
                    </div>
                  </div>
                </div>

                <div v-if="!userHasSufficientTokens" class="caption white--text lgrad-red d-flex align-center font-weight-medium pa-4 mb-1">
                  <v-icon size="26" color="white" class="mr-2">mdi-alert-outline</v-icon>
                  <div class="flex">
                    <div>
                      You do not have enough {{ presaleTokenHydrated.symbol }} tokens in your wallet.
                    </div>
                  </div>
                </div>

                <v-row dense class="ma-0 mt-4">
                  <v-col cols="6">
                    <v-btn @click="approve" color="primary" x-large block depressed :disabled="!allowanceIncreaseRequired" :loading="approvalLoading">
                      Approve
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn @click="createPresale" color="primary" x-large block depressed :disabled="creationDisabled" :loading="createLoading">
                      Create presale
                    </v-btn>
                  </v-col>
                </v-row>

              </template>

            </template>
            <!-- WALLET CONNECTED -->

          </div>

        </v-card>
      </v-col>
    </v-row>

    <!-- DIALOGS -->
    <select-base-token-dialog ref="baseTokenDialog"></select-base-token-dialog>
    <country-list-dialog ref="countryListDialog"></country-list-dialog>
    <!-- DIALOGS -->

    <div v-if="presaleTokenHydrated.address" style="position: fixed; top: 150px; right: 20px;">
      <div style="width: 60px;">
        <donut-chart :chartdata="chartData" :options="chartOptionsSmall" ref="donutChartSmall" :red="!userHasSufficientTokens" />
      </div>
    </div>

  </v-container>
</template>

<script>
import { ethers } from 'ethers'
import PresaleGeneratorContract from '@/smart-contracts/presales/v6/presale-generator01'
import PresaleSettingsContract from '@/smart-contracts/presales/v6/presale-settings'
import PresaleABI from '@/smart-contracts/presales/v6/presale-abis'
import UniswapFactoryContract from '@/smart-contracts/uniswap/uniswap-factory-contract'
import TxWrapper from '@/smart-contracts/tx-wrapper'
import ERC20 from '@/smart-contracts/erc20'
import ERC20PAGER from '@/smart-contracts/erc20/pager'
import BDate from '@/components/ui/block-date'
import SelectBaseTokenDialog from '../select-base-token-dialog'
import CountryListDialog from '@/components/dialogs/country-select/csdialog'
import UniswapABI from '@/smart-contracts/uniswap/uniswap-abis'
import moment from 'moment'
import DonutChart from '@/components/charts/donut6burn'
import GeneratedTokens from '@/views/minter/generated-tokens'
import VestingPager from '@/smart-contracts/token-locker/pager-contract'
import VESTABI from '@/smart-contracts/token-locker/token-locker-abis'

export default {
  components: {
    BDate,
    SelectBaseTokenDialog,
    DonutChart,
    CountryListDialog,
    GeneratedTokens
  },

  data: () => ({
    tab: 0,
    showPredictionPanel: true,
    ethBalance: '0',
    amount: '0',
    amountHuman: '0',
    liquidityPercent: '60',
    presalePredictionPercent: '50',
    allowance: '0',
    hardcap: '0',
    hardcapHuman: '0',
    softcap: '0',
    softcapHuman: '0',
    round0Start: '0',
    startBlock: '0',
    endBlock: '0',
    listingRate: '0',
    listingRateHuman: '0',
    listingRatePercent: 100,
    lockPeriod: '1 year',
    presaleTokenAddress: '',
    baseTokenHydrated: {},
    presaleTokenHydrated: {},
    presaleTokenBalance: '0',
    maxSpend: '0',
    maxSpendHuman: '0',
    presaleSettings: {},
    uniswapPairExists: false,
    referralAddress: '',
    hasReferralAddress: false,
    referrerIsValid: false,
    implementVesting: false,

    referralIsLoading: false,
    approvalLoading: false,
    createLoading: false,
    checkingIfPairExists: false,
    loadingPresaleToken: false,

    selectedCountry: {
      id: -1,
      country_code: null,
      country_name: 'No selection',
      continent_name: null
    },

    chartData: {
      labels: ['Presale', 'Liquidity', 'Fees', 'Locked', 'Burnt', 'Unlocked'],
      datasets: [{
        data: [],
        backgroundColor: [
          'rgb(20, 240, 132)',
          'rgb(54, 162, 235)',
          'rgba(0, 0, 0, 0.1)',
          'rgba(0, 0, 0, 0.1)',
          'rgba(0, 0, 0, 0.1)',
          'rgba(0, 0, 0, 0.1)',
        ],
        borderWidth: 1
        // borderColor: 'rgba(0,0,0,0.1)'
      }]

    },
    chartOptions: {
      cutoutPercentage: 70,
      legend: {
        position: 'right'
      }
    },
    chartOptionsSmall: {
      cutoutPercentage: 70,
      legend: {
        display: false,
        position: 'right'
      },
      events: [] // an empty events array prevents hover events showing tooltips
    },

    tokenLocks: {
      locked: [],
      partial: [],
      unlocked: []
    },
    TOKENOMICS: {
      burnt: '0',
      locked: '0',
    },
    tokenomicsScore: 0
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    blockNumber () {
      return this.$store.state.blockNumber
    },
    idealAllocation () {
      return ethers.BigNumber.from(this.hardcap).div(200).toString()
    },
    numHardcapParticipants () {
      var isZero = ethers.BigNumber.from(this.maxSpend).eq(0)
      var denominator = isZero ? '1' : this.maxSpend
      var numParticipants = ethers.BigNumber.from(this.hardcap).div(denominator)
      try {
        return numParticipants.toNumber()
      } catch (e) {
        return 0
      }
    },
    numUNCLParticipants () {
      var isZero = ethers.BigNumber.from(this.maxSpend).eq(0)
      var denominator = isZero ? '1' : this.maxSpend
      var numParticipants = ethers.BigNumber.from(this.hardcap).div(denominator).mul(this.presaleSettings.uncl_percentage).div(100)
      try {
        return numParticipants.toNumber()
      } catch (e) {
        return 0
      }
    },
    numWhitelistParticipants () {
      var isZero = ethers.BigNumber.from(this.maxSpend).eq(0)
      var denominator = isZero ? '1' : this.maxSpend
      var numParticipants = ethers.BigNumber.from(this.hardcap).div(denominator).mul(this.presaleSettings.whitelist_percentage).div(100)
      try {
        return numParticipants.toNumber()
      } catch (e) {
        return 0
      }
    },
    oneWeekFromNowAsBlockNumber () {
      var oneWeek = 60 * 60 * 24 * 6
      var asBlocks = parseInt(oneWeek / this.$store.state.blocksPerSecond)
      return this.blockNumber + asBlocks
    },
    ethCreationFeeHuman () {
      var amount = ethers.utils.formatUnits(this.presaleSettings.eth_creation_fee, 18)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3})
    },
    combinedTokensAddress () {
      return this.baseTokenHydrated.address + this.presaleTokenHydrated.address
    },
    presaleTokenAddressCondensed () {
      var address = this.presaleTokenAddress
      if (!address) {
        return ''
      }
      return address.slice(0, 6) + '...' + address.slice(address.length - 4)
    },
    presaleTokenBalanceHuman () {
      var amount = ethers.utils.formatUnits(this.presaleTokenBalance, this.presaleTokenHydrated.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8})
    },
    tokensPerEth () {
      if (this.hardcap === '0' || !this.hardcap || !this.presaleTokenHydrated.address) {
        return '0'
      }
      return ethers.BigNumber.from(this.amount).mul((10 ** this.baseTokenHydrated.decimals).toString()).div(this.hardcap).toString()
    },
    tokensPerEthHuman () {
      var amount = ethers.utils.formatUnits(this.tokensPerEth, this.presaleTokenHydrated.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: this.presaleTokenHydrated.decimals})
    },
    allowanceIncreaseRequired () {
      if (ethers.BigNumber.from(this.amount).gt(this.allowance)) {
        return true
      }
      return false
    },
    amountLiquidityHardcap () {
      if (this.tokensPerEth === '0') {
        return '0'
      }
      var listingRate = ethers.BigNumber.from(this.listingRate).mul(1000).div(this.tokensPerEth).toString()
      var amount = ethers.BigNumber.from(this.amount).mul(1000 - this.presaleSettings.token_fee).div(1000).mul(this.liquidityPercent * 10).div(1000).mul(listingRate).div(1000).toString()
      return ethers.BigNumber.from(amount).toString()
    },
    amountLiquidityHardcapHuman () {
      var amount = ethers.utils.formatUnits(this.amountLiquidityHardcap, this.presaleTokenHydrated.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8})
    },
    chartDataComputed () {
      try {
        return `${this.amount} + ${this.amountLiquidityHardcap} + ${this.tokenFee}`
      } catch (e) {
        return 1
      }
    },
    tokenFee () {
      var amount = ethers.BigNumber.from(this.amount).mul(this.presaleSettings.token_fee).div(1000).toString()
      return ethers.BigNumber.from(amount).toString()
    },
    tokenFeeHuman () {
      var amount = ethers.utils.formatUnits(this.tokenFee, this.presaleTokenHydrated.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8})
    },
    totalTokensRequired () {
      return ethers.BigNumber.from(this.tokenFee).add(this.amountLiquidityHardcap).add(this.amount)
    },
    totalTokensRequiredHuman () {
      var amount = ethers.utils.formatUnits(this.totalTokensRequired, this.presaleTokenHydrated.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8})
    },
    userHasSufficientTokens () {
      return ethers.BigNumber.from(this.presaleTokenBalance).gte(this.totalTokensRequired)
    },
    predictiveBaseRaised () {
      var amount = ethers.BigNumber.from(this.hardcap).sub(this.softcap).mul(this.presalePredictionPercent).div(100).toString()
      return ethers.BigNumber.from(amount).add(this.softcap).toString()
    },
    predictiveBaseRaisedHuman () {
      var amount = ethers.utils.formatUnits(this.predictiveBaseRaised, this.baseTokenHydrated.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8})
    },
    predictiveUnicryptBaseFee () {
      return ethers.BigNumber.from(this.predictiveBaseRaised).mul(this.presaleSettings.base_fee).div(1000).toString()
    },
    predictiveUnicryptBaseFeeHuman () {
      var amount = ethers.utils.formatUnits(this.predictiveUnicryptBaseFee, this.baseTokenHydrated.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8})
    },
    predictiveUnicryptTokenFee () {
      return ethers.BigNumber.from(this.predictiveTokensSold).mul(this.presaleSettings.token_fee).div(1000).toString()
    },
    predictiveUnicryptTokenFeeHuman () {
      var amount = ethers.utils.formatUnits(this.predictiveUnicryptTokenFee, this.presaleTokenHydrated.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8})
    },
    predictiveBaseLiquidity () {
      return ethers.BigNumber.from(this.predictiveBaseRaised).sub(this.predictiveUnicryptBaseFee).mul(this.liquidityPercent * 10).div(1000).toString()
    },
    predictiveBaseLiquidityHuman () {
      var amount = ethers.utils.formatUnits(this.predictiveBaseLiquidity, this.baseTokenHydrated.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8})
    },
    predictiveTokenLiquidity () {
      if (this.tokensPerEth === '0') {
        return '0'
      }
      var decimalDenominator = ethers.BigNumber.from(10).pow(this.baseTokenHydrated.decimals)
      return ethers.BigNumber.from(this.predictiveBaseLiquidity).mul(this.listingRate).div(decimalDenominator).toString()
    },
    predictiveTokenLiquidityHuman () {
      if (!this.presaleTokenHydrated.address) {
        return '0'
      }
      var amount = ethers.utils.formatUnits(this.predictiveTokenLiquidity, this.presaleTokenHydrated.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8})
    },
    predictiveOwnerBaseHuman () {
      var amount = ethers.BigNumber.from(this.predictiveBaseRaised).sub(this.predictiveUnicryptBaseFee).sub(this.predictiveBaseLiquidity).toString()
      amount = ethers.utils.formatUnits(amount, this.baseTokenHydrated.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8})
    },
    predictiveTokensSold () {
      if (this.hardcap === '0') {
        return '0'
      }
      var amount = ethers.BigNumber.from(this.hardcap).sub(this.softcap).mul(this.presalePredictionPercent).div(100).toString()
      amount = ethers.BigNumber.from(amount).add(this.softcap).toString()
      return ethers.BigNumber.from(amount).mul(this.amount).div(this.hardcap).toString()
    },
    predictiveTokensSoldHuman () {
      var amount = ethers.utils.formatUnits(this.predictiveTokensSold, this.presaleTokenHydrated.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8})
    },
    referralAddressIsAddress () {
      try {
        ethers.utils.getAddress(this.referralAddress)
        return true
      } catch (e) {
        return false
      }
    },
    actualLiquidityPercent () {
      return this.liquidityPercent - (Number(this.presaleSettings.base_fee) / 10)
    },
    presaleBlockDuration () {
      var blockLength = Number(this.endBlock) - Number(this.startBlock)
      return blockLength
    },
    presaleDurationHuman () {
      var duration = this.presaleBlockDuration * this.$store.state.blocksPerSecond
      var mdur = moment.duration(duration * 1000)
      if (mdur.days() > 0) {
        return mdur.days() + ' days'
      }
      if (mdur.hours() > 0) {
        return mdur.hours() + ' hours'
      }
      if (mdur.minutes() > 0) {
        return mdur.minutes() + ' minutes'
      }
      return mdur.seconds() + ' seconds'
    },
    presaleMaxDurationHuman () {
      var duration = this.presaleSettings.max_presale_length * this.$store.state.blocksPerSecond
      var mdur = moment.duration(duration * 1000)
      if (mdur.days() > 0) {
        return mdur.days() + ' days'
      }
      if (mdur.hours() > 0) {
        return mdur.hours() + ' hours'
      }
      if (mdur.minutes() > 0) {
        return mdur.minutes() + ' minutes'
      }
      return mdur.seconds() + ' seconds'
    },
    // Errors
    eHardcapLessThanSoftcap () {
      return ethers.BigNumber.from(this.hardcap).lt(this.softcap)
    },
    eHardcapIsZero () {
      return !ethers.BigNumber.from(this.hardcap || 0).gt(0)
    },
    eSoftcapIsZero () {
      return !ethers.BigNumber.from(this.softcap || 0).gt(0)
    },
    eSoftcapLessThanRequiredPercentageOfHardcap () {
      var required = ethers.BigNumber.from(this.hardcap).div(4)
      return ethers.BigNumber.from(this.softcap).lt(required.toString())
    },
    eMaxSpendIsZero () {
      if (!this.maxSpend) {
        return true
      }
      return ethers.BigNumber.from(this.maxSpend).lte('0')
    },
    eStartEndExceedsMax () {
      var length = this.endBlock - this.startBlock
      return length > this.presaleSettings.max_presale_length
    },
    eEndBlockLessThanCurrent () {
      return Number(this.blockNumber) >= Number(this.endBlock)
    },
    eMinDivis () {
      return ethers.BigNumber.from(this.amount).lt('10000');
    },
    creationDisabled () {
      if (this.eMinDivis || this.eStartEndExceedsMax || this.eMaxSpendIsZero || this.eHardcapLessThanSoftcap || !this.userHasSufficientTokens || this.allowanceIncreaseRequired || this.eInsufficientEth) {
        return true
      }
      return false
    },
    eInsufficientEth () {
      // var feePlusGas = ethers.BigNumber.from(this.presaleSettings.eth_creation_fee).add('200000000000000000')
      return ethers.BigNumber.from(this.ethBalance).lt(this.presaleSettings.eth_creation_fee)
    }
  },

  watch: {
    sEthersWatcher () {
      this.refresh()
    },
    presaleTokenAddress (nv) {
      this.loadTokenInfo()
    },
    referralAddress (nv) {
      if (this.referralAddressIsAddress) {
        this.checkReferralAddress(nv)
      } else {
      }
    },
    tokensPerEth (nv) {
      this.setListingRatePercent(this.listingRatePercent)
    },
    idealAllocation (nv) {
      this.setIdealAllocation()
    },
    combinedTokensAddress () {
      this.checkIfPairExists()
    },
    chartDataComputed (nv) {
      var remain = ethers.BigNumber.from(this.presaleTokenHydrated.totalSupply).sub(this.amount).sub(this.amountLiquidityHardcap).sub(this.tokenFee).sub(this.TOKENOMICS.locked).sub(this.TOKENOMICS.burnt)
      if (remain.lt(0)) {
        remain = 0
      }

      var data = [this.amount, this.amountLiquidityHardcap, this.tokenFee, this.TOKENOMICS.locked, this.TOKENOMICS.burnt, remain]
      data = data.map(raw_amount => ethers.utils.formatUnits(raw_amount, this.presaleTokenHydrated.decimals))
      this.chartData.datasets[0].data = data
      // this.$refs.donutChart.update()
      this.$refs.donutChart.update()
      this.$refs.donutChartSmall.update()

      var supplyMinusBurn = ethers.BigNumber.from(this.totalSupply).sub(this.TOKENOMICS.burnt)
      var percent = ethers.BigNumber.from(this.amount).add(this.amountLiquidityHardcap).mul(100).div(supplyMinusBurn.toString())
      this.tokenomicsScore = Math.min(percent.toNumber(), 100)
    }
  },

  methods: {
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .then(() => {
        })
        .catch(e => {})
    },
    setIdealAllocation () {
      this.maxSpend = this.idealAllocation
      this.maxSpendHuman = ethers.utils.formatUnits(this.maxSpend, this.baseTokenHydrated.decimals)
    },
    async checkReferralAddress (address) {
      this.referralIsLoading = true
      PresaleSettingsContract.referrerIsValid(address)
        .then(isValid => {
          this.referrerIsValid = isValid
        })
        .catch(e => {})
        .then(() => {
          this.referralIsLoading = false
        })
    },
    openSelectBaseTokenDialog () {
      this.$refs.baseTokenDialog.open()
        .then(token => {
          this.baseTokenHydrated = token
        })
    },
    openSelectCountryDialog () {
      this.$refs.countryListDialog.open()
        .then(country => {
          this.selectedCountry = country
        })
    },
    async getChartInfo () {
      try {
        this.totalSupply = this.presaleTokenHydrated.totalSupply

        // tokenLocks
        var numLocks = await VestingPager.getTokenLocksLength(this.presaleTokenHydrated.address)
        var rows = []
        var page = 0;
        while (rows.length < numLocks) {
          var lock_ids = await VestingPager.getTokenLocks(this.presaleTokenHydrated.address, page * VESTABI.MAX_GETTER_LENGTH, VESTABI.MAX_GETTER_LENGTH)
          var locks = await VestingPager.getLocks(lock_ids)
          rows.push(...locks)
          page++
        }
        // total locked
        // var now = moment().unix()
        // var now = this.dateStartBlock.unix()
        var now = moment().add(6, 'days').unix()
        var unlockedLocks = []
        var lockedLocks = []
        var partialLocks = []
        rows.forEach(item => {
          if (item.start_emission !== 0) { // Linear locks
            if (item.start_emission > now && item.condition === ethers.constants.AddressZero) {
              lockedLocks.push(item)
            } else if (item.end_emission > now && item.condition === ethers.constants.AddressZero) {
              partialLocks.push(item)
            } else {
              unlockedLocks.push(item)
            }
          } else { // Cliff locks
            if (item.end_emission > now && item.condition === ethers.constants.AddressZero) {
              lockedLocks.push(item)
            } else {
              unlockedLocks.push(item)
            }
          }
        })
        var amountLocked = JSON.parse(JSON.stringify(lockedLocks)).reduce((a, item) => {
          return a.add(item.tokens_deposited).sub(item.tokens_withdrawn)
        }, ethers.BigNumber.from(0))
        var amountLockedPartially = JSON.parse(JSON.stringify(partialLocks)).reduce((a, item) => {
          var timeclamp = now
          if (timeclamp > item.end_emission) {
            timeclamp = item.end_emission;
          }
          if (timeclamp < item.start_emission) {
            timeclamp = item.start_emission
          }
          var remainingTime = item.end_emission - timeclamp
          var fullperiod = item.end_emission - item.start_emission
          var tokens = a.add(item.tokens_deposited).sub(item.tokens_withdrawn)
          var locked = tokens.mul(remainingTime).div(fullperiod)
          return locked
        }, ethers.BigNumber.from(0))
        amountLocked = amountLocked.add(amountLockedPartially)

        this.tokenLocks.locked = lockedLocks.sort((a, b) => a.end_emission - b.end_emission)
        this.tokenLocks.unlocked = unlockedLocks.sort((a, b) => a.end_emission - b.end_emission)
        this.tokenLocks.partial = partialLocks.sort((a, b) => a.end_emission - b.end_emission)

        var burnt = await ERC20.getBalance('0x000000000000000000000000000000000000dEaD', this.presaleTokenHydrated.address)

        this.TOKENOMICS.locked = amountLocked.toString()
        this.TOKENOMICS.burnt = burnt.toString()
      } catch (e) {
        console.log(e)
        // incase on testnets and token vesting is not connected
        // this prevents errors in the browser console that make no sense
      }
    },
    async loadTokenInfo () {
      try {
        ethers.utils.getAddress(this.presaleTokenAddress)
      } catch (e) {
        this.presaleTokenHydrated = {}
        return
      }
      this.loadingPresaleToken = true
      ERC20PAGER.getERC20(this.presaleTokenAddress)
        .then(token => {
          this.presaleTokenHydrated = token
          this.getChartInfo()
          this.getPresaleTokenBalance()
          this.getAllowance()
        })
        .catch(e => {})
        .then(() => {
          this.loadingPresaleToken = false
        })
    },
    async checkIfPairExists () {
      if (this.presaleTokenHydrated.address && this.baseTokenHydrated.address) {
        this.checkingIfPairExists = true
        UniswapFactoryContract.uniPairHasLiquidity(this.presaleTokenHydrated.address, this.baseTokenHydrated.address)
          .then(pairExists => {
            this.uniswapPairExists = pairExists
            // this.uniswapPairExists = false
          })
          .catch(e => {})
          .then(() => {
            this.checkingIfPairExists = false
          })
      }
    },
    async getPresaleTokenBalance () {
      var balance = await ERC20.getBalance(this.sEthers.coinbase, this.presaleTokenHydrated.address)
      this.presaleTokenBalance = balance
    },
    async getAllowance () {
      var allowance = await ERC20.getAllowance(this.presaleTokenHydrated.address, this.sEthers.coinbase, PresaleABI.presale_generator01_address())
      this.allowance = allowance
    },
    onPresaleTokenAmountChange (_val) {
      try {
        this.amount = ethers.utils.parseUnits(_val, this.presaleTokenHydrated.decimals).toString()
      } catch (e) {
        this.amount = '0'
      }
    },
    onHardcapAmountChange (val) {
      if (!val) {
        this.hardcap = '0'
        return
      }
      this.hardcap = ethers.utils.parseUnits(val, this.baseTokenHydrated.decimals).toString()
    },
    onSoftcapAmountChange (val) {
      if (!val) {
        this.softcap = '0'
        return
      }
      this.softcap = ethers.utils.parseUnits(val, this.baseTokenHydrated.decimals)
    },
    onLiquidityAmountChange (val) {
      this.amountLiquidityHardcap = ethers.utils.parseUnits(val, this.presaleTokenHydrated.decimals)
    },
    onMaxSpendAmountChange (val) {
      this.maxSpend = ethers.utils.parseUnits(val || '0', this.baseTokenHydrated.decimals)
    },
    setMax () {
      this.amount = this.presaleTokenBalance
      this.amountHuman = ethers.utils.formatUnits(this.presaleTokenBalance, this.presaleTokenHydrated.decimals)
    },
    setPercent (percent) {
      this.amount = ethers.BigNumber.from(this.presaleTokenBalance).mul(percent).div(100).toString()
      this.amountHuman = ethers.utils.formatUnits(this.amount, this.presaleTokenHydrated.decimals)
    },
    setListingRatePercent (percent) {
      this.listingRatePercent = percent
      var discount = ethers.BigNumber.from(this.tokensPerEth).mul(percent).div(1000).toString()
      this.listingRate = ethers.BigNumber.from(this.tokensPerEth).sub(discount).toString()
      this.listingRateHuman = ethers.utils.formatUnits(this.listingRate, this.presaleTokenHydrated.decimals)
    },
    setLiquidityPercent (percent) {
      this.amountLiquidityHardcap = ethers.BigNumber.from(this.presaleTokenBalance).mul(percent).div(100).toString()
      this.amountLiquidityHardcapHuman = ethers.utils.formatUnits(this.amountLiquidityHardcap, this.presaleTokenHydrated.decimals)
    },
    approve () {
      var amount = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      this.approvalLoading = true
      var confirmations = this.$settings.CHAINS[this.$store.state.requiredNetwork].confirmations
      TxWrapper.doTransaction(ERC20.setAllowance(this.presaleTokenHydrated.address, amount, PresaleABI.presale_generator01_address()), this.$root.$dialog.confirmTx, confirmations)
        .then(() => { 
          this.getAllowance()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          this.approvalLoading = false
        })
    },
    async createPresale () {
      this.createLoading = true
      var amount = this.amount
      var lockPeriod = 60 * 60 * 24 * 31
      if (this.lockPeriod === '2 months') {
        lockPeriod = 60 * 60 * 24 * 62
      } else if (this.lockPeriod === '3 months') {
        lockPeriod = 60 * 60 * 24 * 93
      } else if (this.lockPeriod === '6 months') {
        lockPeriod = 60 * 60 * 24 * 184
      } else if (this.lockPeriod === '1 year') {
        lockPeriod = 60 * 60 * 24 * 365
      } else if (this.lockPeriod === 'Max: 266 years') {
        lockPeriod = 9999999999
      }
      
      var referral = this.hasReferralAddress ? this.referralAddress : ethers.constants.AddressZero
      var confirmations = this.$settings.CHAINS[this.$store.state.requiredNetwork].confirmations
      TxWrapper.doTransaction(PresaleGeneratorContract.createPresale(this.sEthers.coinbase, this.presaleTokenHydrated.address, this.baseTokenHydrated.address, referral, amount, this.tokensPerEth, this.maxSpend, this.hardcap, this.softcap, this.liquidityPercent * 10, this.listingRate, this.round0Start, this.startBlock, this.endBlock, lockPeriod, this.selectedCountry.id, this.implementVesting, this.presaleSettings.eth_creation_fee), this.$root.$dialog.confirmTx, confirmations)
        .then(response => {
          var eventIndex = this.$store.state.requiredNetwork === 'Matic' ? response.receipt.events.length - 2 : response.receipt.events.length - 1
          var creationEvent = response.receipt.events[eventIndex]
          var decodedArray = ethers.utils.defaultAbiCoder.decode(["address"], creationEvent.data)
          // console.log(creationEvent.data)
          // console.log(decodedArray[0])

          this.$axios.post(`/presales/sync-latest`)
            .then(() => {})
            .catch(e => {})
          // this.getUniBalance()
          // this.$emit('on-lock', amount)
          this.$root.ammLink(`/ilo/${decodedArray[0]}/edit`)
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          this.createLoading = false
        })
    },

    async getPresaleSettings () {
      var presaleSettings = await PresaleSettingsContract.getSettings()
      this.presaleSettings = presaleSettings
    },

    async getUserEthBalance () {
      var ethBalance = await this.sEthers.provider.getBalance(this.sEthers.coinbase)
      this.ethBalance = ethBalance
    },

    async refresh () {
      this.getPresaleSettings()
      this.loadTokenInfo()
      this.getUserEthBalance()
    }
  },

  created () {
    var baseTokens = {
      'Mainnet': {
        name: 'Ethereum',
        symbol: 'ETH',
        address: UniswapABI.weth9_address(),
        decimals: 18
      },
      'Kovan': {
        name: 'Ethereum',
        symbol: 'ETH',
        address: UniswapABI.weth9_address(),
        decimals: 18
      },
      'Ganache': {
        name: 'Ethereum',
        symbol: 'ETH',
        address: '0x8045Bf666404bB03AdEe2B3f633feA5A6086F0bc',
        decimals: 18
      },
      'xDai': {
        name: 'xDai',
        symbol: 'xDai',
        address: '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d',
        decimals: 18
      },
      'BSC_MAINNET': {
        name: 'Wrapped BNB',
        symbol: 'WBNB',
        address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        decimals: 18
      },
      'Matic': {
        name: 'Wrapped Matic',
        symbol: 'WMATIC',
        address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
        decimals: 18
      },
      'AVAX': {
        name: 'Avax',
        symbol: 'Avax',
        address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
        decimals: 18
      },
    }
    this.baseTokenHydrated = baseTokens[this.$store.state.requiredNetwork]
    this.refresh()

    // this is placed here to be called only ONCE, otherwise it doesnt work. e.g. 300 becomes 300e18 on updating chart data
    this.chartOptions.tooltips.callbacks.label = (tooltipItem, data) => {
      var amount = data.datasets[0].data[tooltipItem.index]
      amount = ethers.utils.formatUnits(amount, this.presaleTokenHydrated.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    }
  }
}
</script>