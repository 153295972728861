<template>
  <span>
    <v-btn
    v-if="wrongNetwork"
    @click="connectWallet"
    small
    color="red"
    :class="['font-weight-medium white--text']"
    rounded depressed
    >
      <v-icon color="white">mdi-pulse</v-icon>
      Wrong network
      <!--
        <div class="caption ml-1">
        Switch your wallet to {{ $store.state.requiredNetwork }}
      </div>
      -->
    </v-btn>

    <v-btn
    v-else
    @click="connectWallet"
    class="midground"
    rounded depressed
    >   
      <img 
      :src="$settings.CHAINS[$store.state.requiredNetwork].icon" 
      height="20px"
      width="20px"
      class="br-20">
      <span class="ml-1"/>
      <span v-if="!sEthers.coinbase">Connect</span>
      <span v-else>{{ $root.condenseAddress(sEthers.coinbase) }}</span>

      <v-progress-circular
      v-if="pendingTx.length > 0"
      indeterminate
      width="2"
      size="24"
      color="primary"
      class="ml-2"
      >
        <span class="caption">
          {{ pendingTxLengthHuman }}
        </span>
      </v-progress-circular>
    </v-btn>
  </span>
</template>

<script>

export default {
  data: () => ({
  }),
  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    pendingTx () {
      return this.$store.state.pendingTx.pending
    },
    pendingTxLengthHuman () {
      if (this.pendingTx.length > 9) {
        return '>9'
      }
      return this.pendingTx.length
    },
    wrongNetwork () {
      return this.$store.state.wrongNetwork
    },
  },

  watch: {
  },

  methods: {
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .catch(e => {})
    },
  }
}
</script>