<template>
  <div class="pa-4">

    <div class="text-center pt-2">
      <div class="textFaint--text caption">
        Selected network
      </div>
      <v-btn large block text @click="$root.$dialog.chainSwitcher.open()" class="title font-weight-bold">
        <img 
        :src="$settings.CHAINS[$store.state.requiredNetwork].icon" 
        height="24px"
        width="24px"
        class="mr-3 br-20">
        {{ $settings.CHAIN_DISPLAY_NAME[$store.state.requiredNetwork] }}
        <v-icon small color="">mdi-chevron-down</v-icon>
      </v-btn>
    </div>
    
    <div class="title">
      Ecosystem fees
    </div>

    <div class="v-card pa-4 br-8">
      <div class="mt-4 textFaint--text font-weight-bold">
        User Address
      </div>
      <div class="mt-2 pa-2 br-8 inputcolor mb-4">
        <div class="d-flex align-center py-2">
          <template v-if="!userAddressIsValid">
            <v-icon small color="red" class="mr-1">mdi-alert-outline</v-icon>
          </template>
          <template v-else>
            <v-icon small color="primary" class="mr-1">mdi-check-circle-outline</v-icon>
          </template>
          <c-input :value.sync="user_address" placeholder="0x...">
          </c-input>
        </div>
      </div>

      <div class="mt-4 textFaint--text font-weight-bold">
        Token
      </div>
      <load-token @on-update="updateToken"></load-token>

      <div class="py-2 text-end">
        <v-btn color="lgrad-green" class="white--text" @click="addTokenAdminToWhitelist">
          Add
        </v-btn>
      </div>
    </div>

    <div class="v-card pa-4 br-8 mt-4">
      <v-row dense class="ma-0 py-3 align-end">
        <v-col cols="5">
          <div class="textFaint--text caption font-weight-bold">
            Token
          </div>
          <div class="inputcolor pa-2 br-8">
            <c-input :value.sync="check.token" placeholder="0x...">
            </c-input>
          </div>
        </v-col>
        <v-col cols="5">
          <div class="textFaint--text caption font-weight-bold">
            User
          </div>
          <div class="inputcolor pa-2 br-8">
            <c-input :value.sync="check.user" placeholder="0x...">
            </c-input>
          </div>
        </v-col>
        <v-col cols="2">
          <v-btn small color="lgrad-green" @click="checkFee">
            Check
          </v-btn>
        </v-col>
      </v-row>

      <div v-if="check.valid !== -1" class="text-center font-weight-bold primary--text">
        Charge fee: 
        {{ check.valid }}
      </div>
    </div>

    <div class="pa-2 d-flex align-center">
      Tokens {{ num_tokens }}
      <v-spacer></v-spacer>
      <v-btn icon @click="refresh">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </div>

    <token-row v-for="tokenAddress of tokens" :key="tokenAddress" :address="tokenAddress">
    </token-row>

  </div>
</template>

<script>
import { ethers } from 'ethers'
import EcosystemWhitelistContract from '@/smart-contracts/ecosystem-whitelist/ecosystem-whitelist'
import TxWrapper from '@/smart-contracts/tx-wrapper'
import TokenRow from './token-row.vue'

export default {
  components: {
    TokenRow
  },

  data: () => ({
    token_hydrated: {},
    user_address: '',
    num_tokens: 0,
    tokens: [],

    check: {
      token: '',
      user: '',
      valid: -1 // -1, false, true
    }
  }),

  computed: {
    userAddressIsValid () {
      try {
        ethers.utils.getAddress(this.user_address)
        return true
      } catch (e) {
        return false
      }
    }
  },

  methods: {
    updateToken (token) {
      this.token_hydrated = token;
    },
    async addTokenAdminToWhitelist () {
      TxWrapper.doTransaction(EcosystemWhitelistContract.addTokenAdminToWhitelist(this.token_hydrated.address, this.user_address), this.$root.$dialog.confirmTx, 1)
        .then(response => {
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
        })
    },
    async checkFee () {
      var response = await EcosystemWhitelistContract.chargeFee(this.check.token, this.check.user)
      this.check.valid = response
    },
    async refresh () {
      this.tokens = []
      this.num_tokens = await EcosystemWhitelistContract.getNumTokens()
      var tokens = await EcosystemWhitelistContract.getPagedTokens(0, this.num_tokens)
      this.tokens = tokens
    }
  },

  created () {
    this.refresh()
  }
}
</script>