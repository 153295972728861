<template>
  <div :class="['py-2', {'px-2': $vuetify.breakpoint.mobile}]">
    <div class="text-center title">
      Recent Top performing ILO's
    </div>
    <v-row dense class="ma-0">
      <v-col cols="12" md="12"
      class="py-0"
      v-for="presale of presales['Pancakeswap V2']" 
      :key="presale.presale_contract">
        <presale-row
        @click.native="goToPresale(presale)"
        :exchange="presale.exchange"
        :presale="presale"
        >
        </presale-row>
      </v-col>
    </v-row>
    <div class="">
      <presale-row
      @click.native="goToPresale(presale)"
      v-for="presale of presales['Uniswap V2']" 
      :key="presale.presale_contract"
      :exchange="presale.exchange"
      :presale="presale"
      >
      </presale-row>
    </div>
  </div>
</template>

<script>
import SETTINGS from '@/store/settings'
import axios from 'axios'
import PresaleRow from '@/views/presale/presales/row-switcher'

export default {
  props: {
  },

  components: {
    PresaleRow
  },

  data: () => ({
    presales: {
      'Uniswap V2': [],
      'Honeyswap V1': [],
      'Pancakeswap V1': [],
      'Pancakeswap V2': [],
      'Julswap V1': [],
      'Sushiswap V1': [],
      'Quickswap V1': [],
    },
  }),

  computed: {
  },

  methods: {
    async getPresales (exchange) {
      var page = 0
      var filters = {
        // sort: 'profit',
        sort: 'start_block',
        sortAscending: false,
        search: '',
        hide_flagged: true
      }
      var response = await axios.post(`${SETTINGS.AMMS[exchange].server}/presales/search`, {filters: filters, page: page, stage: 2, rows_per_page: 10})
      var presales = response.data.rows
      presales.sort((a, b) => b.profit - a.profit)
      presales = presales.filter(item => item.profit > 0)
      presales.map(item => {
        item.exchange = exchange
      })
      this.presales[exchange] = presales
    },
    goToPresale (presale) {
      // this.$store.commit('switchExchange', presale.exchange)
      this.$root.ammLinkTwo(`/ilo/${presale.presale_contract}`, presale.exchange)
    },
  },

  created () {
    this.getPresales('Pancakeswap V2')
    // this.getPresales('Uniswap V2')
  }
}

</script>
