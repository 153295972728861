<template>

  <div class="v-card br-20 pa-6">
    <div>
      <div>

        <div class="">
          Top up
        </div>

        <div class="py-3 textFaint--text">
          Extend the end block of this farm by adding more tokens. 
          New endblock = (tokensDeposited / blockReward) + currentEndblock
        </div>

        <div class="pa-4 align-center flex-nowrap inputcolor r-outlined">

          <div class="caption text-end pt-2">
            Balance: {{ userBalanceHuman }}
          </div>

          <div class="d-flex align-center">
            <c-input :value.sync="amountTopUpHuman" placeholder="0.0" @updateWhileFocussed="onAmountChanged" class="pa-2 font-weight-bold">

            </c-input>

            <v-btn small rounded outlined @click.native="$root.tokenPage.open(reward_pool.reward_token.address, $store.state.requiredNetwork)" style="min-width: 10px;">
              <span :class="['text-truncate']" :style="$vuetify.breakpoint.xs ? 'max-width: 14ch;' : 'max-width: 20ch;'">
                {{ reward_pool.reward_token.symbol }}
              </span>
            </v-btn>

            <v-btn rounded small depressed color="lgrad-blue" @click="setMax" class="ml-1 white--text">
              MAX
            </v-btn>
          </div>

        </div>

        <v-row dense class="ma-0 mt-4 br-c" style="overflow: hidden;">
          <v-col cols="6" class="pa-0">
            <v-btn class="br-0 white--text" @click="approve" color="lgrad-blue" x-large block depressed :disabled="!allowanceIncreaseRequiredUNCL" :loading="approveLoading">
              Approve
            </v-btn>
          </v-col>
          <v-col cols="6" class="pa-0">
            <v-btn class="br-0 white--text" @click="topUp" color="lgrad-blue" x-large block depressed :disabled="topUpDisabled" :loading="topUpLoading">
              Top up
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>

  </div>

</template>

<script>
import { ethers } from 'ethers'
import RewardPoolContract from '@/smart-contracts/farms-v2/normal/reward-pool/v1/reward-pool'
import ERC20BALANCE from '@/smart-contracts/erc20'
import TxWrapper from '@/smart-contracts/tx-wrapper'

export default {

  props: {
    address: null,
    reward_pool: {
      type: Object,
      default: () => {
        return {
          reward_token: {}
        }
      }
    }
  },

  components: {
  },

  data: () => ({
    allowance: '0',
    amountTopUp: '0',
    amountTopUpHuman: '0',
    approveLoading: false,
    topUpLoading: false,
    userBalance: '0'
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    userBalanceHuman () {
      var amount = ethers.utils.formatUnits(this.userBalance, this.reward_pool.reward_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8})
    },
    allowanceIncreaseRequiredUNCL () {
      if (ethers.BigNumber.from(this.amountTopUp).gt(this.allowance)) {
        return true
      }
      return false
    },
    topUpDisabled () {
      if (this.allowanceIncreaseRequiredUNCL) {
        return true
      }
      return false
    },
  },

  watch: {
    sEthersWatcher () {
      this.refresh()
    },
  },

  methods: {
    async refresh () {
      this.getUserBalance()
      this.getAllowance()
    },
    async getAllowance () {
      var allowance = await ERC20BALANCE.getAllowance(this.reward_pool.reward_token.address, this.sEthers.coinbase, this.address)
      this.allowance = allowance
    },
    setMax () {
      this.amountTopUp = this.userBalance
      this.amountTopUpHuman = ethers.utils.formatUnits(this.amountTopUp, this.reward_pool.reward_token.decimals)
    },
    onAmountChanged (_val) {
      try {
        this.amountTopUp = ethers.utils.parseUnits(_val, this.reward_pool.reward_token.decimals).toString()
      } catch (e) {
        this.amountTopUp = '0'
      }
    },
    async getUserBalance () {
      this.userBalance = await ERC20BALANCE.getBalance(this.sEthers.coinbase, this.reward_pool.reward_token.address)
    },
    async topUp () {
      TxWrapper.doTransaction(RewardPoolContract.topUp(this.address, this.amountTopUp), this.$root.$dialog.confirmTx, 1)
        .then(response => {
          this.refresh()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
        })
    },
    approve () {
      var amount = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      this.approveLoading = true
      TxWrapper.doTransaction(ERC20BALANCE.setAllowance(this.reward_pool.reward_token.address, amount, this.address), this.$root.$dialog.confirmTx, 1)
        .then(() => { 
          this.getAllowance()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          this.approveLoading = false
        })
    },
  },

  created () {
    this.refresh()
  }
}
</script>