<template>
  <div>
    <div class="px-2 title">
      Recent Gaming, NFT, and other Launches
    </div>
    <div class="px-2 mb-2 caption primary--text">
      Profit shown as ATH
    </div>

    <v-row dense class="ma-0">
      <v-col cols="6" md="3">
        <v-card class="c-list pa-4 br-8 text-center" href="https://babydoge.com" target="_blank" style="height: 100%;">
          <coin-icon url="https://cdn.shopify.com/s/files/1/0388/4833/1916/files/baby_doge_200.png" :size="$vuetify.breakpoint.xs ? 56 : 66"></coin-icon>
          <div class="">
            BABY DOGE
          </div>
          <div class="primary--text font-weight-bold">
            16,520X
          </div>
        </v-card>
      </v-col>

      <!--
      <v-col cols="6" md="3">
        <v-card class="c-list pa-4 br-8 text-center" href="https://graphlinq.io" target="_blank" style="height: 100%;">
          <coin-icon url="https://i.imgur.com/l7VLtZw.png" :size="$vuetify.breakpoint.xs ? 56 : 66"></coin-icon>
          <div class="">
            GRAPH LINQ
          </div>
          <div class="primary--text font-weight-bold">
            29X
          </div>
        </v-card>
      </v-col>
      -->

      <v-col cols="6" md="3">
        <v-card class="c-list pa-4 br-8 text-center" href="https://cultdao.io/" target="_blank" style="height: 100%;">
          <coin-icon url="https://i.imgur.com/dE7x5Dx.png" :size="$vuetify.breakpoint.xs ? 56 : 66"></coin-icon>
          <div class="">
            CULT
          </div>
          <div class="primary--text font-weight-bold">
            315X
          </div>
        </v-card>
      </v-col>

      <v-col cols="6" md="3">
        <v-card class="c-list pa-4 br-8 text-center" href="https://v-empire.io" target="_blank" style="height: 100%;">
          <coin-icon url="https://i.imgur.com/Ay5nuny.png" :size="$vuetify.breakpoint.xs ? 56 : 66"></coin-icon>
          <div class="">
            vEMPIRE
          </div>
          <div class="primary--text font-weight-bold">
            19X
          </div>
        </v-card>
      </v-col>

      <v-col cols="6" md="3">
        <v-card class="c-list pa-4 br-8 text-center" href="https://quantumworks.co.uk" target="_blank" style="height: 100%;">
          <coin-icon url="https://i.imgur.com/mDs97vC.png" :size="$vuetify.breakpoint.xs ? 56 : 66"></coin-icon>
          <div class="">
            Project Quantum
          </div>
          <div class="primary--text font-weight-bold">
            105X
          </div>
        </v-card>
      </v-col>
    </v-row>
    <!--
    <v-row dense class="ma-0">
      <ilo-pinned presale="0xE3E59f96cDF5Fe7417836a6458AbD5533dd4f807" exchange="Pancakeswap V2"></ilo-pinned>
      <ilo-pinned presale="0xe5efebD1Dd0e6699eFa33aBb775f8689aA09C3AD" exchange="Pancakeswap V2"></ilo-pinned>
      <ilo-pinned presale="0x9b0f2Ab1Fe5C40f0fb1a06b0BC3C8dDF2b3297BD" exchange="Pancakeswap V2"></ilo-pinned>
      <ilo-pinned presale="0xD8100066b8c58E8B90fA7F4c3BFc90116deB16b0" exchange="Pancakeswap V2"></ilo-pinned>
      <ilo-pinned presale="0x0EB207B525Dc856C3BAD5bfD7a7A4Aae781E1757" exchange="Pancakeswap V2"></ilo-pinned>
      <ilo-pinned presale="0xF4f759a7Ee12a1DBd9d6d56bd14d5e22ec9348e6" exchange="Uniswap V2"></ilo-pinned>
    </v-row>
    -->
  </div>
</template>

<script>

export default {
  components: {
  },
}
</script>