<template>
  <div :class="['cursor-pointer d-flex align-center']" style="flex: 1 0 0;">

    <div style="flex: 1 0 0;">
      <div v-if="firstLoad" class="textFaint--text d-flex justify-center align-center">
        <v-progress-circular
        indeterminate
        size="18"
        width="1"
        style="position: absolute;top: 20px; right: 20px;"
        color="textFaint">
        </v-progress-circular>
        <div class="text-center">
          <coin-icon :url="presale.icon_url" :size="76" class=""></coin-icon>
          <div class="title text--text font-weight-medium">
            {{ presale.s_token.name }}
          </div>
          <div v-if="!sEthers.coinbase" class="textFaint--text caption font-italic">
            Connect your wallet for faster loading times
          </div>
        </div>
      </div>

      <div v-else style="overflow: hidden;">

        <template v-if="presaleStage === 5 && $store.state.superUserMode && false">
          <v-row dense class="midground align-center pl-3 pt-1">
            <v-col cols="4">
              <div class="textFaint--text caption">
                {{ $root.formatAmount(presale_info.token_price, presale_info.token.decimals) }} {{ presale_info.token.symbol }}
              </div>
              <div class="textFaint--text caption font-italic">per {{ baseTokenSymbol }}</div>
            </v-col>
            <v-col cols="4" class="caption text-center">
              <div class="textFaint--text">
                {{ $root.formatAmount(current_price, presale_info.token.decimals) }} {{ presale_info.token.symbol }}
              </div>
              <div class="textFaint--text caption font-italic">per {{ baseTokenSymbol }}</div>
            </v-col>
            <v-col cols="4" class="text-end">
              <span :class="[price_diff >= 0 ? 'primary--text' : 'red--text', 'ml-1']">{{ price_diff }}%</span>
              <v-btn @click.stop="getPriceDiff" icon color="textFaint">
                <v-icon small>mdi-refresh</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <!-- NEW -->
        <v-carousel hide-delimiters show-arrows-on-hover height="200px;" class="carousel-override">
          
          <v-carousel-item class="fill-height pa-4">
            <div class="d-flex align-center" style="height: 100%;">
              <div class="flex">
                <!-- PROFIT AND LOSS -->
                <div v-if="presaleStage === 5" class="d-flex br-20">
                  <div class="br-20 midground">
                    <v-menu
                    v-model="chartPopup"
                    offset-y
                    open-on-hover
                    content-class="br-20 pa-0"
                    >
                      <template v-slot:activator="{ attrs, on }">
                        <v-btn
                          text
                          small
                          rounded
                          v-bind="attrs"
                          v-on="on"
                          color="textFaint"
                          class="font-weight-regular"
                          @click.stop="chartPopup = true"
                        >
                          chart
                          <v-icon small>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>

                      <v-list style="min-width: 250px;" class="foreground br-20 pa-0">
                        <a :href="dextLink" target="_blank" class="deadlink">
                          <div class="d-flex align-center c-list px-4 py-3 border-b">
                            <img 
                            src="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xfB7B4564402E5500dB5bB6d63Ae671302777C75a/logo.png"
                            height="20px"
                            width="20px">
                            <div>
                              <div class="ml-4 textFaint--text caption">
                                View on Dext
                                <v-icon x-small>mdi-arrow-top-right</v-icon>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a v-if="cExchange === 'Pancakeswap V2'" :href="`https://poocoin.app/tokens/${presale.s_token.address}`" target="_blank" class="deadlink">
                          <div class="d-flex align-center c-list px-4 py-3 border-b">
                            <img 
                            src="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/0xB27ADAfFB9fEa1801459a1a81B17218288c097cc/logo.png"
                            height="20px"
                            width="20px">
                            <div>
                              <div class="ml-4 textFaint--text caption">
                                View on Poocoin
                                <v-icon x-small>mdi-arrow-top-right</v-icon>
                              </div>
                            </div>
                          </div>
                        </a>
                      </v-list>
                    </v-menu>
                    <v-btn @click.stop="getPriceDiff" text rounded small class="align-center">
                      <span :class="[price_diff >= 0 ? 'primary--text' : 'red--text', 'caption font-weight-medium']">{{ price_diff }}%</span>
                      <v-icon v-if="!refreshPriceLoading" color="textFaint" small style="margin: 7px;">mdi-refresh</v-icon>
                      <div v-else style="height: 20px; width: 30px;" class="text-center">
                        <v-progress-circular
                        indeterminate
                        size="14"
                        width="1.5"
                        color="textFaint">
                        </v-progress-circular>
                      </div>
                    </v-btn>
                  </div>
                </div>
                <!-- PROFIT AND LOSS -->

                <div class="d-flex align-center">
                  <coin-icon :url="presale.icon_url" :size="$vuetify.breakpoint.xs ? 56 : 76" class="mr-3"></coin-icon>
                  <div style="flex:1;">

                    <div class="d-flex">
                      <v-btn text @click.stop="openTokenPage" class="title pa-0" style="min-width: 10px;">
                        <span :class="['text-truncate', {'red--text': tokenomicsIsRed}]" :style="$vuetify.breakpoint.xs ? 'max-width: 14ch;' : 'max-width: 20ch;'">
                          {{ presale.s_token.name }}
                        </span>
                        <v-icon small :color="textColor">mdi-chevron-up</v-icon>
                      </v-btn>

                      <v-spacer></v-spacer>

                      <v-btn 
                      v-if="$store.state.superUserMode && $store.state.user.jwtObject.user_id === '21'" 
                      small color="textFaint" text 
                      :href="`${$store.state.etherscan_url}/address/${presale_info.presale_contract}`" 
                      target="_blank"
                      @click.stop>
                        {{ $store.state.explorer_name }}
                        <v-icon x-small>mdi-arrow-top-right</v-icon>
                      </v-btn>

                      <div style="width: 30px;">
                        <donut-chart :chartdata="chartData" :options="chartOptions" :red="tokenomicsIsRed" ref="donutChart1" />
                      </div>

                    </div>

                    <div v-if="presale.stage === 0" :class="`caption font-weight-regular ${textColor}--text`">
                      <template v-if="!firstLoad">
                        Starts {{ dateStartBlockHuman }} your time
                      </template>
                      <span v-else class="font-weight-medium textFaint--text">
                        ...loading
                      </span>
                    </div>

                    <!-- BADGES -->
                    <div class="d-flex align-center flex-wrap">

                      <template v-if="tokenHydrated.address">

                        <div class="d-flex align-center font-weight-medium pr-2 br-8" style="position: relative; overflow: hidden;">
                          <v-icon size="16" color="text" class="mr-1">mdi-lock</v-icon>
                          <span class="text--text">
                            {{ presale_info.liquidity_percent / 10 }}%
                          </span>
                        </div>

                        <div v-if="presale.hot" class="caption d-flex align-center font-weight-medium primary white--text px-2 br-20">
                          <v-icon v-if="false" size="16" color="white" class="mx-1">mdi-rocket</v-icon>
                          INCUBATED
                        </div>

                        <div v-if="tokenomicsScore < 70 || tokenomicsIsRed" class="caption d-flex align-center font-weight-medium red white--text pr-2 br-20">
                          <v-icon size="16" color="white" class="mx-1">mdi-chart-pie</v-icon>
                          <span>
                            {{ tokenomicsScore }}%
                          </span>
                        </div>

                        <div v-else class="caption d-flex align-center font-weight-medium lgrad-blue white--text pr-2 br-20">
                          <v-icon size="16" color="white" class="mx-1">mdi-chart-pie</v-icon>
                          <span>
                            {{ tokenomicsScore }}%
                          </span>
                        </div>

                        <div v-if="tokenHydrated.isENMT" class="caption d-flex align-center font-weight-medium lgrad-blue white--text pr-2 br-20">
                          <v-icon size="16" color="white" class="mx-1">mdi-leaf</v-icon>
                          <span>
                            ENMT
                          </span>
                        </div>

                        <div v-if="presale.audit_rating === 2" class="caption d-flex align-center font-weight-medium red white--text pr-2 br-20">
                          <v-icon size="16" color="white" class="mx-1">mdi-file-document-outline</v-icon>
                          <span>
                            Flagged
                          </span>
                        </div>

                        <div v-else-if="presale.audit_rating === 1" class="caption d-flex align-center font-weight-medium orange white--text pr-2 br-20">
                          <v-icon size="16" color="white" class="mx-1">mdi-file-document-outline</v-icon>
                          Audit
                        </div>

                        <div v-else-if="presale.audit_rating === 0" class="caption d-flex align-center font-weight-medium lgrad-blue white--text pr-2 br-20">
                          <v-icon size="16" color="white" class="mx-1">mdi-file-document-outline</v-icon>
                          <span>
                            Audit
                          </span>
                        </div>

                        <div v-else-if="!tokenHydrated.isENMT" class="caption d-flex align-center font-weight-medium red white--text pr-2 br-20">
                          <v-icon size="16" color="white" class="mx-1">mdi-file-document-outline</v-icon>
                          <span>
                            Audit
                          </span>
                        </div>
                      </template>

                      <div v-if="presale.kyc_rating === 0" class="caption d-flex align-center font-weight-medium lgrad-blue white--text pr-2 br-20">
                        <v-icon size="16" color="white" class="mx-1">mdi-magnify</v-icon>
                        <span>
                          KYC
                        </span>
                      </div>

                      <div v-else class="caption d-flex align-center font-weight-medium red white--text pr-2 br-20">
                        <v-icon size="16" color="white" class="mx-1">mdi-magnify</v-icon>
                        <span>
                          KYC
                        </span>
                      </div>
                      
                      <v-btn 
                      v-if="$store.state.superUserMode && $store.state.user.jwtObject.user_id === '21'"
                      @click.stop="togglePresaleHot"
                      x-small rounded outlined
                      color="text">
                        <v-icon small>mdi-fire</v-icon>
                      </v-btn>

                    </div>
                    <!-- BADGES -->

                    <!-- ROW 2 -->
                    <div class="d-flex mt-2">
                      <div class="caption font-weight-medium textFaint--text" style="position: relative; overflow: hidden;">
                        <span v-if="presaleStage === 0">
                          Duration {{ presaleDurationHuman }}
                        </span>
                        <span v-else-if="presaleStage === 1 || presaleStage === 2">
                          Ends {{ timeToEndBlock }}
                        </span>
                        <span v-else>
                          Presale ended
                        </span>
                      </div>

                      <v-spacer></v-spacer>

                      <!-- PRESALE STAGE -->
                      <template>
                        <div class="caption font-weight-medium px-2 textFaint--text" style="position: relative; overflow: hidden;">
                          <span>{{ presaleStageString }}</span>
                          &nbsp;
                          <span v-if="presaleStage === 0" class="text-uppercasee">{{ timeToStartBlock }}</span>
                        </div>
                      </template>
                      <!-- PRESALE STAGE -->
                    </div>
                    <!-- ROW 2 -->

                    <v-progress-linear height="22" :value="presaleProgress" 
                    :color="tokenomicsIsRed ? 'lgrad-red' : presale.hot ? 'primary' : 'lgrad-blue'"
                    background-color="background"
                    :class="['caption font-weight-bold br-20', {'text--text': presaleProgress > 50}, {'text--text': presaleProgress <= 50}]">
                      <div class="d-flex flex px-3 align-center">
                        <div class="">
                          {{ totalBaseCollectedHuman }}
                        </div>
                        <div :class="`px-1`">
                          /
                        </div>
                        <div :class="`d-flex align-center`">
                          {{ hardcapHuman }} {{ baseTokenSymbol }}
                        </div>
                        <v-spacer></v-spacer>
                        <span v-if="$store.state.superUserMode" class="mr-2 textFaint--text">v5</span>
                        <div class="">
                          {{ userMaxLimitHuman }} {{ baseTokenSymbol }}
                        </div>
                      </div>
                    </v-progress-linear>

                    <!-- ROW 1 -->
                    <v-row dense class="ma-0 pa-0 align-center">
                      <v-col cols="6" class="d-flex align-center pa-0">
                        <img 
                        v-if="false"
                        src="@/assets/img/UNCL.svg" 
                        height="20px"
                        width="20px">

                        <v-progress-linear height="22" :value="unclProgress" 
                        :color="tokenomicsIsRed ? 'lgrad-red' : presale.hot ? 'primary' : 'lgrad-blue'" :class="['br-20 caption font-weight-bold', {'text--text': unclProgress > 50}, {'text--text': unclProgress <= 50}]"
                        background-color="background">
                          <span class="">{{ presale_info.uncl_participants }}</span>/{{ presale_info.uncl_max_participants }}
                        </v-progress-linear>
                      </v-col>
                      <v-col cols="6" class="d-flex caption justify-end">
                        <div :class="`d-flex align-center font-weight-medium ${textColor}--text mr-6`">
                          <DisqusCount shortname='unicrypt-network' :identifier="netIdentifier" />
                          <v-icon size="12" :color="textColor" class="ml-1">mdi-comment</v-icon>
                        </div>
                        <div :class="`d-flex align-center font-weight-medium ${textColor}--text`">
                          {{ presale_info.num_buyers }}
                          <v-icon size="12" :color="textColor" class="ml-1">mdi-account</v-icon>
                        </div>
                      </v-col>
                    </v-row>

                    <!-- END ROW 1 -->

                  </div>
                </div>
              </div>
            </div>
          </v-carousel-item>

          <v-carousel-item class="fill-height">
            <div class="text-center d-flex align-center justify-center border-b pa-2">
              <coin-icon :url="presale.icon_url" :size="30" class="mr-3"></coin-icon>
              {{ presale.s_token.name}} Social links
            </div>
            <div class="d-flex align-start textFaint--text px-4" style="word-break: break-all;">
              <a v-if="presale.twitter_url" @click.stop :href="presale.twitter_url" target="_blank" class="deadlink d-flex flex-column justify-center text-center pa-2 hoverlink" style="flex: 1 0 0;">
                <v-icon size="50">mdi-twitter</v-icon>
                <div class="caption font-italic">
                  {{ presale.twitter_url }}
                </div>
              </a>
              <a v-if="presale.telegram_url" @click.stop :href="presale.telegram_url" target="_blank" class="deadlink d-flex flex-column justify-center text-center pa-2 hoverlink" style="flex: 1 0 0;">
                <v-icon size="50">mdi-telegram</v-icon>
                <div class="caption textFaint--text font-italic">
                  {{ presale.telegram_url }}
                </div>
              </a>
              <a v-if="presale.website_url" @click.stop :href="presale.website_url" target="_blank" class="deadlink d-flex flex-column justify-center text-center pa-2 hoverlink" style="flex: 1 0 0;">
                <v-icon size="50">mdi-web</v-icon>
                <div class="caption textFaint--text font-italic">
                  {{ presale.website_url }}
                </div>
              </a>
            </div>
          </v-carousel-item>

          <v-carousel-item v-if="false">
            <div style="width: 80px;">
              <donut-chart :chartdata="chartData" :options="chartOptions" ref="donutChart" />
            </div>
          </v-carousel-item>
        </v-carousel>
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import { ethers } from 'ethers'
import PresaleContract from '@/smart-contracts/presales/v4/presale-contract'
import { DisqusCount } from 'vue-disqus'
import SETTINGS from '@/store/settings'
import ERC20 from '@/smart-contracts/erc20/pager'
import ERC20BALANCE from '@/smart-contracts/erc20'
import V2PAIR from '@/smart-contracts/uniswap/v2-pair'
import V2FACTORY from '@/smart-contracts/uniswap/uniswap-factory-contract'
import VestingPager from '@/smart-contracts/token-locker/pager-contract'
import VESTABI from '@/smart-contracts/token-locker/token-locker-abis'
import DonutChart from '@/components/charts/donut6burn'

export default {
  props: {
    presale: {
      type: Object,
      default: () => {
      }
    },
    exchange: null
  },

  data: () => ({
    firstLoad: true,
    refreshPriceLoading: false,
    chartPopup: false,
    finalizedPairAddress: null,
    lastPriceUpdateBlock: 0,
    lastSync: 0,
    tokenHydrated: {},
    presale_info: {},
    tokenomicsScore: 0,
    current_price: 0,
    price_diff: 0,
    chartData: {
      labels: ['Presale', 'Liquidity', 'Fees', 'Locked', 'Burnt', 'Unlocked'],
      datasets: [{
        data: [],
        backgroundColor: [
          'rgb(20, 240, 132)',
          'rgb(54, 162, 235)',
          'rgba(0, 0, 0, 0.1)',
          'rgba(0, 0, 0, 0.1)',
          'rgba(0, 0, 0, 0.1)',
          'rgba(0, 0, 0, 0.1)',
        ],
        borderWidth: 1,
        // borderColor: 'rgba(0,0,0,0.6)'
      }]

    },
    chartOptions: {
      cutoutPercentage: 70,
      legend: {
        display: false,
        position: 'right'
      },
      events: [] // an empty events array prevents hover events showing tooltips
    },
  }),

  components: {
    DisqusCount,
    DonutChart
  },

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    cExchange () {
      return this.exchange || this.$store.state.exchange
    },
    cNetwork () {
      return this.$settings.AMMS[this.cExchange].chain
    },
    dextLink () {
      if (this.cExchange === 'Uniswap V2') {
        return `https://www.dextools.io/app/uniswap/pair-explorer/${this.finalizedPairAddress}`
      } else if (this.cExchange === 'Pancakeswap V2' || this.cExchange === 'Pancakeswap V1') {
        return `https://www.dextools.io/app/pancakeswap/pair-explorer/${this.finalizedPairAddress}`
      }
      return null
    },
    tokenomicsIsRed () {
      return !(this.tokenHydrated.isENMT || this.presale.audit_rating === 0 || this.presale.audit_rating === 1)
    },
    textColor () {
      // return this.presale.hot ? 'white' : 'text'
      return 'text'
    },
    netIdentifier () {
      var identifier = `token_${this.presale.s_token.address}`
      return `${this.cNetwork}_${identifier}`
    },
    blockNumber () {
      if (this.exchange) {
        return this.$store.state.blockNumz[this.$settings.AMMS[this.exchange].chain]
      }
      return this.$store.state.blockNumber
    },
    blocksPerSecond () {
      if (this.exchange) {
        return this.$settings.CHAINS[this.$settings.AMMS[this.exchange].chain].blockTime
      }
      return this.$store.state.blocksPerSecond
    },
    dateStartBlock () {
      var numBlocks = this.presale_info.start_block - this.blockNumber
      var seconds = numBlocks * this.blocksPerSecond
      var date = moment().add(seconds, 'seconds')
      return date
    },
    dateStartBlockHuman () {
      return this.dateStartBlock.format('ddd D MMM HH:mm')
    },
    timeToStartBlock () {
      return this.dateStartBlock.fromNow()
    },
    dateEndBlock () {
      var numBlocks = this.presale_info.end_block - this.blockNumber
      var seconds = numBlocks * this.blocksPerSecond
      var date = moment().add(seconds, 'seconds')
      return date
    },
    dateEndBlockHuman () {
      return this.dateEndBlock.format('ddd D MMM HH:mm')
    },
    timeToEndBlock () {
      return this.dateEndBlock.fromNow()
    },
    presaleBlockDuration () {
      var blockLength = Number(this.presale_info.end_block) - Number(this.presale_info.start_block)
      return blockLength
    },
    presaleDurationHuman () {
      var duration = this.presaleBlockDuration * this.blocksPerSecond
      var mdur = moment.duration(duration * 1000)
      if (mdur.days() > 0) {
        return mdur.days() + ' days'
      }
      if (mdur.hours() > 0) {
        return mdur.hours() + ' hours'
      }
      if (mdur.minutes() > 0) {
        return mdur.minutes() + ' minutes'
      }
      return mdur.seconds() + ' seconds'
    },
    softcapHuman () {
      if (this.firstLoad) {
        return 0
      }
      var amount = ethers.utils.formatUnits(this.presale_info.softcap, this.presale.b_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    hardcapHuman () {
      if (this.firstLoad) {
        return 0
      }
      var amount = ethers.utils.formatUnits(this.presale_info.hardcap, this.presale.b_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    tokenPriceHuman () {
      var amount = ethers.utils.formatUnits(this.presale_info.token_price, this.presale.s_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8})
    },
    listingPriceHuman () {
      var amount = ethers.utils.formatUnits(this.presale_info.listing_rate, this.presale.s_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8})
    },
    baseTokenSymbol () {
      return this.presale.b_token.address === this.$settings.WRAPPED_GAS_TOKEN[this.cNetwork] ? this.$settings.CHAINS[this.cNetwork].gasToken.symbol : this.presale.b_token.symbol.toUpperCase()
    },
    lockPeriodHuman () {
      var endOfLock = moment().unix() + Number(this.presale_info.lock_period)
      if (endOfLock > 9999999999) {
        var diff = Number(this.presale_info.lock_period) - moment().unix()
        return moment.duration(diff * 1000).years() + ' Years'
      }
      var duration = moment.duration(this.presale_info.lock_period * 1000)
      if (duration.years() >= 1) {
        return duration.years() + ' Years'
      }
      if (duration.months() >= 1) {
        return duration.months() + ' Months'
      }
      return duration.days() + ' Days'
    },
    userMaxLimitHuman () {
      if (this.firstLoad) {
        return 0
      }
      var amount = ethers.utils.formatUnits(this.presale_info.max_spend_per_buyer, this.presale.b_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    unclProgress () {
      if (this.firstLoad) {
        return 0
      }
      if (this.presale_info.uncl_max_participants === '0') {
        return 0
      }
      var percent = ethers.BigNumber.from(this.presale_info.uncl_participants).mul(1000).div(this.presale_info.uncl_max_participants)
      return percent.toNumber() / 10
    },
    presaleProgress () {
      if (this.firstLoad) {
        return 0
      }
      var percent = ethers.BigNumber.from(this.presale_info.total_base_collected).mul(1000).div(this.presale_info.hardcap)
      return percent.toNumber() / 10
      // return 0
    },
    totalBaseCollectedHuman () {
      if (this.firstLoad) {
        return 0
      }
      var amount = ethers.utils.formatUnits(this.presale_info.total_base_collected, this.presale.b_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
    },
    presaleStage () {
      if (this.firstLoad) {
        return 0
      }
      if (this.presale_info.lp_generation_complete) {
        return 5 // finalized
      }
      if (this.presale_info.force_failed) {
        return 4 // failed
      }
      if (Number(this.blockNumber) > Number(this.presale_info.end_block) && ethers.BigNumber.from(this.presale_info.total_base_collected).lt(this.presale_info.softcap)) {
        return 4 // failed
      }
      if (ethers.BigNumber.from(this.presale_info.total_base_collected).gte(this.presale_info.hardcap)) {
        return 3 // success
      }
      if (Number(this.blockNumber) > Number(this.presale_info.end_block) && ethers.BigNumber.from(this.presale_info.total_base_collected).gte(this.presale_info.softcap)) {
        return 3 // success
      }
      if (Number(this.blockNumber) >= Number(this.presale_info.start_block) && Number(this.blockNumber) <= Number(this.presale_info.end_block)) {
        if (Number(this.blockNumber) < (Number(this.presale_info.start_block) + Number(this.presale_info.round1_length))) {
          return 1 // round 1
        }
        return 2 // round 2
      }

      return 0 // awaiting start
    },
    presaleStageString () {
      var arr = ['Starts', 'LIVE: Round 1', 'LIVE: Round 2', 'Success', 'Failed', 'Markets initialized']
      return arr[this.presaleStage]
    },
    presaleStageColor () {
      var arr = ['blue', 'primary', 'primary', 'primary', 'pink', 'purple']
      return arr[this.presaleStage]
    },
    auditColor () {
      if (this.presale.audit_rating === null) {
        return 'orange'
      }
      var arr = ['green', 'orange', 'red']
      return arr[this.presale.audit_rating]
    },
    actualLiquidityPercent () {
      return (Number(this.presale_info.liquidity_percent) - (Number(this.presale_info.fee_base))) / 10
    }
  },

  watch: {
    blockNumber () {
      var now = Math.round(new Date() / 1000)
      var elapsed = now - this.lastSync
      // console.log('elapsed: ' + elapsed)
      if (elapsed >= 8) {
        this.lastSync = now
        this.refresh()
      } else {
        // console.log('skipping')
      }
      if (this.lastPriceUpdateBlock < this.blockNumber - 5) {
        this.getPriceDiff()
        this.lastPriceUpdateBlock = this.blockNumber
      }
    }
  },

  methods: {
    logSomething () {
      console.log(this.$root.PROVIDERS)
    }, 
    togglePresaleHot () {
      var data = {
        presale_contract: this.presale.presale_contract,
      }
      this.$axios.post(`/presales/toggle-hot`, data)
        .then(response => {
          this.$root.$dialog.globalSuccess.open('Hot successfully submitted')
        })
        .catch(error => {
          this.$root.$dialog.web3Error.open(error.response.data.errors[0])
        })
    },
    async refresh () {
      var network = this.exchange ? SETTINGS.AMMS[this.exchange].chain : null
      var response = await PresaleContract.getInfo(this.presale.presale_contract, network)
      this.presale_info = response.presale_info
    },
    openTokenPage () {
      this.$root.tokenPage.open(this.presale.s_token.address, this.cNetwork, this.cExchange)
    },
    async hydrateToken () {
      this.tokenHydrated = await ERC20.getERC20(this.presale.s_token.address, this.cNetwork)
    },
    async getPriceDiff () {
      if (this.presaleStage !== 5) {
        return
      }
      this.refreshPriceLoading = true
      this.finalizedPairAddress = await V2FACTORY.getPair(this.presale.s_token.address, this.presale.b_token.address, this.cNetwork, this.cExchange)
      var reserves = await V2PAIR.getReserves(this.finalizedPairAddress, this.cNetwork)
      var token0 = await V2PAIR.token0(this.finalizedPairAddress, this.cNetwork)

      var priceToken0
      if (token0 === this.presale.s_token.address) {
        priceToken0 = ethers.BigNumber.from(ethers.BigNumber.from(10).pow(this.presale.b_token.decimals)).mul(reserves.reserve0).div(reserves.reserve1)
      } else {
        priceToken0 = ethers.BigNumber.from(ethers.BigNumber.from(10).pow(this.presale.b_token.decimals)).mul(reserves.reserve1).div(reserves.reserve0)
      }
      this.current_price = priceToken0

      var priceDifference = ethers.BigNumber.from(this.presale_info.token_price).sub(this.current_price)
      priceDifference = priceDifference.mul(10000).div(this.current_price).toString()
      this.price_diff = Number(priceDifference / 100)
      this.refreshPriceLoading = false
    },
    async getTokenomics () {
      try {
        var totalSupply = this.presale.s_token.total_supply

        var listingRatePercent = ethers.BigNumber.from(this.presale_info.listing_rate).mul(1000).div(this.presale_info.token_price).toString()
        var unicryptFee = ethers.BigNumber.from(this.presale_info.amount).mul(this.presale_info.fee_token).div(1000).toString()
        var liquidity = ethers.BigNumber.from(this.presale_info.amount).sub(unicryptFee).mul(this.presale_info.liquidity_percent).mul(listingRatePercent).div(1000000).toString()

        // tokenLocks
        var numLocks = await VestingPager.getTokenLocksLength(this.presale_info.token.address, this.cNetwork)
        var rows = []
        var page = 0;
        while (rows.length < numLocks) {
          var lock_ids = await VestingPager.getTokenLocks(this.presale_info.token.address, page * VESTABI.MAX_GETTER_LENGTH, VESTABI.MAX_GETTER_LENGTH, this.cNetwork)
          var locks = await VestingPager.getLocks(lock_ids, this.cNetwork)
          rows.push(...locks)
          page++
        }
        // total locked
        var now = moment().unix()
        var validLocks = rows.filter(item => {
          if (item.end_emission > now && item.shares_withdrawn !== item.shares_deposited && item.condition === ethers.constants.AddressZero) {
            return item
          }
        })
        var amountLocked = validLocks.reduce((a, item) => {
          return a.add(item.tokens_deposited).sub(item.tokens_withdrawn)
        }, ethers.BigNumber.from(0))
        var allLocks = rows.filter(item => {
          if (item.end_emission > now && item.shares_withdrawn !== item.shares_deposited) {
            return item
          }
        })
        this.tokenLocks = allLocks

        // chart DATA
        var burnt = await ERC20BALANCE.getBalance('0x000000000000000000000000000000000000dEaD', this.presale_info.token.address, this.cNetwork)
        var remain = ethers.BigNumber.from(totalSupply).sub(this.presale_info.amount).sub(liquidity).sub(unicryptFee).sub(amountLocked).sub(burnt)
        var unsold = ethers.utils.formatUnits(remain, this.presale_info.token.decimals)

        var amount = ethers.utils.formatUnits(this.presale_info.amount, this.presale_info.token.decimals)
        unicryptFee = ethers.utils.formatUnits(unicryptFee, this.presale_info.token.decimals)
        liquidity = ethers.utils.formatUnits(liquidity, this.presale_info.token.decimals)
        amountLocked = ethers.utils.formatUnits(amountLocked, this.presale_info.token.decimals)
        burnt = ethers.utils.formatUnits(burnt, this.presale_info.token.decimals)

        var data = [amount, liquidity, unicryptFee, amountLocked, burnt, unsold]
        this.chartData.datasets[0].data = data
        try {
          this.$refs.donutChart.update()
        } catch (e) {}
        try {
          this.$refs.donutChart1.update()
        } catch (e) {}

        // percent of total
        var percent = ethers.BigNumber.from(totalSupply).sub(remain).mul(100).div(totalSupply)
        this.tokenomicsScore = Math.min(percent.toNumber(), 100)
      } catch (e) {
        console.log(e)
        // incase on testnets and token vesting is not connected
        // this prevents errors in the browser console that make no sense
      }
    },
  },

  created () {
    this.hydrateToken()
    this.refresh()
      .then(() => {})
      .catch(e => { console.log(e) })
      .then(() => {
        setTimeout(() => {
          this.firstLoad = false
          this.getTokenomics()
          this.getPriceDiff()
        }, 0)
      })
  }
}
</script>

<style scoped lang="scss">
.carousel-override {
  &.v-carousel .v-window-item {
    display: flex;
    align-items: center;
  }
  /deep/ .v-image {
    color: var(--v-text-base);
    height: 100%;
  }
}
.hoverlink {
  :hover {
    color: var(--v-primary-base)!important;
    &.v-icon {
      color: var(--v-primary-base)!important;
    }
  }
}
</style>