<template>
  <div class="">

    <div v-if="pool_info.creator !== sEthers.coinbase" class="">
      <div class="py-4 textFaint--text">
        Connect the following account to access this area
      </div>
      <div class="v-card pa-3 font-weight-bold d-flex align-center">
        <v-icon class="mr-3 pa-2 lgrad-green br-c" size="32" color="white">mdi-account-circle-outline</v-icon>
        <div>
          <div>
            Pool Admin
          </div>
          <div class="caption font-weight-medium textFaint--text">
            {{ pool_info.creator }}
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="mt-4 font-weight-bold">
        Transfer ownership
      </div>
      <div class="px-3 br-8 inputcolor mb-4">
        <div class="d-flex align-center py-2">
          <template v-if="!newOwnerAddressIsValid">
            <v-icon small color="textFaint" class="mr-1">mdi-alert-outline</v-icon>
          </template>
          <template v-else>
            <v-icon small color="primary" class="mr-1">mdi-check-circle-outline</v-icon>
          </template>
          <c-input :value.sync="new_owner_address" placeholder="New owner address..">
          </c-input>
          <v-btn small rounded color="primary" @click="transferOwnership">
            transfer
          </v-btn>
        </div>
      </div>

      <div class="mt-10 font-weight-bold">
        Additional reward pool creator
      </div>
      <div class="textFaint--text">
        Contract or user address who can along with the pool owner create reward pools
      </div>
      <div class="px-3 mt-2 br-8 inputcolor mb-4">
        <div class="d-flex align-center py-2">
          <template v-if="!rewardCreatorAddressIsValid">
            <v-icon small color="textFaint" class="mr-1">mdi-alert-outline</v-icon>
          </template>
          <template v-else>
            <v-icon small color="primary" class="mr-1">mdi-check-circle-outline</v-icon>
          </template>
          <c-input :value.sync="reward_creator_address" placeholder="Reward creator address..">
          </c-input>
          <v-btn small rounded color="primary" @click="adminSetRewardCreator">
            Set
          </v-btn>
        </div>
      </div>

      <div class="mt-12 font-weight-bold">
        Set minimum lock period
      </div>
      <div class="textFaint--text">
        This is the boost (0%) minimum lock period
      </div>
      <time-offset @onUpdate="updateMinStakingPeriod" ref="timeOffset"></time-offset>

      <v-btn color="primary" @click="adminSetMinStakingPeriod">
        Set Lock Period
      </v-btn>

      <div>

        <div class="mt-12 mb-2 font-weight-bold">
          Min Stake Amount
        </div>

        <div class="d-flex align-center inputcolor pa-2 r-outlined">
          <c-input :value.sync="min_stake_amount_human" placeholder="0.0" @updateWhileFocussed="onMinStakeAmountChange" class="pa-2 font-weight-bold">
          </c-input>

          <div class="font-weight-bold">
            {{ stake_token_hydrated.symbol }}
          </div>
        </div>

      </div>
      <div>

        <div class="mt-6 mb-2 font-weight-bold">
          Max stake amount
        </div>

        <div class="d-flex align-center inputcolor pa-2 r-outlined">
          <c-input :value.sync="max_stake_amount_human" placeholder="0.0" @updateWhileFocussed="onMaxStakeAmountChange" class="pa-2 font-weight-bold">
          </c-input>

          <div class="font-weight-bold">
            {{ stake_token_hydrated.symbol }}
          </div>
        </div>
      </div>

      <v-btn color="primary" @click="adminSetMinMaxStakeAmount">
        Set Stake Amount Range
      </v-btn>

      <!-- STAKE MOD FEE -->
      <div class="mt-6 py-2 d-flex align-center font-weight-bold">
        Stake modification fee
        <v-spacer></v-spacer>
        <v-btn v-if="!expandStakeModFeePanel" rounded color="primary" @click="expandStakeModFeePanel = true">
          Enable
        </v-btn>
      </div>
      <div class="textFaint--text caption">
        Charge the user a fee every time they stake or unstake.
        This is useful for covering oracle costs.
      </div>
      <v-expand-transition mode="out-in">
        <div v-if="expandStakeModFeePanel">

          <div class="pa-4 v-card br-20">
            <div class="mb-2 d-flex align-center textFaint--text font-weight-bold">
              Fee Token
              <v-spacer></v-spacer>
              <v-btn small rounded depressed 
              @click="stakeModFeeTokenIsGasToken = true"
              :color="stakeModFeeTokenIsGasToken ? 'primary' : ''">
                {{ $store.state.nativeGasTokenSymbol }}
              </v-btn>
              <v-btn small rounded depressed 
              @click="stakeModFeeTokenIsGasToken = false"
              :color="!stakeModFeeTokenIsGasToken ? 'primary' : ''">
                Other
              </v-btn>
            </div>
            <v-expand-transition mode="out-in">
              <div v-if="!stakeModFeeTokenIsGasToken" class="pa-0">
                <load-token @on-update="updateStakeModFeeToken" :address="pool_info.stake_mod_fee_token.address"></load-token>
              </div>
            </v-expand-transition>

            <div class="mt-4 textFaint--text font-weight-bold">
              Amount
            </div>
            <div class="d-flex mt-2 br-8 align-center inputcolor pa-2">
              <c-input :value.sync="stake_mod_fee_amount_human" placeholder="0.0" @updateWhileFocussed="onStakeModFeeAmountChange" class="pa-2 font-weight-bold">
              </c-input>

              <div class="font-weight-bold">
                {{ stakeModFeeTokenIsGasToken ? $store.state.nativeGasTokenSymbol : stake_mod_fee_token_hydrated.symbol }}
              </div>
            </div>

            <div class="mt-4 textFaint--text font-weight-bold">
              Fee Address
            </div>
            <div class="textFaint--text caption">
              The address where staking and unstaking fees be sent.
            </div>
            <div class="mt-2 pa-2 br-8 inputcolor mb-4">
              <div class="d-flex align-center py-2">
                <template v-if="!stakeModFeeAddressIsValid">
                  <v-icon small color="red" class="mr-1">mdi-alert-outline</v-icon>
                </template>
                <template v-else>
                  <v-icon small color="primary" class="mr-1">mdi-check-circle-outline</v-icon>
                </template>
                <c-input :value.sync="stake_mod_fee_address" placeholder="0x...">
                </c-input>
              </div>
            </div>
            <v-btn small rounded color="primary"
            @click="adminSetStakeModificationFee">
              Update
            </v-btn>
            <v-btn small rounded color="primary" @click="adminDisableStakeModificationFee">
              Disable
            </v-btn>
          </div>
        </div>
      </v-expand-transition>
      <!-- STAKE MOD FEE -->

      <div class="py-8">
        <div class="mt-4 font-weight-bold">
          Unlock all tokens
        </div>
        <div class="textFaint--text">
          Set to true to allow all users to withdraw their tokens from this pool and bypass the lock date
        </div>
        <div class="d-flex align-center pt-3">
          <v-btn depressed rounded :color="unlock_tokens ? 'primary' : ''" @click="unlock_tokens = true">
            True
          </v-btn>
          <v-btn depressed rounded :color="!unlock_tokens ? 'primary' : ''" @click="unlock_tokens = false">
            False
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn small rounded color="primary" :disabled="unlock_tokens === pool_info.unlock_tokens"
          @click="setUnlockTokens">
            Update
          </v-btn>
        </div>
      </div>

      <drain-token :address="address" :stake_token_hydrated="stake_token_hydrated"></drain-token>
    </div>

  </div>
</template>

<script>
import TimeOffset from '@/components/date-time/time-offset'
import { ethers } from 'ethers'
import TxWrapper from '@/smart-contracts/tx-wrapper'
import StakePoolContract from '@/smart-contracts/farms-v2/oracle/stake-pool/v2/stake-pool'
import ERC20 from '@/smart-contracts/erc20/pager'
import DrainToken from './drain-token'

export default {

  props: {
    address: null
  },

  components: {
    TimeOffset,
    DrainToken
  },

  data: () => ({
    pool_info: {

    },
    stake_token_hydrated: {

    },

    new_owner_address: '',
    reward_creator_address: '',
    lockPeriod: '0',
    min_stake_amount: '0',
    max_stake_amount: '0',
    min_stake_amount_human: '0',
    max_stake_amount_human: '0',
    unlock_tokens: false,

    stakeModFeeTokenIsGasToken: true,
    stake_mod_fee_amount: '0',
    stake_mod_fee_amount_human: '0',
    stake_mod_fee_token_hydrated: {},
    stake_mod_fee_address: '',
    expandStakeModFeePanel: false
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    newOwnerAddressIsValid () {
      try {
        ethers.utils.getAddress(this.new_owner_address)
        return true
      } catch (e) {
        return false
      }
    },
    rewardCreatorAddressIsValid () {
      try {
        ethers.utils.getAddress(this.reward_creator_address)
        return true
      } catch (e) {
        return false
      }
    },
    stakeModFeeAddressIsValid () {
      if (this.stake_mod_fee_address === ethers.constants.AddressZero) {
        return false
      }
      try {
        ethers.utils.getAddress(this.stake_mod_fee_address)
        return true
      } catch (e) {
        return false
      }
    },
  },

  methods: {
    async refresh () {
      this.pool_info = await StakePoolContract.getPoolInfo(this.address)
      this.stake_token_hydrated = await ERC20.getERC20(this.pool_info.staking_token)
      this.min_stake_amount_human = ethers.utils.formatUnits(this.pool_info.min_stake_amount, this.stake_token_hydrated.decimals)
      if (this.pool_info.max_stake_amount === ethers.constants.MaxUint256.toString()) {
        this.max_stake_amount_human = 'MAX_VALUE'
      } else {
        this.max_stake_amount_human = ethers.utils.formatUnits(this.pool_info.max_stake_amount, this.stake_token_hydrated.decimals)
      }
      this.min_stake_amount = this.pool_info.min_stake_amount
      this.max_stake_amount = this.pool_info.max_stake_amount
      this.unlock_tokens = this.pool_info.unlock_tokens
      this.reward_creator_address = this.pool_info.reward_creator
      this.$refs.timeOffset.setDisplay(this.pool_info.min_staking_period)

      // stake mod fees
      if (this.pool_info.stake_mod_fee_token.address === ethers.constants.AddressZero) {
        this.stakeModFeeTokenIsGasToken = true
        this.stake_mod_fee_amount_human = ethers.utils.formatUnits(this.pool_info.stake_mod_fee_amount, 18)
      } else {
        this.stakeModFeeTokenIsGasToken = false
        this.stake_mod_fee_amount_human = ethers.utils.formatUnits(this.pool_info.stake_mod_fee_amount, this.pool_info.stake_mod_fee_token.decimals)
      }
      if (this.pool_info.stake_mod_fee_amount !== '0') {
        this.expandStakeModFeePanel = true
      }
      this.stake_mod_fee_amount = this.pool_info.stake_mod_fee_amount
      this.stake_mod_fee_address = this.pool_info.stake_mod_fee_address
    },
    updateStakeModFeeToken (token) {
      this.stake_mod_fee_token_hydrated = token;
    },
    onStakeModFeeAmountChange (val) {
      if (!val) {
        this.stake_mod_fee_amount = '0'
        return
      }
      this.stake_mod_fee_amount = ethers.utils.parseUnits(val, this.stake_mod_fee_token_hydrated.decimals)
    },
    updateMinStakingPeriod (timeInSeconds) {
      this.lockPeriod = timeInSeconds
    },
    onMinStakeAmountChange (val) {
      if (!val) {
        this.min_stake_amount = '0'
        return
      }
      this.min_stake_amount = ethers.utils.parseUnits(val, this.stake_token_hydrated.decimals)
    },
    onMaxStakeAmountChange (val) {
      if (!val) {
        this.max_stake_amount = '0'
        return
      }
      this.max_stake_amount = ethers.utils.parseUnits(val, this.stake_token_hydrated.decimals)
    },
    adminSetMinStakingPeriod () {
      TxWrapper.doTransaction(StakePoolContract.adminSetMinStakingPeriod(this.address, this.lockPeriod), this.$root.$dialog.confirmTx, 1)
        .then(response => {
          this.$emit('hard-refresh')
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          // end
        })
    },
    adminSetMinMaxStakeAmount () {
      TxWrapper.doTransaction(StakePoolContract.adminSetMinMaxStakeAmount(this.address, this.min_stake_amount, this.max_stake_amount), this.$root.$dialog.confirmTx, 1)
        .then(response => {
          this.$emit('hard-refresh')
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          // end
        })
    },
    adminSetStakeModificationFee () {
      var stakeModFeeToken = ethers.constants.AddressZero
      if (!this.stakeModFeeTokenIsGasToken) {
        stakeModFeeToken = this.stake_mod_fee_token_hydrated.address
      }
      TxWrapper.doTransaction(StakePoolContract.adminSetStakeModificationFee(this.address, this.stake_mod_fee_amount, stakeModFeeToken, this.stake_mod_fee_address), this.$root.$dialog.confirmTx, 1)
        .then(response => {
          this.refresh()
          this.$emit('hard-refresh')
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          // end
        })
    },
    adminDisableStakeModificationFee () {
      if (this.pool_info.stake_mod_fee_amount === '0') {
        this.expandStakeModFeePanel = false
        return
      }
      var amount = '0'
      var tokenAddress = ethers.constants.AddressZero
      var feeAddress = ethers.constants.AddressZero
      TxWrapper.doTransaction(StakePoolContract.adminSetStakeModificationFee(this.address, amount, tokenAddress, feeAddress), this.$root.$dialog.confirmTx, 1)
        .then(response => {
          this.refresh()
          this.$emit('hard-refresh')
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          // end
        })
    },
    transferOwnership () {
      TxWrapper.doTransaction(StakePoolContract.transferPoolOwnership(this.address, this.new_owner_address), this.$root.$dialog.confirmTx, 1)
        .then(response => {
          this.refresh()
          this.$emit('hard-refresh')
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          // end
        })
    },
    adminSetRewardCreator () {
      TxWrapper.doTransaction(StakePoolContract.adminSetRewardCreator(this.address, this.reward_creator_address), this.$root.$dialog.confirmTx, 1)
        .then(response => {
          this.refresh()
          this.$emit('hard-refresh')
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          // end
        })
    },
    setUnlockTokens () {
      TxWrapper.doTransaction(StakePoolContract.adminSetUnlockTokens(this.address, this.unlock_tokens), this.$root.$dialog.confirmTx, 1)
        .then(response => {
          this.refresh()
          this.$emit('hard-refresh')
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          // end
        })
    },
  },

  created () {
    this.refresh()
  }
}
</script>