<template>
  
  <div v-if="firstLoad" class="py-3 px-4">
    <v-scale-transition appear>
      <v-progress-circular
      indeterminate
      size="44"
      width="2"
      color="#aaa">
        <img 
        src="@/assets/img/UNCX_fill.svg" 
        height="120px"
        class="greyscale"
        width="38px">
      </v-progress-circular>
    </v-scale-transition>
  </div>
  <v-slide-x-transition v-else appear>
    <div class="foreground">

      <!-- TOP PANEL -->
      <div v-if="!mini" class="px-4 py-3 d-flex align-center c-list" @click="expandPanel = !expandPanel">

        <coin-icon :address="reward_pool.reward_token.address" class="mr-1" :size="38"></coin-icon>

        <div class="flex ml-1">

          <div class="d-flex align-center">

            <span :class="['ml-1 text-truncate font-weight-medium']">
              {{ reward_pool.reward_token.symbol.toUpperCase() }}
            </span>

            <div v-if="remainingBlocks > 0" class="px-1 primary--text br-c caption font-weight-medium ml-1">
              {{ reward_meta.apy }}% APR
            </div>

            <div v-if="bonusLiveOrUpcoming && reward_pool.bonus !== '1'" class="px-4 lgrad-blue white--text br-c caption font-weight-bold ml-2">
              {{ reward_pool.bonus }}x bonus
            </div>

            <!--
            <div v-if="userIsSubscribed" class="px-1 primary white--text br-4 caption font-weight-bold ml-2">
              Sub
            </div>

            <div v-else class="px-1 textFaint background--text br-4 caption font-weight-bold ml-2">
              Unsub
            </div>
            -->

            <v-spacer></v-spacer>

            <span v-if="rpool_user_info.unharvested_rewards !== '0'" class="font-weight-medium text--text pr-1">
              {{ $root.formatAmount(rpool_user_info.unharvested_rewards, reward_pool.reward_token.decimals) }}
            </span>

            <span v-if="!userIsSubscribed && !farmingEnded" class="lgrad-green br-c">
              <v-btn rounded :loading="subscribeLoading" small class="white--text">
                Subscribe
                <v-icon v-if="false" size="16" color="white" class="ml-1 tween">mdi-alert-decagram-outline</v-icon>
              </v-btn>
            </span>

            <!--
            <div v-else-if="!userIsSubscribed && (farmingAwaitingStart || farmingLive)" class="caption d-flex align-center font-weight-medium textFaint--text">
              Not subscribed
              <v-icon small class="pl-1" :color="userIsSubscribed ? 'primary' : 'textFaint'">mdi-alert-outline</v-icon>
            </div>
            -->

            <!--
            <div v-if="farmingEnded" class="caption font-weight-bold textFaint--text px-1">
              <v-icon small color="red">mdi-timelapse</v-icon>
            </div>
            <div v-if="farmingAwaitingStart" class="caption font-weight-bold textFaint--text px-1">
              <v-icon small color="light-blue">mdi-timelapse</v-icon>
            </div>
            <div v-if="farmingLive" class="caption font-weight-bold textFaint--text px-1">
              <v-icon small color="primary">mdi-timelapse</v-icon>
            </div>
            -->
          </div>

          <div class="d-flex align-center">
            
            <div v-if="farmingAwaitingStart" class="d-flex align-center textFaint--text caption font-weight-medium">
              <v-icon x-small color="textFaint" class="mr-1">mdi-timelapse</v-icon>
              Starts {{ timeToStartBlock }}
            </div>
            <div v-if="farmingLive" class="d-flex align-center textFaint--text caption font-weight-medium">
              <v-icon x-small color="textFaint" class="mr-1">mdi-timelapse</v-icon>
              Ends {{ timeToEndBlock }}
            </div>
            <div v-if="farmingEnded" class="d-flex align-center textFaint--text caption font-weight-medium">
              <v-icon x-small color="textFaint" class="mr-1">mdi-timelapse</v-icon>
              Ended
            </div>
            
            <div class="px-2 d-flex align-center br-c caption textFaint--text ml-1">
              <v-icon x-small color="textFaint" class="mr-1">mdi-account-group</v-icon>
              {{ reward_pool.num_farmers }}
            </div>

            <span v-if="userConnected && !farmingEnded" :class="['px-2 br-c d-flex align-center caption', {'textFaint--text': !userIsSubscribed}, {'textFaint--text': userIsSubscribed}]">
              <v-icon x-small color="textFaint" class="mr-1">mdi-chart-pie</v-icon>
              {{ $root.formatAmount(predictiveReward, reward_pool.reward_token.decimals) }}
            </span>

            <span v-if="false" :class="['px-2 textFaint--text caption']">
              {{ $root.condenseAddress(reward_pool.pool_address) }}
            </span>

          </div>

        </div>

        <v-icon color="textFaint">mdi-chevron-right</v-icon>
      </div>
      <!-- TOP PANEL -->
      <!-- TOP PANEL -->
      <div v-else class="px-2 py-3 d-flex align-center c-list" @click="expandPanel = !expandPanel">

        <coin-icon :address="reward_pool.reward_token.address" :size="32"></coin-icon>

        <div class="flex pl-2">

          <div class="d-flex align-center">

            <span :class="['text-truncate font-weight-bold']">
              {{ reward_pool.reward_token.symbol.toUpperCase() }}
            </span>

            <v-spacer></v-spacer>

            <div v-if="bonusLiveOrUpcoming && reward_pool.bonus !== '1'" class="px-4 lgrad-blue br-c caption font-weight-bold ml-2">
              {{ reward_pool.bonus }}x bonus
            </div>

            <!--
            <div v-if="userIsSubscribed" class="px-1 text background--text br-4 caption font-weight-bold ml-2">
              Sub
            </div>

            <div v-else class="px-1 textFaint background--text br-4 caption font-weight-bold ml-2">
              Unsub
            </div>
            -->

            <!--
            <div v-else-if="!userIsSubscribed && (farmingAwaitingStart || farmingLive)" class="caption d-flex align-center font-weight-medium textFaint--text">
              Not subscribed
              <v-icon small class="pl-1" :color="userIsSubscribed ? 'primary' : 'textFaint'">mdi-alert-outline</v-icon>
            </div>
            -->

            <!--
            <div v-if="farmingEnded" class="caption font-weight-bold textFaint--text px-1">
              <v-icon small color="red">mdi-timelapse</v-icon>
            </div>
            <div v-if="farmingAwaitingStart" class="caption font-weight-bold textFaint--text px-1">
              <v-icon small color="light-blue">mdi-timelapse</v-icon>
            </div>
            <div v-if="farmingLive" class="caption font-weight-bold textFaint--text px-1">
              <v-icon small color="primary">mdi-timelapse</v-icon>
            </div>
            -->
          </div>

          <div v-if="rpool_user_info.unharvested_rewards !== '0'" class="font-weight-bold text--text">
            {{ $root.formatAmount(rpool_user_info.unharvested_rewards, reward_pool.reward_token.decimals) }}
          </div>
                    
          <div v-else-if="!farmingEnded" :class="['d-flex align-center font-weight-medium', {'textFaint--text': !userIsSubscribed}, {'textFaint--text': userIsSubscribed}]">
            {{ $root.formatAmount(predictiveReward, reward_pool.reward_token.decimals) }}
          </div>

        </div>
      </div>
      <!-- TOP PANEL -->

      <v-dialog v-model="expandPanel" :fullscreen="$vuetify.breakpoint.xs" scrollable :content-class="!$vuetify.breakpoint.xs ? 'br-20' : ''" width="1000px">
          <v-card :class="['midground', {'br-20': !$vuetify.breakpoint.xs}]">
            <div class="d-flex border-b pa-4">
              <v-btn @click="tab = 0" text :color="tab === 0 ? 'text' : 'textFaint'">
                <v-icon>mdi-account-outline</v-icon>
              </v-btn>
              <v-btn v-if="false" @click="tab = 1" text :color="tab === 1 ? 'text' : 'textFaint'">
                Info
              </v-btn>
              <v-btn @click="tab = 2" text :color="tab === 2 ? 'text' : 'textFaint'">
                Admin
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="textFaint" icon @click="expandPanel = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>

            <v-card-text class="pa-0">

              <div v-if="tab === 0" class="">
                
                <v-row dense class="ma-0">
                  <v-col cols="12" md="6">
                    <div v-if="sEthers.coinbase">

                      <!-- First stake -->
                      <div v-if="spool_user_info.share_weight === '0'" class="px-4 pb-4 br-20 d-flex align-center">
                        <v-icon :class="['mr-2 pa-1 br-c lgrad-red']" size="62" color="white">mdi-account-circle</v-icon>
                        <div class="flex">
                          <div class="d-flex align-center">
                            <div>
                              <div class="font-weight-bold">
                              Your stake balance is 0
                              </div>
                              <div class="caption">
                                You need to stake before you can subscribe to rewards.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- First stake -->

                      <div v-else class="px-4 pb-4 br-20 d-flex align-center">
                        <v-icon :class="['mr-2 pa-1 br-c']" size="62" color="text">mdi-account-circle</v-icon>
                        <div class="flex">
                          <div v-if="userIsSubscribed" class="d-flex align-center justify-center">
                            <div>
                              <div class="font-weight-bold">
                                Subscribed
                              </div>
                              <div class="caption">
                                Earning rewards
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                            <v-btn rounded @click="unsubscribe" :loading="unsubscribeLoading" color="text" class="background--text">
                              Unsubscribe
                            </v-btn>
                          </div>
                          <div v-else-if="!farmingEnded" class="d-flex align-center justify-center">
                            <div>
                              <div class="font-weight-bold">
                                Unsubscribed
                              </div>
                              <div class="caption">
                                Not earning rewards
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                            <span class="gradient-border br-c pa-05">
                            <v-btn rounded depressed @click="subscribe" :loading="subscribeLoading" color="foreground">
                              <v-icon small color="text" class="mr-1">mdi-alert-decagram-outline</v-icon>
                              Subscribe
                            </v-btn>
                            </span>
                          </div>
                          <div v-else class="d-flex align-center justify-center br-20">
                            <div>
                              <div class="font-weight-bold">
                                Unsubscribed
                              </div>
                              <div class="caption">
                                Not earning rewards
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                          </div>
                        </div>
                      </div>

                      <div v-if="false" class="v-card">
                        <v-btn small rounded text @click="unsubscribe">
                          Unsubscribe
                        </v-btn>
                        <v-btn small rounded text @click="subscribe">
                          Subscribe
                        </v-btn>
                        <v-btn small rounded text @click="emergencyUnsubscribe">
                          Emergency
                        </v-btn>
                      </div>

                      <div class="pl-4 mt-3 caption textFaint--text">
                        Harvestable
                      </div>
                      <div class="d-flex align-center pa-4 foreground br-20">
                        <!-- v-if="rpool_user_info.unharvested_rewards !== '0'" -->
                        <v-icon size="30" color="textFaint" class="mr-2">mdi-sprout</v-icon>
                        <div class="">
                          <div class="text--text">
                            {{ $root.formatAmount(rpool_user_info.unharvested_rewards, reward_pool.reward_token.decimals) }}
                            <span class="textFaint--text">
                              {{ reward_pool.reward_token.symbol }}
                            </span>
                          </div>
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn v-if="rpool_user_info.unharvested_rewards !== '0'" small rounded depressed @click="harvest" :loading="harvestLoading" color="textFaint" class="foreground--text">
                          Harvest
                          <v-icon small>mdi-arrow-down</v-icon>
                        </v-btn>
                      </div>

                      <div class="pl-4 mt-2 caption textFaint--text">
                        Your Wallet
                      </div>
                      <div class="foreground br-20 pa-4 d-flex align-center">
                        <v-icon size="30" color="textFaint" class="mr-2">mdi-wallet</v-icon>
                        <div>
                          {{ $root.formatAmount(user_wallet_balance, reward_pool.reward_token.decimals) }}
                          <span class="textFaint--text">
                            {{ reward_pool.reward_token.symbol }}
                          </span>
                        </div>
                      </div>

                      <div class="pl-4 mt-6 caption textFaint--text">
                        Your predicted earnings after {{ $root.getReadableTimeSpan(remainingSecondsTillEnd) }}
                      </div>
                      <div v-if="!userIsSubscribed" class="pl-4 orange--text">
                        * If you subscribe to this pool
                      </div>
                      <div @click="expandPredictionPanel = !expandPredictionPanel" class="foreground br-20 c-list pa-4">
                        <div class="d-flex text--text align-center font-weight-regular">
                          <v-icon size="30" color="textFaint" class="mr-2">mdi-chart-pie</v-icon>
                          <div>
                            {{ $root.formatAmount(predictiveReward, reward_pool.reward_token.decimals) }}
                            <span class="textFaint--text">
                              {{ reward_pool.reward_token.symbol }}
                            </span>
                          </div>
                          <v-spacer></v-spacer>
                          <v-icon color="textFaint" :class="['ml-2', {'rotate-180': expandPredictionPanel}]">mdi-chevron-down</v-icon>
                        </div>
                      </div>

                      <v-expand-transition mode="out-in">
                        <div v-if="expandPredictionPanel">

                          <div class="midground c-list pa-4">
                            <div class="d-flex text--text align-center font-weight-regular">
                              Optimistic prediction
                              <v-spacer></v-spacer>
                              <v-switch
                              v-model="predictOptimisticEarnings"
                              color="primary"
                              class="ma-0"
                              hide-details
                              ></v-switch>
                            </div>
                            <div v-if="predictOptimisticEarnings" class="py-2 caption textFaint--text">
                              Your estimated rewards if no additional users subscribe to this reward stream.
                            </div>
                            <div v-else class="py-2 caption textFaint--text">
                              Your estimated rewards if all users from the stake pool subscribe to this reward stream.
                            </div>
                            <!--
                            <div v-if="!userIsSubscribed" class="textFaint--text caption">
                              * you need to subscribe to this pool to earn rewards
                            </div>
                            -->
                          </div>

                        </div>
                      </v-expand-transition>
                      
                      <template v-if="userIsSubscribed">
                        <div class="pt-6">
                          <v-btn text color="textFaint" @click="expandAdvancedPanel = !expandAdvancedPanel">
                            Advanced options
                            <v-icon color="textFaint" :class="['ml-2', {'rotate-180': expandAdvancedPanel}]">mdi-chevron-down</v-icon>
                          </v-btn>
                        </div>
                        <v-expand-transition mode="out-in">
                          <div v-if="expandAdvancedPanel">
                            <div class="pa-4">
                              <div class="pa-4 foreground br-8">
                                <div>
                                  Emergency Unsubscribe
                                </div>
                                <div class="pt-2">
                                  <span class="orange--text title">Caution</span>, this function is for use in emergencies to unsubscribe from a reward pool 
                                  where the reward token is broken (possibly non transferrable and not harvestable).
                                  Using this function will unsubscribe you from the pool at the cost of losing all farmed rewards in this pool.
                                </div>
                                <div class="pt-2">
                                  <v-btn color="orange" class="white--text" rounded @click="emergencyUnsubscribe">
                                    Emergency unsubscribe
                                  </v-btn>
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-expand-transition>
                      </template>
                    </div>
                    <div v-else class="v-card br-20 text-center pa-6">
                      <div>
                        <v-icon size="40" color="textFaint">mdi-account-circle</v-icon>
                        <div class="textFaint--text caption pt-2">
                          View your staking info
                        </div>
                      </div>
                      <div class="pt-2">
                        <v-btn
                          large
                          block
                          depressed
                          class="font-weight-bold midground"
                          rounded
                          @click="connectWallet"
                          >
                            CONNECT
                          </v-btn>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <div class="">
                      <div v-if="$vuetify.breakpoint.xs" class="pt-8">

                      </div>

                      <div class="px-4 pb-4 pt-1 br-20 d-flex align-center">
                        <coin-icon :address="reward_pool.reward_token.address" :size="70" class="mr-2"></coin-icon>
                        <div class="flex">
                          <div class="d-flex align-center">
                            <span class="font-weight-bold hover-primary" @click.stop="$root.tokenPage.open(reward_pool.reward_token.address, $store.state.requiredNetwork)">
                              {{ reward_pool.reward_token.name }}
                            </span>
                            <v-spacer></v-spacer>
                            <template v-if="farmingLive">
                              <div class="mr-2 font-weight-bold primary--text">
                                Live
                              </div>
                              <v-icon color="primary" size="16">mdi-timelapse</v-icon>
                            </template>
                            <template v-else-if="farmingAwaitingStart">
                              <div class="mr-2 font-weight-bold light-blue--text">
                                Qued
                              </div>
                              <v-icon color="light-blue" size="22">mdi-timelapse</v-icon>
                            </template>
                            <template v-else-if="farmingEnded">
                              <div class="mr-2 font-weight-bold red--text">
                                Ended
                              </div>
                              <v-icon color="red" size="22">mdi-timelapse</v-icon>
                            </template>
                          </div>
                          <!-- <div class="caption">
                            Reward Pool
                          </div> -->
                        </div>
                      </div>

                      <div v-if="farmingAwaitingStart" class="text-center foreground br-8 pa-2 mb-4">
                        <div class="light-blue--text font-weight-bold">
                          Starts {{ timeToStartBlock }}
                        </div>
                      </div>

                      <div v-if="farmingEnded" class="d-flex align-center justify-center foreground mb-4 py-4">
                        <div class="ml-1 font-weight-bold textFaint--text">
                          Reward emission has ended
                        </div>
                      </div>
                      
                      <!--
                      <div class="text-center pt-3">
                        <coin-icon :address="reward_pool.reward_token.address" :size="70"></coin-icon>
                      </div>
                      <div class="text-center title">
                        {{ reward_pool.reward_token.name }} Reward Pool
                      </div>
                      -->
                      <div class="px-4 pb-6">
                        <div class="caption d-flex">
                          <span class="textFaint--text">
                            Block Reward
                          </span>
                          <span v-if="bonusLiveOrUpcoming && reward_pool.bonus !== '1'" class="ml-1 px-2 lgrad-blue white--text br-c font-weight-bold">
                            x{{ reward_pool.bonus }}
                          </span>
                          <v-spacer></v-spacer>
                          <div>
                            {{ $root.formatAmount(reward_pool.block_reward, reward_pool.reward_token.decimals) }} {{ reward_pool.reward_token.symbol }}
                          </div>
                        </div>

                        <div class="d-flex caption">
                          <span class="textFaint--text">
                            Num subscribers
                          </span>
                          <v-spacer></v-spacer>
                          {{ reward_pool.num_farmers }}
                        </div>

                        <div class="d-flex caption">
                          <span class="textFaint--text">
                            Remaining rewards
                          </span>
                          <v-spacer></v-spacer>
                          {{ $root.formatAmount(remainingRewards, reward_pool.reward_token.decimals) }}
                          {{ reward_pool.reward_token.symbol }}
                        </div>

                        <div class="d-flex caption">
                          <span class="textFaint--text">
                            Pool Balance
                          </span>
                          <v-spacer></v-spacer>
                          {{ $root.formatAmount(reward_pool.pool_balance, reward_pool.reward_token.decimals) }}
                          {{ reward_pool.reward_token.symbol }}
                        </div>

                        <div class="d-flex caption">
                          <span class="textFaint--text">
                            Remaining blocks
                          </span>
                          <v-spacer></v-spacer>
                          {{ remainingBlocks }}
                        </div>

                        <div class="d-flex align-center caption font-weight-bold textFaint--text align-center">
                          Contract
                          <v-spacer></v-spacer>
                          <copy-address :address="reward_info.pool_address" color="text"></copy-address>
                        </div>

                      </div>

                      <div class="d-flex border-b py-2">
                        <v-btn @click="second_tab = 0" text :color="second_tab === 0 ? 'text' : 'textFaint'">
                          Pool Info
                        </v-btn>
                        <v-btn @click="second_tab = 1" text :color="second_tab === 1 ? 'text' : 'textFaint'">
                          Subscribers ({{ reward_pool.num_farmers }})
                        </v-btn>
                      </div>
                      <!-- POOL INFO -->
                      <div v-if="second_tab === 0" class="pt-4">
                        <!-- BLOCK TIMES -->
                        <div class="">
                          <div class="pl-4 caption textFaint--text">
                            Block info
                          </div>
                          <div class="pa-4 br-8 foreground caption">

                            <!-- <div class="d-flex">
                              <span class="textFaint--text">
                                Current block
                              </span>
                              <v-spacer></v-spacer>
                              {{ blockNumber }}
                            </div> -->

                            <!-- <div class="d-flex">
                              <span class="textFaint--text">
                                Start block
                              </span>
                              <v-spacer></v-spacer>
                              {{ reward_pool.start_block }}
                            </div> -->

                            <div class="d-flex">
                              <span class="textFaint--text">
                                Start block date
                              </span>
                              <v-spacer></v-spacer>
                              {{ dateStartBlock.format('D MMM YYYY HH:mm') }}
                            </div>

                            <!-- <div class="d-flex">
                              <span class="textFaint--text">
                                Bonus End Block
                              </span>
                              <v-spacer></v-spacer>
                              {{ reward_pool.bonus_end_block }}
                            </div> -->

                            <div v-if="reward_pool.bonus_end_block !== reward_pool.start_block" class="d-flex">
                              <span class="textFaint--text">
                                Bonus End Block date
                              </span>
                              <v-spacer></v-spacer>
                              {{ dateBonusEndBlock.format('D MMM YYYY HH:mm') }}
                            </div>

                            <!-- <div class="d-flex">
                              <span class="textFaint--text">
                                End block
                              </span>
                              <v-spacer></v-spacer>
                              {{ reward_pool.end_block }}
                            </div> -->

                            <div class="d-flex">
                              <span class="textFaint--text">
                                End block date
                              </span>
                              <v-spacer></v-spacer>
                              {{ dateEndBlock.format('D MMM YYYY HH:mm') }}
                            </div>

                            <div v-if="reward_pool.bonus_end_block !== reward_pool.start_block" class="d-flex">
                              <span class="textFaint--text">
                                Bonus
                              </span>
                              <v-spacer></v-spacer>
                              {{ reward_pool.bonus }}x
                            </div>
                          </div>
                        </div>

                        <div class="caption">

                          <div class="pl-4 mt-4 d-flex align-center textFaint--text">
                            Pool stats
                          </div>
                          <div class="pa-4 br-8 foreground caption">
                            <div class="d-flex">
                              <span class="textFaint--text">
                                Shares
                              </span>
                              <v-spacer></v-spacer>
                              {{ $root.formatAmount(reward_pool.shares_total, pool_info.staking_token.decimals) }}
                            </div>
                            <div class="d-flex">
                              <span class="textFaint--text">
                                Block Reward
                              </span>
                              <span v-if="bonusLiveOrUpcoming && reward_pool.bonus !== '1'" class="ml-1 px-2 lgrad-blue white--text br-c font-weight-bold">
                                x{{ reward_pool.bonus }}
                              </span>
                              <v-spacer></v-spacer>
                              <div>
                                {{ $root.formatAmount(reward_pool.block_reward, reward_pool.reward_token.decimals) }} {{ reward_pool.reward_token.symbol }}
                              </div>
                            </div>
                          </div>

                          <div class="pl-4 mt-4 d-flex align-center textFaint--text">
                            Your stats
                          </div>
                          <div class="pa-4 br-8 foreground caption">
                            <div class="d-flex">
                              <span class="textFaint--text">
                                Shares
                              </span>
                              <v-spacer></v-spacer>
                              <div class="">
                                {{ $root.formatAmount(spool_user_info.share_weight, pool_info.staking_token.decimals) }}
                                ({{ (poolSharePercent / 1000000).toFixed(2) }}%)
                              </div>
                            </div>
                            <div class="d-flex">
                              <span class="textFaint--text">
                                Block Reward
                              </span>
                              <span v-if="bonusLiveOrUpcoming && reward_pool.bonus !== '1'" class="ml-1 px-2 lgrad-blue white--text br-c font-weight-bold">
                                x{{ reward_pool.bonus }}
                              </span>
                              <v-spacer></v-spacer>
                              <div>
                                {{ $root.formatAmount(userBlockRewardShare, reward_pool.reward_token.decimals) }} {{ reward_pool.reward_token.symbol }}
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <!-- POOL INFO -->

                      <reward-staker-view v-else-if="second_tab === 1" :reward_pool_address="reward_info.pool_address" :tokenHydrated="reward_pool.reward_token" :pool_info="pool_info"></reward-staker-view>
                    </div>
                  </v-col>
                </v-row>
              </div>

              <div v-if="tab === 1" class="pa-3">
                <div class="caption textFaint--text">
                  {{ reward_info }}
                </div>

                <div>
                  {{ rpool_user_info }}
                </div>

                <div>
                  {{ reward_pool }}
                </div>
              </div>

              <div v-if="tab === 2" class="">
                <admin-panel :reward_pool="reward_pool" :address="reward_info.pool_address" @hard-refresh="$emit('hard-refresh')"></admin-panel>
              </div>

            </v-card-text>
          </v-card>
      </v-dialog>
    </div>
  </v-slide-x-transition>
</template>

<script>
import moment from 'moment'
import RewardPoolContract from '@/smart-contracts/farms-v2/normal/reward-pool/v1/reward-pool'
import TxWrapper from '@/smart-contracts/tx-wrapper'
import ERC20BALANCE from '@/smart-contracts/erc20'
import { ethers } from 'ethers'
import AdminPanel from './admin-functions'
import RewardStakerView from '@/views/farms/pool/ui/v1/reward-staker-view'

export default {

  props: {
    reward_info: {
      type: Object,
      default: () => {
        return {
          pool_address: '',
          contract_version: 0
        }
      }
    },
    spool_user_info: {
      type: Object,
      default: () => {
        return {
          share_weight: '0'
        }
      }
    },
    pool_info: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
    reward_meta: {
      type: Object,
      default: () => {
        return {
          apy: 0
        }
      }
    },
    mini: {
      type: Boolean,
      default: false
    }
  },

  components: {
    AdminPanel,
    RewardStakerView
  },

  data: () => ({
    expandPanel: false,
    expandPredictionPanel: false,
    expandAdvancedPanel: false,
    predictOptimisticEarnings: true,
    tab: 0,
    second_tab: 0,
    reward_pool: {
      reward_token: {
        decimals: 0,
        symbol: ''
      },
      start_block: '0',
      end_block: '0',
      shares_total: '0'
    },
    rpool_user_info: {
      unharvested_rewards: '0',
      share_weight: '0'
    },
    rpool_balance: '0',
    user_wallet_balance: '0',

    harvestLoading: false,
    unsubscribeLoading: false,
    subscribeLoading: false,

    firstLoad: true
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    userConnected () {
      return ethers.utils.isAddress(this.sEthers.coinbase)
    },
    userIsSubscribed () {
      return this.rpool_user_info.share_weight !== '0'
    },
    poolSharePercent () {
      if (!this.predictOptimisticEarnings) {
        return this.sharepoolSharePercent
      }
      return this.userIsSubscribed ? this.currentPoolSharePercent : this.predictivePoolSharePercent
    },
    currentPoolSharePercent () {
      // divide this by 1e6
      if (!this.userConnected) {
        return 0
      }
      if (this.reward_pool.shares_total === '0') {
        return 0
      }
      var percent = ethers.BigNumber.from(this.rpool_user_info.share_weight).mul(100000000).div(this.reward_pool.shares_total)
      return percent.toNumber()
      // return 0
    },
    predictivePoolSharePercent () {
      // divide this by 1e6
      if (this.reward_pool.shares_total === '0') {
        return 100000000
      }
      var totalShares = ethers.BigNumber.from(this.spool_user_info.share_weight).add(this.reward_pool.shares_total).toString()
      var percent = ethers.BigNumber.from(this.spool_user_info.share_weight).mul(100000000).div(totalShares)
      return percent.toNumber()
      // return 0
    },
    sharepoolSharePercent () {
      if (this.pool_info.share_weight_total === '0') {
        return 0
      }
      var percent = ethers.BigNumber.from(this.spool_user_info.share_weight).mul(100000000).div(this.pool_info.share_weight_total)
      return percent.toNumber()
      // return 0
    },
    userBlockRewardShare () {
      if (this.reward_pool.shares_total === '0') {
        return 0
      }

      var totalShares = this.reward_pool.shares_total
      if (!this.userIsSubscribed) {
        totalShares = ethers.BigNumber.from(this.spool_user_info.share_weight).add(this.reward_pool.shares_total).toString()
      }
      var blockReward = ethers.BigNumber.from(this.reward_pool.block_reward).mul(this.spool_user_info.share_weight).div(totalShares)
      return blockReward.toString()
      // return 0
    },
    predictiveReward () {
      return ethers.BigNumber.from(this.poolSharePercent).mul(this.remainingRewards).div(100000000)
    },
    blockNumber () {
      return this.$store.state.blockNumber
    },
    blocksPerSecond () {
      return this.$store.state.blocksPerSecond
    },
    dateStartBlock () {
      var numBlocks = this.reward_pool.start_block - this.blockNumber
      var seconds = numBlocks * this.blocksPerSecond
      var date = moment().add(seconds, 'seconds')
      return date
    },
    timeToStartBlock () {
      return this.dateStartBlock.fromNow()
    },
    dateEndBlock () {
      var numBlocks = this.reward_pool.end_block - this.blockNumber
      var seconds = numBlocks * this.blocksPerSecond
      var date = moment().add(seconds, 'seconds')
      return date
    },
    timeToEndBlock () {
      return this.dateEndBlock.fromNow()
    },
    dateBonusEndBlock () {
      var numBlocks = this.reward_pool.bonus_end_block - this.blockNumber
      var seconds = numBlocks * this.blocksPerSecond
      var date = moment().add(seconds, 'seconds')
      return date
    },
    farmingEnded () {
      return ethers.BigNumber.from(this.blockNumber).gte(this.reward_pool.end_block)
    },
    farmingAwaitingStart () {
      return ethers.BigNumber.from(this.blockNumber).lt(this.reward_pool.start_block)
    },
    farmingLive () {
      return ethers.BigNumber.from(this.blockNumber).gte(this.reward_pool.start_block) && ethers.BigNumber.from(this.blockNumber).lt(this.reward_pool.end_block)
    },
    bonusLiveOrUpcoming () {
      return ethers.BigNumber.from(this.blockNumber).lt(this.reward_pool.bonus_end_block)
    },
    remainingSecondsTillEnd () {
      if (ethers.BigNumber.from(this.blockNumber).gt(this.reward_pool.end_block)) {
        return 0
      }
      var duration = ethers.BigNumber.from(this.reward_pool.end_block).sub(this.blockNumber)
      duration = duration.toNumber() * this.$store.state.blocksPerSecond
      return duration
    },
    remainingBlocks () {
      if (ethers.BigNumber.from(this.blockNumber).gt(this.reward_pool.end_block)) {
        return 0
      }
      var startClamp = ethers.BigNumber.from(this.blockNumber).gt(this.reward_pool.start_block) ? this.blockNumber : this.reward_pool.start_block
      var duration = ethers.BigNumber.from(this.reward_pool.end_block).sub(startClamp)
      return duration.toNumber()
    },
    remainingBonusBlocks () {
      if (ethers.BigNumber.from(this.blockNumber).gt(this.reward_pool.bonus_end_block)) {
        return 0
      }
      var startClamp = ethers.BigNumber.from(this.blockNumber).gt(this.reward_pool.start_block) ? this.blockNumber : this.reward_pool.start_block
      var duration = ethers.BigNumber.from(this.reward_pool.bonus_end_block).sub(startClamp)
      return duration.toNumber()
    },
    remainingRewards () {
      var blocks = 0
      var from = ethers.BigNumber.from(this.blockNumber).gt(this.reward_pool.start_block) ? this.blockNumber : this.reward_pool.start_block
      var to = ethers.BigNumber.from(this.blockNumber).gt(this.reward_pool.end_block) ? this.blockNumber : this.reward_pool.end_block

      if (ethers.BigNumber.from(to).lte(this.reward_pool.bonus_end_block)) {
        blocks = ethers.BigNumber.from(to).sub(from).mul(this.reward_pool.bonus)
      } else if (ethers.BigNumber.from(from).gte(this.reward_pool.bonus_end_block)) {
        blocks = ethers.BigNumber.from(to).sub(from)
      } else {
        var b1 = ethers.BigNumber.from(this.reward_pool.bonus_end_block).sub(from).mul(this.reward_pool.bonus)
        var b2 = ethers.BigNumber.from(to).sub(this.reward_pool.bonus_end_block)
        blocks = b1.add(b2)
      }
      return ethers.BigNumber.from(blocks).mul(this.reward_pool.block_reward).toString()
    }
  },

  watch: {
    blockNumber () {
      this.refresh()
    },
    sEthersWatcher () {
      this.refresh()
    },
  },

  methods: {
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .then(() => {
        })
        .catch(e => {})
    },
    async getUserWalletBalance () {
      if (this.userConnected) {
        this.user_wallet_balance = await ERC20BALANCE.getBalance(this.sEthers.coinbase, this.reward_pool.reward_token.address)
      }
    },
    async getRewardPoolBalance () {
      this.rpool_balance = await ERC20BALANCE.getBalance(this.reward_info.pool_address, this.reward_pool.reward_token.address)
    },
    async refresh () {
      this.reward_pool = await RewardPoolContract.getInfo(this.reward_info.pool_address)
      if (this.userConnected) {
        this.rpool_user_info = await RewardPoolContract.getUserInfo(this.reward_info.pool_address, this.sEthers.coinbase)
      }
      this.firstLoad = false
      this.getRewardPoolBalance()
      this.getUserWalletBalance()
    },
    async subscribe () {
      this.subscribeLoading = true
      TxWrapper.doTransaction(RewardPoolContract.subscribe(this.reward_info.pool_address, this.sEthers.coinbase), this.$root.$dialog.confirmTx, 1)
        .then(response => {
          this.refresh()
          this.$emit('on-sub-event')
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          this.subscribeLoading = false
        })
    },
    async harvest () {
      this.harvestLoading = true
      TxWrapper.doTransaction(RewardPoolContract.harvest(this.reward_info.pool_address, this.sEthers.coinbase), this.$root.$dialog.confirmTx, 1)
        .then(response => {
          this.refresh()
          this.$emit('on-sub-event')
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          this.harvestLoading = false
        })
    },
    async unsubscribe () {
      this.unsubscribeLoading = true
      TxWrapper.doTransaction(RewardPoolContract.unsubscribe(this.reward_info.pool_address, this.sEthers.coinbase), this.$root.$dialog.confirmTx, 1)
        .then(response => {
          this.refresh()
          this.$emit('on-sub-event')
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          this.unsubscribeLoading = false
        })
    },
    async emergencyUnsubscribe () {
      TxWrapper.doTransaction(RewardPoolContract.emergencyUnsubscribe(this.reward_info.pool_address), this.$root.$dialog.confirmTx, 1)
        .then(response => {
          this.refresh()
          this.$emit('on-sub-event')
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
        })
    },
  },

  created () {
    this.refresh()
  }
}
</script>