export default {
  theme1: {
    darkMode: false,
    light: {
      primary: '#00C988', // green
      secondary: '#424242',
      navcolor: '#5A63D2',
      accent: '#82B1FF',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#EE3043',
      text: '#202532',
      icon: '#0F3256',
      textFaint: '#8e98ab',
      heading: '#0F3256',
      outline: "#D7D8DA",
      background: "#e9eef2",
      midground: "#f5f5f9",
      excolor: "#ffffff",
      foreground: "#ffffff",
      hover: "#fafafa",
      inputcolor: "#F5F7F9",
    }
  },
  // black theme - tinted blue
  theme2: { // old dark theme
    darkMode: true,
    light: {
      primary: '#68D67C', // green
      secondary: '#424242',
      navcolor: '#0BBDFB',
      accent: '#82B1FF',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#EE3043',
      text: '#fafafa',
      icon: '#aaaaaa',
      textFaint: '#b9babb',
      heading: '#0F3256',
      outline: "#262626",
      background: "#151617",
      midground: "#18191C",
      excolor: "#171717",
      foreground: "#1E2023",
      hover: "#22272c",
      inputcolor: "#101010",
    }
  },
  // blue black
  theme3: {
    darkMode: true,
    light: {
      primary: '#5ED1A1', // green
      pink: '#ca5071',
      secondary: '#424242',
      accent: '#82B1FF',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FFC107',
      text: '#CEC2CD',
      icon: '#7C7AA3',
      textFaint: '#8C8AA3',
      heading: '#0F3256',
      outline: "#3C4667",
      background: "#1E2438",
      midground: "#1E2438",
      excolor: "#21273D",
      foreground: "#272E48",
      inputcolor: "#303956",
    }
  },
}
